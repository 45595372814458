import RegisterContent from "./features/register";
import "../../auth/auth.scss";
import AuthLayout from "../../../layout/authLayout";

const Register = () => {
  return (
    <AuthLayout>
      <main>
        <RegisterContent />
      </main>
    </AuthLayout>
  );
};

export default Register;
