import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../hooks/store";
import {
  useLazyDeleteCollectionByIdQuery,
  useLazyGetAllCollectionQuery,
  useUpdateCollectionStatusMutation,
} from "../../../services/main";
import { useEffect, useState } from "react";
import { Collection, CommonBody } from "../../../types/General";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../../helpers";
import { setCredentials } from "../../../reducers/authSlice";
import Pagination from "../../../components/Pagination";
import WarnModal from "../../../components/modals/WarnModal";
import { updateStatus } from "../../../utils/commonFunctions";

export default function CollectionCenterListing() {
  const user = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [getCollectionMutation, { isLoading }] = useLazyGetAllCollectionQuery();
  const [deleteById] = useLazyDeleteCollectionByIdQuery();
  const [updateStatusMutation] = useUpdateCollectionStatusMutation();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [collections, setCollections] = useState<Collection[]>([]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleDelete = async () => {
    if (selectedId) {
      try {
        const response = await deleteById({ id: selectedId }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Deleted Successfully");
          getCollections();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const getCollections = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );

    try {
      const res = await getCollectionMutation({
        size: 10,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setCollections(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCollections();
  }, [debouncedSearchTerm, page]);

  return (
    <>
      <TableContainer className="table_container">
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className="table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Latitude</TableCell>
              <TableCell>Longitude</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {collections?.length
              ? collections?.map((item, i) => (
                  <TableRow key={item?._id}>
                    <TableCell align="center">
                      {(page - 1) * 10 + i + 1}
                    </TableCell>
                    <TableCell>{item?.name || ""}</TableCell>
                    <TableCell>{item?.address || ""}</TableCell>
                    <TableCell>{item?.longitude || ""}</TableCell>
                    <TableCell>{item?.latitude || ""}</TableCell>
                    <TableCell>
                      <Switch
                        {...label}
                        size="small"
                        checked={!item?.isBlocked}
                        onChange={() =>
                          updateStatus(
                            item?._id,
                            !item?.isBlocked,
                            updateStatusMutation,
                            setCollections,
                            collections
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <ul className="action_btn">
                        <li
                          onClick={() =>
                            navigate(
                              `/manage-collection-center/edit/${item?._id}`
                            )
                          }
                        >
                          <button type="button">
                            <EditIcon />
                          </button>
                        </li>
                        <li>
                          <button
                            type="button"
                            onClick={() => {
                              setOpen(true);
                              setSelectedId(item?._id);
                            }}
                          >
                            <DeleteIcon />
                          </button>
                        </li>
                      </ul>
                    </TableCell>
                  </TableRow>
                ))
              : !loading &&
                !collections?.length && (
                  <TableRow>
                    <TableCell align="center" colSpan={7}>
                      No Data Found
                    </TableCell>
                  </TableRow>
                )}
            {/* {rows.map((row, i) => (
              <TableRow>
                <TableCell align="center">{i + 1}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.lat}</TableCell>
                <TableCell>{row.long}</TableCell>
                <TableCell>{row.address}</TableCell>
                <TableCell>
                  <ul className="action_btn">
                    <li
                      onClick={() => navigate("/manage-collection-center/add")}
                    >
                      <button type="button">
                        <EditIcon />
                      </button>
                    </li>
                    <li>
                      <button type="button">
                        <DeleteIcon />
                      </button>
                    </li>
                  </ul>
                </TableCell>
              </TableRow>
            ))} */}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        setPage={setPage}
        module={collections}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
        totalCount={totalCount}
      />

      <WarnModal
        setOpen={setOpen}
        open={open}
        name="collection"
        handleDelete={handleDelete}
      />
    </>
  );
}
