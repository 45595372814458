
import AuthLayout from "../../../layout/authLayout";
import "../../auth/auth.scss";
import ResetPasswordContent from "./features/resetPassword";

const ResetPassword = () => {
  return (
    <AuthLayout>
      <main>
        <ResetPasswordContent />
      </main>
    </AuthLayout>
  );
};

export default ResetPassword;
