import {
  Box,
  Button,
  CardMedia,
  Input,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MainContainer from "../../../layout/MainContainer";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import ChangePassword from "./changePassword";
import * as Yup from "yup";
import { useFormik } from "formik";
import useAuth from "../../../hooks/useAuth";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../../helpers";
import { UploadMedia } from "../../../utils/mediaUpload";
import { useEditProfileMutation } from "../../../services/auth";
import Loader from "../../../constants/Loader";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { setCredentials } from "../../../reducers/authSlice";
import { useAppDispatch } from "../../../hooks/store";
import { CommonBody } from "../../../types/General";
import { isString } from "../../../utils/validation";
import { useLocation } from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Profile = () => {
  const user = useAuth();
  const location = useLocation();
  const { state } = location;
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const [updateProfile, { isLoading }] = useEditProfileMutation();

  const [value, setValue] = useState(state === "isProfileComplete" ? 1 : 0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  //for image upload
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("image", res?.data);
        } else {
          errorToast(res?.message);
        }
      } else {
        // Display an error message for non-image files
        errorToast("Please Try Again");
      }
    }
  };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: user?.email || "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      phone: user?.phone || "",
      countryCode: user?.countryCode || "+971",
      image: user?.image || "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      firstName: Yup.string()
        .required("First name is required")
        .min(2, "Minimum 2 characters are required")
        .max(25, "Maximum 25 character are allowed"),
      lastName: Yup.string()
        .required("Last name is required")
        .max(25, "Maximum 25 character are allowed")
        .min(2, "Minimum 2 characters are required"),

      phone: Yup.string()
        .required("Phone number is required")
        .min(8, "Phone number must be at least 8 digits")
        .max(16, "Phone number must be at least 16 digits"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      if (!formik.values.image) {
        return;
      }
      let body = {
        email: formik.values.email,
        firstName: formik.values.firstName,
        lastName: formik.values.lastName,
        countryCode: formik.values.countryCode,
        phone: formik.values.phone,
        image: formik.values.image,
      };
      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await updateProfile(encryptedBody).unwrap();
        console.log(response);
        if (response?.statusCode === 200) {
          const token = getFromStorage(STORAGE_KEYS.token);
          successToast("Profile updated successfully");
          dispatch(
            setCredentials({
              user: response?.data || null,
              token: token || null,
            })
          );
        } else {
          errorToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />
      <MainContainer>
        <div className="profile_page">
          <div className="dashboard fs_22">
            <h1>View Profile</h1>
          </div>

          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Profile" {...a11yProps(0)} />
              <Tab label="Change Password" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <div className="edit_info">
                  <form action="" className="form">
                    <div className="form_title fs_16">
                      <h2>
                        General Info
                        {/* <figure>
                          <img src="/images/edit_icon.svg" alt="" />
                        </figure> */}
                      </h2>
                    </div>

                    <div className="form_group gap_m">
                      <div className="form_left">
                        <div className=" gap_m">
                          <div className="form_control form_control_bg w_50">
                            <label> FIRST NAME</label>
                            <TextField
                              hiddenLabel
                              placeholder="First Name"
                              fullWidth
                              type="text"
                              variant="outlined"
                              name="firstName"
                              className="text_field"
                              id="firstName"
                              onChange={(val) => {
                                if (
                                  val.target.value === " " ||
                                  val.target.value === "."
                                ) {
                                } else if (isString(val.target.value)) {
                                  formik.handleChange(val);
                                }
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.firstName}
                              helperText={
                                formik.touched.firstName &&
                                formik.errors.firstName
                              }
                            />
                          </div>
                          <div className="form_control form_control_bg w_50">
                            <label>LAST NAME</label>

                            <TextField
                              className="text_field"
                              hiddenLabel
                              placeholder="Last Name"
                              fullWidth
                              type="text"
                              variant="outlined"
                              name="lastName"
                              id="lastName"
                              onChange={(val) => {
                                if (
                                  val.target.value === " " ||
                                  val.target.value === "."
                                ) {
                                } else if (isString(val.target.value)) {
                                  formik.handleChange(val);
                                }
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.lastName}
                              helperText={
                                formik.touched.lastName &&
                                formik.errors.lastName
                              }
                            />
                          </div>
                          <div className="form_control form_control_bg w_50">
                            <label>Email</label>

                            <TextField
                              disabled
                              className="text_field"
                              hiddenLabel
                              placeholder="Email"
                              fullWidth
                              type="email"
                              variant="outlined"
                              name="email"
                              id="email"
                              onChange={(val) => {
                                if (
                                  val.target.value === " " ||
                                  val.target.value === "."
                                ) {
                                } else {
                                  formik.handleChange(val);
                                }
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                              helperText={
                                formik.touched.email && formik.errors.email
                              }
                            />
                          </div>

                          <div className="form_control form_control_bg w_50">
                            <label className="custom_label">Phone Number</label>
                            <PhoneInput
                              value={
                                formik.values.countryCode + formik.values.phone
                              }
                              country={"us"}
                              placeholder="0 (000) 000-000"
                              enableSearch={true}
                              inputStyle={{ width: "100%" }}
                              onChange={(phone, country) =>
                                handleChangePhone(phone, country)
                              }
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.phone && formik.errors.phone ? (
                              <h6 className="err_msg">
                                {formik.touched.phone && formik.errors.phone}
                              </h6>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="prfl_pic form_control form_control_bg ">
                        <label>Logo</label>
                        {formik.values.image ? (
                          <div className="upload_image">
                            <div className="upload_image_holder">
                              <figure>
                                <CardMedia
                                  component="img"
                                  image={formik.values.image}
                                  alt="photo"
                                />
                              </figure>
                              <CloseIcon
                                onClick={() => {
                                  formik.setFieldValue("image", "");
                                }}
                              />
                            </div>
                          </div>
                        ) : (
                          <label
                            className="upload_image"
                            htmlFor="icon-button-file"
                          >
                            <Input
                              sx={{ display: "none" }}
                              id="icon-button-file"
                              type="file"
                              inputProps={{
                                accept: ".png, .jpg, .jpeg, .svg",
                              }}
                              onChange={handleImageUpload}
                            />
                            <span className="upload_image_holder">
                              <figure>
                                <img src="/images/userDummy.jpeg" alt="" />
                              </figure>
                              <AddIcon />
                            </span>
                          </label>
                        )}
                        {!formik.values.image && error ? (
                          <h6 className="err_msg">This field is required</h6>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </form>
                </div>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                    onClick={() => setError(true)}
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ChangePassword />
            </CustomTabPanel>
          </Box>
        </div>
      </MainContainer>
    </>
  );
};

export default Profile;
