import MainContainer from "../../layout/MainContainer";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import CollectionCenterListing from "./features/collectionCenterListing";

export default function ManageCollectionCenter() {
  const navigate = useNavigate();

  return (
    <MainContainer name="Contact & Support">
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <h2>Manage Collection Center</h2>
          <div className="add_btn">
            <button className="btn btn_primary" onClick={() => navigate("add")}>
              <AddIcon />
            </button>
          </div>
        </div>
        <CollectionCenterListing />
      </div>
    </MainContainer>
  );
}
