import LoginContent from "./features/login";
import "../../auth/auth.scss";
import AuthLayout from "../../../layout/authLayout";

const Login = () => {
  return (
    <AuthLayout>
      <main>
        <LoginContent />
      </main>
    </AuthLayout>
  );
};

export default Login;
