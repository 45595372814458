import { useNavigate } from "react-router-dom";
import Pagination from "../../../components/Pagination";
import useAuth from "../../../hooks/useAuth";
import { Employee, User } from "../../../types/User";
import Loader from "../../../constants/Loader";
import { useLazyGetEmployeeQuery } from "../../../services/organisation";
import { useEffect, useState } from "react";
import SearchBar from "../../../components/SearchBar";
import { isValidInput } from "../../../utils/validation";
import { Permissions } from "../../../types/General";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { STORAGE_KEYS, getFromStorage } from "../../../helpers";
import { setCredentials } from "../../../reducers/authSlice";
import { useAppDispatch } from "../../../hooks/store";

const EmployeeList = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [getEmployee, { isLoading }] = useLazyGetEmployeeQuery();

  const [employee, setEmployee] = useState<Employee[]>([]);
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);
  const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const handleExportCsv = async () => {
  //   const token = getFromStorage(STORAGE_KEYS.token);
  //   let headers = {
  //     Accept: "application/json",
  //     Authorization: `Bearer ${token}`, // Include the token in the Authorization header
  //   };

  //   try {
  //     const res = await fetch(API_URL + END_POINTS?.organisationCsv, {
  //       method: "GET",
  //       headers,
  //     });

  //     let response = await res.json();
  //     window.open(response?.data || "");
  //   } catch (error) {
  //     console.log(error, ">>>>>>>>>");
  //   }
  // };

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const checkPermission = () => {
    const permission = user?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Employees"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (user && user?.permission?.length) {
      checkPermission();
    }
  }, [user]);

  const getAllEmployee = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await getEmployee({
        size: 10,
        page: page,
        query: debouncedSearchTerm.trim(),
        type: value,
        b2bId: user?.createdBy,
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setEmployee(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllEmployee();
  }, [debouncedSearchTerm, page, value, user]);

  return (
    <div className="main_loyout rpt_card">
      <Loader isLoad={isLoading || loading} />
      <div className="dashboard d_flx sec_head fs_22">
        <h2>Employees List</h2>
        <div className="btn_group">
          <SearchBar
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            value={searchTerm}
            onCross={() => setSearchTerm("")}
            onChange={(val: any) => {
              if (isValidInput(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
          />
          {hidePermission?.isAdd || user?.role === 2 ? (
            <figure className="pls_icn" onClick={() => navigate("add")}>
              <img src="/images/plus.svg" alt="" />
            </figure>
          ) : null}

          {/* <button className="btn btn_sm btn_primary" onClick={handleExportCsv}>
            Export CSV
          </button> */}
        </div>
      </div>
      <Box className="custom_tabs">
        <Tabs
          value={value}
          onChange={handleChange2}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
          className="custom_tabs_links"
        >
          <Tab label="Registered" {...a11yProps(0)} />
          <Tab label="Un-Registered" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
          <ul className="emp_lst gap_m">
            {employee?.length
              ? employee?.map((item) => (
                  <li className="active">
                    <figure>
                      <img
                        src={item?.image || "/images/userDummy.jpeg"}
                        alt="user"
                      />
                    </figure>
                    <div className="usr_cnt">
                      <h3>{item?.firstName || ""}</h3>
                      <p>{item?.email || ""}</p>
                      <button
                        onClick={() =>
                          navigate(
                            `/manage-employee/view/${item?._id}/${value}`
                          )
                        }
                        type="button"
                        className="lst_btn"
                      >
                        View Details
                      </button>
                    </div>
                  </li>
                ))
              : !loading &&
                !employee?.length && (
                  <div className="no_date_sc hd_4">
                    <figure>
                      <img src="/images/noData.png" alt="noData" />
                    </figure>
                    <h2>No Data Found</h2>
                  </div>
                )}
          </ul>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ul className="emp_lst gap_m">
            {employee?.length
              ? employee?.map((item) => (
                  <li className="active">
                    <figure>
                      <img
                        src={item?.image || "/images/userDummy.jpeg"}
                        alt="user"
                      />
                    </figure>
                    <div className="usr_cnt">
                      <h3>{item?.companyName || ""}</h3>
                      <p>{item?.email || ""}</p>
                      <button
                        onClick={() =>
                          navigate(
                            `/manage-employee/view/${item?._id}/${value}`
                          )
                        }
                        type="button"
                        className="lst_btn"
                      >
                        View Details
                      </button>
                    </div>
                  </li>
                ))
              : !loading &&
                !employee?.length && (
                  <div className="no_date_sc hd_4">
                    <figure>
                      <img src="/images/noData.png" alt="noData" />
                    </figure>
                    <h2>No Data Found</h2>
                  </div>
                )}
          </ul>
        </CustomTabPanel>
      </Box>

      <Pagination
        setPage={setPage}
        module={employee}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </div>
  );
};

export default EmployeeList;
