import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

type PostSetPasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};
type PostChangePasswordResponse = {
  statusCode: number;
  message: string;
  data: any;
};

export const authApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postLogin: builder.mutation<
      CommonResponseType & { data: User },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.login,
        method: "POST",
        body,
      }),
    }),
    postForgotPassword: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.forgotPassword,
        method: "POST",
        body,
      }),
    }),
    postVerifyOtp: builder.mutation<
      CommonResponseType & { data: User },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.verifyOtp,
        method: "POST",
        body,
      }),
    }),
    resetPassword: builder.mutation<PostSetPasswordResponse, CommonBody>({
      query: (body) => ({
        url: END_POINTS.resetPassword,
        method: "POST",
        body,
      }),
    }),
    postChangePassword: builder.mutation<
      PostChangePasswordResponse,
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.changePassword,
        method: "POST",
        body,
      }),
    }),
    getUser: builder.query<CommonResponseType & { data: User }, {}>({
      query: () => ({
        url: END_POINTS.get_user,
        method: "GET",
      }),
    }),
    editProfile: builder.mutation<
      CommonResponseType & { data: User },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.update_profile,
        method: "PUT",
        body,
      }),
    }),
    postLogout: builder.mutation<CommonResponseType, {}>({
      query: () => ({
        url: END_POINTS.logout,
        method: "GET",
      }),
    }),
    postSignUp: builder.mutation<
      CommonResponseType & { data: User },
      CommonBody
    >({
      query: (body) => ({
        url: END_POINTS.signupEmployee,
        method: "POST",
        body,
      }),
    }),
  }),
});

export const {
  usePostLoginMutation,
  usePostForgotPasswordMutation,
  useLazyGetUserQuery,
  useEditProfileMutation,
  usePostVerifyOtpMutation,
  usePostChangePasswordMutation,
  usePostLogoutMutation,
  useResetPasswordMutation,
  usePostSignUpMutation,
} = authApi;
