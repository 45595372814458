import { Button, CardMedia, Input, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Category, CommonBody } from "../../types/General";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  useAddCategoryMutation,
  useLazyGetCategoryByIdQuery,
  useUpdateCategoryMutation,
} from "../../services/main";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { isString } from "../../utils/validation";
import { UploadMedia } from "../../utils/mediaUpload";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";
import { setCredentials } from "../../reducers/authSlice";

export default function AddSubCategory() {
  const { id } = useParams();
  const { subId } = useParams();
  const navigate = useNavigate();
  const user = useAuth();
  const dispatch = useAppDispatch();

  const [addCategory, { isLoading }] = useAddCategoryMutation();
  const [updateCategory, updateCategoryData] = useUpdateCategoryMutation();
  const [categoryById] = useLazyGetCategoryByIdQuery();

  const [error, setError] = useState<boolean>(false);
  const [category, setCategory] = useState<Category | null>(null);

  //for image upload
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (key === "image") {
            formik.setFieldValue("image", res?.data);
          } else {
            if (file?.size < 130648) {
              errorToast("Cover image minimum dimensions must be 498×540");
              return;
            }
            formik.setFieldValue("coverImage", res?.data);
          }
        } else {
          errorToast(res?.message);
        }
      } else {
        // Display an error message for non-image files
        errorToast("Please Try Again");
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: category?.name || "",
      description: category?.description || "",
      image: category?.image || "",
      coverImage: category?.coverImage || "",
    },
    validationSchema: Yup.object({
      image: Yup.string().required("Image name is required"),
      coverImage: Yup.string().required("Image name is required"),
      name: Yup.string()
        .required("Category name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
      description: Yup.string()
        .required("Description is required")
        .min(2, "Minimum 2 characters are required")
        .max(280, "Maximum 280 character are allowed"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        name: formik.values.name,
        description: formik.values.description,
        image: formik.values.image,
        coverImage: formik.values.coverImage,
        parent: id,
      };

      if (subId) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateCategory({
            body: encryptedBody,
            id: subId,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Subcategory Updated Successfully");
            formik.resetForm();
            navigate(`/manage-category/subcategory/${id}`);
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await addCategory(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Subcategory Added Successfully");
            formik.resetForm();
            navigate(`/manage-category/subcategory/${id}`);
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });
  const getSubCategoryById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );

    try {
      const response = await categoryById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setCategory(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (subId && subId !== "add") {
      getSubCategoryById(subId);
    }
  }, []);

  return (
    <MainContainer name="Contact & Support">
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <h2>Add Sub-category</h2>
          <div className="btn_group">
            <button
              className="btn btn_sm btn_primary"
              onClick={() => navigate(`/manage-category/subcategory/${id}`)}
            >
              Back
            </button>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form_group gap_m">
            <div className="prfl_pic form_control form_control_bg  w_50 ">
              <label>Sub-category Image</label>
              {formik.values.image ? (
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <CardMedia
                        component="img"
                        image={formik.values.image}
                        alt="photo"
                      />
                    </figure>
                    <CloseIcon
                      onClick={() => {
                        formik.setFieldValue("image", "");
                      }}
                    />
                  </div>
                </div>
              ) : (
                <label className="upload_image" htmlFor="icon-button-file">
                  <Input
                    sx={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    inputProps={{
                      accept: "image/png,image/jpeg",
                    }}
                    onChange={(e: any) => handleImageUpload(e, "image")}
                  />
                  <span className="upload_image_holder">
                    <figure>
                      <img src="/images/placeholder_gallery.jpeg" alt="" />
                    </figure>
                    <AddIcon />
                  </span>
                  {!formik.values.image && error ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </label>
              )}
            </div>
            <div className="prfl_pic form_control form_control_bg  w_50 ">
              <label>Sub-category Cover Image (dimensions 498×540)</label>
              {formik.values.coverImage ? (
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <CardMedia
                        component="img"
                        image={formik.values.coverImage}
                        alt="photo"
                      />
                    </figure>
                    <CloseIcon
                      onClick={() => {
                        formik.setFieldValue("coverImage", "");
                      }}
                    />
                  </div>
                </div>
              ) : (
                <label
                  className="upload_image"
                  htmlFor="icon-button-file-coverImg"
                >
                  <Input
                    sx={{ display: "none" }}
                    id="icon-button-file-coverImg"
                    type="file"
                    inputProps={{
                      accept: "image/png,image/jpeg",
                    }}
                    onChange={(e: any) => handleImageUpload(e, "coverImg")}
                  />
                  <span className="upload_image_holder">
                    <figure>
                      <img src="/images/placeholder_gallery.jpeg" alt="" />
                    </figure>
                    <AddIcon />
                  </span>
                  {!formik.values.coverImage && error ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </label>
              )}
            </div>

            <div className="form_control form_control_bg  w_50">
              <label> Sub-category Name </label>
              <TextField
                hiddenLabel
                placeholder="Sub-category Name"
                fullWidth
                variant="outlined"
                name="name"
                type="text"
                className="text_field"
                id="name"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                helperText={formik.touched.name && formik.errors.name}
              />
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Sub-category Description</label>

              <TextField
                hiddenLabel
                placeholder="Description goes here"
                fullWidth
                name="description"
                type="text"
                variant="outlined"
                className="text_field"
                id="description"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </div>
          </div>
          <div className="submit_btn">
            <Button
              className="btn btn_primary"
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              onClick={() => setError(true)}
            >
              Submit
            </Button>
          </div>
        </form>
      </div>
    </MainContainer>
  );
}
