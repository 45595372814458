import { useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import OrganizationViewContent from "./feature/organizationDetailContent";

const OrganizationView = () => {
  const { id } = useParams();
  return (
    <MainContainer>
      <div className="main_loyout rpt_card">
        <OrganizationViewContent id={id} />
      </div>
    </MainContainer>
  );
};

export default OrganizationView;
