import ProfileSetupContent from "./features/profileSetup";
import "../../auth/auth.scss";
import AuthLayout from "../../../layout/authLayout";

const ProfileSetup = () => {
  return (
    <AuthLayout>
      <main>
        <ProfileSetupContent />
      </main>
    </AuthLayout>
  );
};

export default ProfileSetup;
