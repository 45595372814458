import { END_POINTS } from "../helpers";
import {
  Blog,
  Cms,
  CommonBody,
  FaqBody,
  Newsletter,
  Notification,
  Support,
} from "../types/General";
import { User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type NotificationRes = {
  notificationCount: number;
  pages: number;
  notification: Notification[];
};

type FaqRes = {
  count: number;
  pages: number;
  data: FaqBody[];
};
type SupportRes = {
  count: number;
  pages: number;
  data: Support[];
};

type GetParams = {
  size: number;
  page: number;
  query: string;
};

type BlogRes = {
  count: number;
  pages: number;
  data: Blog[];
};

type NewsletterRes = {
  count: number;
  pages: number;
  data: Newsletter[];
};

type superAdminRes = {
  count: number;
  pages: number;
  data: User[];
};

export const commonApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    addNotification: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.notification,
        method: "POST",
        body,
      }),
    }),
    getNotification: builder.query<
      CommonResponseType & { data: NotificationRes },
      {}
    >({
      query: () => ({
        url: END_POINTS.notification,
        method: "GET",
      }),
    }),
    deleteNotificationById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.notification}/${id}`,
        method: "DELETE",
      }),
    }),
    getCms: builder.query<CommonResponseType & { data: Cms }, {}>({
      query: () => ({
        url: END_POINTS.cms,
        method: "GET",
      }),
    }),
    postCms: builder.mutation<CommonResponseType & { data: Cms }, CommonBody>({
      query: (body) => ({
        url: END_POINTS.cms,
        method: "PUT",
        body,
      }),
    }),
    getFaq: builder.query<CommonResponseType & { data: FaqRes }, {}>({
      query: () => ({
        url: END_POINTS.faq,
        method: "GET",
      }),
    }),
    addFaq: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.faq,
        method: "POST",
        body,
      }),
    }),
    deleteFaq: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.faq}/${id}`,
        method: "DELETE",
      }),
    }),
    getSupport: builder.query<
      CommonResponseType & { data: SupportRes },
      { type: number }
    >({
      query: ({ type }) => ({
        url: `${END_POINTS.support}?type=${type}`,
        method: "GET",
      }),
    }),
    getSupportById: builder.query<
      CommonResponseType & { data: Support },
      { id: string; type: number }
    >({
      query: ({ id, type }) => ({
        url: `${END_POINTS.support}/${id}?type=${type}`,
        method: "GET",
      }),
    }),
    revertQuerty: builder.mutation<
      CommonResponseType,
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.revert}/${id}`,
        method: "POST",
        body,
      }),
    }),
    //testimonials
    getAllTestimonial: builder.query<
      CommonResponseType & { data: BlogRes },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.testimonial +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getTestimonialById: builder.query<
      CommonResponseType & { data: Blog },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.testimonial}/${id}`,
        method: "GET",
      }),
    }),
    deleteTestimonialById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.testimonial}/${id}`,
        method: "DELETE",
      }),
    }),
    addTestimonial: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.testimonial,
        method: "POST",
        body,
      }),
    }),
    updateTestimonial: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.testimonial}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateTestimonialStatus: builder.mutation<
      CommonResponseType & { data: Blog },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.testimonial}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    //news
    getAllNews: builder.query<
      CommonResponseType & { data: BlogRes },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.news +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getNewsById: builder.query<
      CommonResponseType & { data: Blog },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.news}/${id}`,
        method: "GET",
      }),
    }),
    deleteNewsById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.news}/${id}`,
        method: "DELETE",
      }),
    }),
    addNews: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.news,
        method: "POST",
        body,
      }),
    }),
    updateNews: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.news}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateNewsStatus: builder.mutation<
      CommonResponseType & { data: Blog },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.news}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    //graphs
    totalNftGraph: builder.query<
      CommonResponseType & { data: { totalNfts: {} } },
      { filter: string }
    >({
      query: ({ filter }) => ({
        url: `${END_POINTS.totalNftGraph}?filter=${filter}`,
        method: "GET",
      }),
    }),
    couponsClaimedGraph: builder.query<
      CommonResponseType & { data: { totalNfts: {} } },
      { filter: string }
    >({
      query: ({ filter }) => ({
        url: `${END_POINTS.couponsClaimedGraph}?filter=${filter}`,
        method: "GET",
      }),
    }),
    totalUsersGraph: builder.query<
      CommonResponseType & { data: { totalUsers: {} } },
      { filter: string }
    >({
      query: ({ filter }) => ({
        url: `${END_POINTS.totalUsersGraph}?filter=${filter}`,
        method: "GET",
      }),
    }),
    getNewsletter: builder.query<
      CommonResponseType & { data: NewsletterRes },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.subscribeNewsletter +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
    getSuperAdmin: builder.query<
      CommonResponseType & { data: superAdminRes },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.getSuperAdmin +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetNotificationQuery,
  useAddNotificationMutation,
  useLazyDeleteNotificationByIdQuery,
  usePostCmsMutation,
  useLazyGetCmsQuery,
  useAddFaqMutation,
  useLazyGetFaqQuery,
  useLazyDeleteFaqQuery,
  useLazyGetSupportQuery,
  useLazyGetSupportByIdQuery,
  useRevertQuertyMutation,

  //testimonial
  useLazyGetAllTestimonialQuery,
  useLazyGetTestimonialByIdQuery,
  useLazyDeleteTestimonialByIdQuery,
  useAddTestimonialMutation,
  useUpdateTestimonialStatusMutation,
  useUpdateTestimonialMutation,

  //news
  useLazyGetAllNewsQuery,
  useLazyGetNewsByIdQuery,
  useLazyDeleteNewsByIdQuery,
  useAddNewsMutation,
  useUpdateNewsMutation,
  useUpdateNewsStatusMutation,
  //graph
  useLazyTotalUsersGraphQuery,
  useLazyCouponsClaimedGraphQuery,
  useLazyTotalNftGraphQuery,
  //
  useLazyGetSuperAdminQuery,
  useLazyGetNewsletterQuery,
} = commonApi;
