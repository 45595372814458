import React, { useEffect, useState } from "react";
import "./App.scss";
import Routing from "./Routes";
import useAuth from "./hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "./hooks/store";
import { useLazyGetUserQuery } from "./services/auth";
import { STORAGE_KEYS, getFromStorage } from "./helpers";
import { setCredentials } from "./reducers/authSlice";
import { WebNotSupport } from "./pages/web3NotSupport";

function App() {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();

  const [isValid, setIsValid] = useState(true);
  console.log("isValid: ", isValid);

  useEffect(() => {
    if (window && window.ethereum) {
      console.log("window: ", window.ethereum, isValid);
      if (typeof window.ethereum !== "undefined") {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }
  }, []);

  // const getUserDetails = async () => {
  //   const token = getFromStorage(STORAGE_KEYS.token);
  //   if (token) {
  //     try {
  //       const result = await getUser({}).unwrap();
  //       if (result?.statusCode === 200 && token) {
  //         dispatch(
  //           setCredentials({
  //             user: result?.data,
  //             token: token,
  //           })
  //         );
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   const token = getFromStorage(STORAGE_KEYS.token);
  //   const userData = getFromStorage(STORAGE_KEYS.credentials);
  //   if (token) {
  //     dispatch(
  //       setCredentials({
  //         token: `${token}`,
  //         user: JSON.parse(`${userData}`),
  //       })
  //     );
  //   }
  //   getUserDetails();
  // }, []);

  return <Routing />;
}

export default App;
