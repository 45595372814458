import React from "react";
import MainContainer from "../../layout/MainContainer";
import PlatformNftList from "./features/PlatformNftList";

const ManagePlatFormNft = () => {
  return (
    <>
      <MainContainer name="Manage NFT">
        <PlatformNftList />
      </MainContainer>
    </>
  );
};

export default ManagePlatFormNft;
