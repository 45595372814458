import { toast } from "react-toastify";

export const successToast = (message: string) => {
  toast.dismiss();
  toast.success(message);
};

export const warnToast = (message: string) => {
  toast.dismiss();
  toast.warning(message);
};

export const errorToast = (message: string) => {
  toast.dismiss();
  toast.error(message);
};
