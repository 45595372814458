import MainContainer from "../../layout/MainContainer";
import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../components/Pagination";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../../constants/Loader";
import {
  useLazyDeleteBlogByIdQuery,
  useLazyGetAllBlogQuery,
  useUpdateBlogStatusMutation,
} from "../../services/main";
import { useEffect, useState } from "react";
import { Blog, CommonBody, Newsletter } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  API_URL,
  END_POINTS,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { isValidInput } from "../../utils/validation";
import SearchBar from "../../components/SearchBar";
import WarnModal from "../../components/modals/WarnModal";
import moment from "moment";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import { useLazyGetNewsletterQuery } from "../../services/common";

export default function ManageNewsletter() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAuth();

  const [getNewsletter, { isLoading }] = useLazyGetNewsletterQuery();
  const [deleteById] = useLazyDeleteBlogByIdQuery();
  const [updateStatusMutation] = useUpdateBlogStatusMutation();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [newsletter, setNewsletter] = useState<Newsletter[]>([]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleExportCsv = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    let headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    };

    try {
      const res = await fetch(
        `${API_URL}${
          END_POINTS?.newsletterCsvExport
        }?search=${debouncedSearchTerm.trim()}`,
        {
          method: "GET",
          headers,
        }
      );

      let response = await res.json();
      window.open(response?.data || "");
    } catch (error) {
      console.log(error, ">>>>>>>>>");
    }
  };

  const getAllBlogs = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await getNewsletter({
        size: 10,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setNewsletter(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer name="Contact & Support">
      <Loader isLoad={isLoading || loading} />
      <div className="manage_blog">
        <div className="dashboard d_flx sec_head fs_22">
          <h2>Newsletter</h2>
          <div className="btn_group">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <button
              className="btn btn_sm btn_primary"
              onClick={handleExportCsv}
            >
              Export CSV
            </button>
          </div>
        </div>
        <TableContainer className="table_container">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Telegram</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {newsletter?.length
                ? newsletter?.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row?.email || ""}</TableCell>
                      <TableCell>{row?.telegram || ""}</TableCell>
                      <TableCell>
                        {row?.createdAt
                          ? moment(row?.createdAt).format("LL")
                          : ""}
                      </TableCell>
                    </TableRow>
                  ))
                : !loading &&
                  !newsletter?.length && (
                    <TableRow>
                      <TableCell align="center" colSpan={4}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          setPage={setPage}
          module={newsletter}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          totalCount={totalCount}
        />
      </div>
    </MainContainer>
  );
}
