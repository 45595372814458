import { Routes, Route, useNavigate } from "react-router-dom";
import Pages from "./pages";
import { useAppDispatch } from "./hooks/store";
import { useEffect } from "react";
import { STORAGE_KEYS, getFromStorage } from "./helpers";
import { resetAuth, setCredentials } from "./reducers/authSlice";

const Routing = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = getFromStorage(STORAGE_KEYS.token);
    if (token && window.location.pathname === "/") {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate("/dashboard", { replace: true });
    } else if (!token && window?.location?.pathname === "/") {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    } else if (token) {
      dispatch(
        setCredentials({
          token: token,
          user: null,
        })
      );
      navigate(window?.location?.pathname);
    } else {
      dispatch(resetAuth());
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/" element={<Pages.Login />} />
      <Route path="/login" element={<Pages.Login />} />
      <Route path="/register" element={<Pages.Register />} />
      <Route path="/profile-setup" element={<Pages.ProfileSetup />} />
      <Route path="/dashboard" element={<Pages.Dashboard />} />

      <Route path="/manage-employee" element={<Pages.ManageEmployee />} />
      <Route path="/manage-employee/add" element={<Pages.AddEmployee />} />
      <Route
        path="/manage-employee/edit/:id/:value"
        element={<Pages.AddEmployee />}
      />
      <Route path="/manage-role" element={<Pages.ManageRole />} />
      <Route path="/manage-coupons" element={<Pages.ManageCoupons />} />
      <Route path="/manage-admin-coupons" element={<Pages.ManageCoupons />} />
      <Route path="/manage-category" element={<Pages.ManageCategory />} />
      <Route path="/manage-category/add" element={<Pages.AddCategory />} />
      <Route path="/manage-category/edit/:id" element={<Pages.AddCategory />} />
      <Route
        path="/manage-category/subcategory/:id"
        element={<Pages.ManageSubcategory />}
      />
      <Route
        path="/manage-category/add-subcategory/:id"
        element={<Pages.AddSubCategory />}
      />
      <Route
        path="/manage-category/edit-subcategory/:id/:subId"
        element={<Pages.AddSubCategory />}
      />
      <Route
        path="/manage-coupons/coupon-detail/:id"
        element={<Pages.CouponDetail />}
      />
      <Route path="/manage-coupons/view" element={<Pages.CouponDetail />} />
      <Route path="/manage-coupons/add" element={<Pages.AddCoupon />} />
      <Route path="/manage-coupons/edit/:id" element={<Pages.AddCoupon />} />
      <Route
        path="/manage-coupons/view-nft/:id"
        element={<Pages.CouponNft />}
      />
      <Route path="/manage-nft" element={<Pages.ManageNft />} />
      <Route
        path="/manage-nft/view-nft/:id/:valueNum"
        element={<Pages.EditNft />}
      />
      <Route path="/manage-platform-nft/add-nft" element={<Pages.AddNft />} />
      <Route
        path="/manage-platform-nft/edit-nft/:id"
        element={<Pages.AddNft />}
      />
      <Route
        path="/manage-platform-nft"
        element={<Pages.ManagePlatFormNft />}
      />
      <Route
        path="/manage-platform-nft/view-nft/:id"
        element={<Pages.EditPlatformNft />}
      />

      <Route path="/manage-blog" element={<Pages.Blog />} />
      <Route path="/manage-collection" element={<Pages.ManageCollection />} />
      <Route path="/manage-collection/add" element={<Pages.AddCollection />} />

      <Route path="/manage-banner" element={<Pages.ManageBanner />} />
      <Route path="/view-profile" element={<Pages.Profile />} />
      <Route path="/change-password" element={<Pages.ChangePassword />} />

      <Route path="/manage-banner/add" element={<Pages.AddBanner />} />
      <Route path="/manage-banner/edit/:id" element={<Pages.AddBanner />} />

      <Route path="/manage-blog/add" element={<Pages.AddBlog />} />
      <Route path="/manage-blog/edit/:id" element={<Pages.AddBlog />} />
      <Route path="/analytics" element={<Pages.Analytics />} />
      <Route path="/forgot-password" element={<Pages.ForgotPassword />} />
      <Route path="/help-support" element={<Pages.HelpSupport />} />
      <Route path="/help-support/view/:id" element={<Pages.Viewquery />} />

      <Route path="/cms" element={<Pages.ManageCms />} />
      <Route
        path="/manage-notification"
        element={<Pages.ManageNotifications />}
      />

      <Route
        path="/manage-notifications/add"
        element={<Pages.AddNotification />}
      />
      <Route path="/manage-organization" element={<Pages.OrganizationList />} />
      <Route
        path="/manage-organization/add"
        element={<Pages.OraganizationDetail />}
      />
      <Route
        path="/manage-organization/edit/:id"
        element={<Pages.OraganizationDetail />}
      />

      <Route path="/otp" element={<Pages.Otp />} />
      <Route path="/reset-password" element={<Pages.ResetPassword />} />
      <Route
        path="/manage-organization/view/:id"
        element={<Pages.OrganizationView />}
      />
      <Route
        path="/manage-employee/view/:id/:value"
        element={<Pages.EmployeeView />}
      />
      <Route path="/manage-users" element={<Pages.ManageUsers />} />
      <Route
        path="/user-claimed-coupon/:userId/:id"
        element={<Pages.ClaimedCoupon />}
      />
      <Route
        path="/manage-users/view/:id"
        element={<Pages.ManageUsersView />}
      />
      <Route
        path="/manage-collection-center"
        element={<Pages.ManageCollectionCenter />}
      />
      <Route
        path="/manage-collection-center/add"
        element={<Pages.AddCollectionCenter />}
      />
      <Route
        path="/manage-collection-center/edit/:id"
        element={<Pages.AddCollectionCenter />}
      />
      <Route path="/manage-news" element={<Pages.NewsPage />} />
      <Route path="/manage-news/add" element={<Pages.AddNews />} />
      <Route path="/manage-news/edit/:id" element={<Pages.AddNews />} />

      <Route path="/manage-testimonial" element={<Pages.TestimonialPage />} />
      <Route
        path="/manage-testimonial/add"
        element={<Pages.AddTestimonials />}
      />
      <Route
        path="/manage-testimonial/edit/:id"
        element={<Pages.AddTestimonials />}
      />
      <Route path="/newsletter" element={<Pages.ManageNewsletter />} />
      <Route path="/superAdmin" element={<Pages.ManageSuperAdmin />} />
    </Routes>
  );
};

export default Routing;
