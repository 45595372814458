import React from "react";
import MainContainer from "../../layout/MainContainer";
import RoleList from "./feature/rolelist";

const ManageRole = () => {
  return (
    <>
      <MainContainer name="Manage Employees">
        <RoleList />
      </MainContainer>
    </>
  );
};

export default ManageRole;
