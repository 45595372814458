import { END_POINTS } from "../helpers";
import { CommonBody, Coupon, Dashboard } from "../types/General";
import { Employee, User } from "../types/User";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type OrganisationRes = {
  count: number;
  pages: number;
  subAdmin: User[];
};

type EmployeeRes = {
  count: number;
  pages: number;
  data: Employee[];
};

type GetParams = {
  size: number;
  page: number;
  query: string;
  filter?: string;
};

type CouponRes = {
  count: number;
  pages: number;
  data: Coupon[];
};

export const organizationApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getCouponByB2bId: builder.query<
      CommonResponseType & { data: CouponRes },
      { id: string; filter: string }
    >({
      query: ({ id, filter }) => ({
        url: `${END_POINTS.couponByB2bId}/${id}?filter=${filter}`,
        method: "GET",
      }),
    }),
    addOrganisation: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.organisation_add,
        method: "POST",
        body,
      }),
    }),
    updateOrganisationStatus: builder.mutation<
      CommonResponseType & { data: OrganisationRes },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.get_organisation}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getAllOrganisation: builder.query<
      CommonResponseType & { data: OrganisationRes },
      {}
    >({
      query: ({}) => ({
        url: END_POINTS.get_organisation,
        method: "GET",
      }),
    }),
    getOrganisation: builder.query<
      CommonResponseType & { data: OrganisationRes },
      GetParams & { pagination?: boolean | undefined }
    >({
      query: ({ size, page, query, pagination, filter }) => ({
        url: `${
          END_POINTS.get_organisation
        }?page=${page}&size=${size}&search=${query}${
          pagination ? `&pagination=${pagination}` : ""
        }${filter ? `&filter=${filter}` : ""}`,
        method: "GET",
      }),
    }),
    getOrganisationById: builder.query<
      CommonResponseType & { data: User },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.get_organisation}/${id}`,
        method: "GET",
      }),
    }),
    getB2BEmployeeById: builder.query<
      CommonResponseType & { data: EmployeeRes },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.getB2bEmployee}/${id}`,
        method: "GET",
      }),
    }),
    updateOrganisation: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.get_organisation}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    deleteOrganisationById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.get_organisation}/${id}`,
        method: "DELETE",
      }),
    }),
    getOrganisationCsv: builder.query<
      {
        statusCode: number;
        message: number;
        data: string;
      },
      unknown
    >({
      query: () => ({
        url: END_POINTS.organisationCsv,
        method: "GET",
      }),
    }),
    addEmployee: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.b2bEmployee,
        method: "POST",
        body,
      }),
    }),
    getEmployee: builder.query<
      CommonResponseType & { data: EmployeeRes },
      GetParams & { type: number; b2bId?: string }
    >({
      query: ({ size, page, query, type, b2bId }) => ({
        url: `${END_POINTS.b2bEmployee}?page=${page}&size=${size}&search=${query}&type=${type}&b2bId=${b2bId}`,
        method: "GET",
      }),
    }),
    getEmployeeById: builder.query<
      CommonResponseType & { data: Employee },
      { id: string; value: any }
    >({
      query: ({ id, value }) => ({
        url: `${END_POINTS.b2bEmployee}/${id}?type=${value}`,
        method: "GET",
      }),
    }),
    updateEmployee: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.b2bEmployee}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    deleteEmployeeById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.b2bEmployee}/${id}`,
        method: "DELETE",
      }),
    }),
    b2bDashboard: builder.mutation<
      CommonResponseType & { data: Dashboard },
      {}
    >({
      query: () => ({
        url: END_POINTS.b2bDashboard,
        method: "GET",
      }),
    }),
    adminDashboard: builder.mutation<
      CommonResponseType & { data: Dashboard },
      {}
    >({
      query: () => ({
        url: END_POINTS.adminDashboard,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useLazyGetAllOrganisationQuery,
  useAddOrganisationMutation,
  useLazyGetOrganisationQuery,
  useLazyGetOrganisationByIdQuery,
  useUpdateOrganisationMutation,
  useLazyDeleteOrganisationByIdQuery,
  useLazyGetOrganisationCsvQuery,
  useLazyGetB2BEmployeeByIdQuery,
  useUpdateOrganisationStatusMutation,
  useLazyGetCouponByB2bIdQuery,
  //
  useAddEmployeeMutation,
  useLazyGetEmployeeQuery,
  useLazyGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
  useLazyDeleteEmployeeByIdQuery,

  //
  useB2bDashboardMutation,
  useAdminDashboardMutation,
} = organizationApi;
