import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { Category } from "../../types/General";

const FilterComp = ({
  selectedCate,
  setSelectCate,
  categories,
  sortSelect,
  setSortSelect,
  positionSelect,
  setPositionSelect,
}: {
  selectedCate: string;
  setSelectCate: Dispatch<SetStateAction<string>>;
  categories: Category[];
  sortSelect: string;
  setSortSelect: Dispatch<SetStateAction<string>>;
  positionSelect: string;
  setPositionSelect: Dispatch<SetStateAction<string>>;
}) => {
  const handleChange1 = (event: SelectChangeEvent) => {
    setSelectCate(event.target.value as string);
  };

  const handleChange2 = (event: SelectChangeEvent) => {
    setSortSelect(event.target.value as any);
  };

  const handleChange3 = (event: SelectChangeEvent) => {
    setPositionSelect(event.target.value as string);
  };
  return (
    <div className="filter_sort">
      <form className="form">
        <div className="form_control">
          <label>Filter by:</label>
          <Select
            fullWidth
            value={selectedCate}
            onChange={handleChange1}
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
          >
            <MenuItem value="">Select</MenuItem>
            {categories && categories.length ? (
              categories.map((item, index) => (
                <MenuItem key={index} value={item?._id}>
                  {item?.name || ""}
                </MenuItem>
              ))
            ) : (
              <MenuItem value={""}>No Sub-categories found</MenuItem>
            )}
          </Select>
        </div>
        <div className="form_control">
          <label>Sort:</label>
          <Select
            fullWidth
            value={sortSelect}
            onChange={handleChange2}
            inputProps={{
              "aria-label": "Without label",
            }}
            displayEmpty
          >
            <MenuItem disabled value={"default"}>
              Sort
            </MenuItem>
            <MenuItem value={1}>By date</MenuItem>
            <MenuItem value={2}>By I2E Points</MenuItem>
            <MenuItem value={3}>By Carbon Credit Points</MenuItem>
          </Select>
          <Select fullWidth value={positionSelect} onChange={handleChange3}>
            <MenuItem value={1}>Ascending</MenuItem>
            <MenuItem value={2}>Descending </MenuItem>
          </Select>
        </div>
        {selectedCate || positionSelect !== "2" || sortSelect !== "1" ? (
          <p
            className="btn_link c_danger"
            onClick={() => {
              setSortSelect("1");
              setPositionSelect("2");
              setSelectCate("");
            }}
          >
            Clear filter
          </p>
        ) : null}
      </form>
    </div>
  );
};

export default FilterComp;
