import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  FormControl,
  NativeSelect,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { errorToast, successToast } from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import { useAddNotificationMutation } from "../../services/common";
import { isString } from "../../utils/validation";
import { useState } from "react";

const AddNotification = () => {
  const navigate = useNavigate();
  const [addNotification, { isLoading }] = useAddNotificationMutation();

  const [notificationType, setNotificationType] = useState("1");
  const handleChangeSelect = (event: SelectChangeEvent) => {
    setNotificationType(event.target.value as string);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: Yup.object({
      title: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
      description: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(300, "Maximum 300 character are allowed"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        title: formik.values.title,
        message: formik.values.description,
        type: notificationType,
      };
      console.log(body, "bodyMM");

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await addNotification(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Notification Added Successfully");
          formik.resetForm();
          navigate("/manage-notification");
        } else {
          errorToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  return (
    <>
      <MainContainer>
        <div className="main_loyout cms_pages">
          <div className="dashboard sec_head">
            <h1>Add Notification</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/manage-notification");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}>
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} className="form_control form_control_bg">
                    <Typography className="custom_label">
                      Notification Title
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="Title"
                      className="text_field"
                      name="title"
                      id="title"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                      helperText={formik.touched.title && formik.errors.title}
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="form_control form_control_bg">
                    <Typography className="custom_label">
                      Notification Message
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      variant="outlined"
                      fullWidth
                      placeholder="Message"
                      className="text_field"
                      name="description"
                      id="description"
                      onChange={(val) => {
                        if (
                          val.target.value === " " ||
                          val.target.value === "."
                        ) {
                        } else if (isString(val.target.value)) {
                          formik.handleChange(val);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                      helperText={
                        formik.touched.description && formik.errors.description
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={6} className="form_control form_control_bg">
                    <Typography className="custom_label">
                      Select Platform
                    </Typography>
                    <Select
                      fullWidth
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      label="organization-type"
                      value={notificationType}
                      onChange={handleChangeSelect}
                    >
                      <MenuItem value={"1"}>All</MenuItem>
                      <MenuItem value={"2"}>Organisations</MenuItem>
                      <MenuItem value={"4"}>Customers</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddNotification;
