// development
export const API_URL: string =
  "https://bantgoapi.appgrowthcompany.com/api/v1/admin/";

export const BUILD_URL: string = "https://nftmarketadmin.appgrowthcompany.com/";
export const BUILD_URL_WEB: string =
  "https://nftmarketweb.appgrowthcompany.com/";

//staging
// export const API_URL: string =
//   "https://stagingapi.impact-to-earn.com/api/v1/admin/";

// export const BUILD_URL: string = "https://d14egj6y3jnuq8.cloudfront.net/";
// export const BUILD_URL_WEB: string = "https://d1y63ol8rc3x29.cloudfront.net/";

export const END_POINTS = {
  login: "login",
  forgotPassword: "forgotPassword",
  verifyOtp: "verifyOtp",
  resetPassword: "setPassword",
  getProfile: "profile",
  updateProfile: "updateProfile",
  changePassword: "changePassword",
  mediaUpload: "upload",
  logout: "logout",
  update_profile: "updateProfile",
  get_user: "profile",
  signupEmployee: "signupEmployee",

  //organinsation
  organisation_add: "subAdmin",
  get_organisation: "subAdmin",
  organisationCsv: "subAdminsExports",
  newsletterCsvExport: "newsletterCsvExports",
  getB2bEmployee: "getB2bEmployee",
  redeemCoupon: "redeemCoupon",

  //employee
  b2bEmployee: "b2bEmployee",

  //dashboard
  b2bDashboard: "b2bDashboard",
  adminDashboard: "adminDashboard",
  //user
  user: "user",
  userClaimedCoupon: "userClaimedCoupon",
  userNft: "userNft",
  userExports: "userExports",
  category: "category",
  subCategoryByCategory: "subCategoryByCategory",
  banner: "banner",
  blog: "blog",
  testimonial: "testimonial",
  news: "news",
  coupon: "coupon",
  couponByB2bId: "couponByB2bId",
  notification: "notification",
  cms: "cms",
  faq: "faq",
  collection: "collectionCenter",
  platformNft: "platformNft",
  adminNft: "adminNft",
  addNft: "addNft",
  support: "support",
  revert: "reply",
  couponNft: "couponNft",

  //bid
  setOnBid: "setOnBid",
  acceptBidWinner: "acceptBidWinner",
  giftNft: "giftNft",
  mintNft: "mintMyNft",

  //graphs
  totalNftGraph: "totalNftGraph",
  totalUsersGraph: "totalUsersGraph",
  couponsClaimedGraph: "couponsClaimedGraph",
  subscribeNewsletter: "subscribeNewsletter",
  getSuperAdmin: "getSuperAdmin",
};
