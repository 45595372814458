import { END_POINTS } from "../helpers";
import {
  Banner,
  Blog,
  Category,
  ClaimedCoupon,
  ClaimedResponse,
  Collection,
  CommonBody,
  Coupon,
  Nft,
  WebUser,
} from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type CategoryRes = {
  count: number;
  pages: number;
  category: Category[];
};

type BannerRes = {
  count: number;
  pages: number;
  data: Banner[];
};
type BlogRes = {
  count: number;
  pages: number;
  data: Blog[];
};

type CouponRes = {
  count: number;
  pages: number;
  data: Coupon[];
};

type CollectionRes = {
  count: number;
  pages: number;
  data: Collection[];
};

type NftRes = {
  count: number;
  pages: number;
  data: Nft[];
};

type UserRes = {
  count: number;
  pages: number;
  user: WebUser[];
};

type GetParams = {
  size: number;
  page: number;
  query: string;
};

type UserNftRes = {
  mintedNft: Nft[];
  mintedNftCount: number;
  passiveNft: Nft[];
  passiveNftCount: number;
};

type ClaimedRes = {
  count: number;
  pages: number;
  data: ClaimedCoupon[];
};

type CouponNftRes = {
  count: number;
  couponNft: Nft[];
};

export const mainApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getUsers: builder.query<
      CommonResponseType & { data: UserRes },
      GetParams & { pagination?: boolean | undefined }
    >({
      query: ({ size, page, query, pagination }) => ({
        url: `${END_POINTS.user}?page=${page}&size=${size}&search=${query}${
          pagination ? `&pagination=${pagination}` : ""
        }`,
        method: "GET",
      }),
    }),
    getUserById: builder.query<
      CommonResponseType & { data: WebUser },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "GET",
      }),
    }),
    deleteUserById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "DELETE",
      }),
    }),
    updateUserStatus: builder.mutation<
      CommonResponseType & { data: Category },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.user}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getUserNft: builder.query<
      CommonResponseType & { data: UserNftRes },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.userNft}/${id}`,
        method: "GET",
      }),
    }),
    getUserClaimedCoupon: builder.query<
      CommonResponseType & { data: ClaimedRes },
      { id: string; filter: string }
    >({
      query: ({ id, filter }) => ({
        url: `${END_POINTS.userClaimedCoupon}/${id}?filter=${filter}`,
        method: "GET",
      }),
    }),
    getUserClaimedCouponById: builder.query<
      CommonResponseType & { data: ClaimedResponse },
      { userId: string; id: string }
    >({
      query: ({ userId, id }) => ({
        url: `${END_POINTS.userClaimedCoupon}/${userId}?couponId=${id}`,
        method: "GET",
      }),
    }),
    //category
    getCategory: builder.query<
      CommonResponseType & { data: CategoryRes },
      GetParams & { pagination?: boolean | undefined }
    >({
      query: ({ size, page, query, pagination }) => ({
        url: `${END_POINTS.category}?page=${page}&size=${size}&search=${query}${
          pagination ? `&pagination=${pagination}` : ""
        }`,
        method: "GET",
      }),
    }),

    getCategoryById: builder.query<
      CommonResponseType & { data: Category },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "GET",
      }),
    }),
    deleteCategoryById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "DELETE",
      }),
    }),
    addCategory: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.category,
        method: "POST",
        body,
      }),
    }),
    updateCategory: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.category}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateCategoryStatus: builder.mutation<
      CommonResponseType & { data: Category },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.category}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getSubCategoryById: builder.query<
      CommonResponseType & { data: CategoryRes },
      {
        id: string;
        pagination?: boolean | undefined;
        blockStatus?: boolean | undefined;
      } & GetParams
    >({
      query: ({ id, size, page, query, pagination, blockStatus }) => ({
        url: `${
          END_POINTS.subCategoryByCategory
        }/${id}?page=${page}&size=${size}&search=${query}${
          pagination ? `&pagination=${pagination}` : ""
        }${blockStatus ? `&blockStatus=${blockStatus}` : ""}`,
        method: "GET",
      }),
    }),

    //banner
    getBanner: builder.query<
      CommonResponseType & { data: BannerRes },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.banner +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getBanneryById: builder.query<
      CommonResponseType & { data: Banner },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.banner}/${id}`,
        method: "GET",
      }),
    }),
    deleteBannerById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.banner}/${id}`,
        method: "DELETE",
      }),
    }),
    addBanner: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.banner,
        method: "POST",
        body,
      }),
    }),
    updateBanner: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.banner}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateBannerStatus: builder.mutation<
      CommonResponseType & { data: Banner },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.banner}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    //blog
    getAllBlog: builder.query<
      CommonResponseType & { data: BlogRes },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.blog +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getBlogById: builder.query<
      CommonResponseType & { data: Blog },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.blog}/${id}`,
        method: "GET",
      }),
    }),
    deleteBlogById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.blog}/${id}`,
        method: "DELETE",
      }),
    }),
    addBlog: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.blog,
        method: "POST",
        body,
      }),
    }),
    updateBlog: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.blog}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateBlogStatus: builder.mutation<
      CommonResponseType & { data: Blog },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.blog}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    //coupon
    getAllCoupon: builder.query<
      CommonResponseType & { data: CouponRes },
      { filter: string; page: number; size: number; query: string }
    >({
      query: ({ filter, page, size, query }) => ({
        url:
          END_POINTS.coupon +
          "?filter=" +
          filter +
          "&page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getCouponById: builder.query<
      CommonResponseType & { data: Coupon },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.coupon}/${id}`,
        method: "GET",
      }),
    }),

    deleteCouponById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.coupon}/${id}`,
        method: "DELETE",
      }),
    }),
    addCoupon: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.coupon,
        method: "POST",
        body,
      }),
    }),
    updateCoupon: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.coupon}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateCouponStatus: builder.mutation<
      CommonResponseType & { data: CouponRes },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.coupon}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getCouponNft: builder.query<
      CommonResponseType & { data: CouponNftRes },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.couponNft}/${id}`,
        method: "GET",
      }),
    }),
    //collection center
    getAllCollection: builder.query<
      CommonResponseType & { data: CollectionRes },
      GetParams
    >({
      query: ({ size, page, query }) => ({
        url:
          END_POINTS.collection +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query,
        method: "GET",
      }),
    }),

    getCollectionById: builder.query<
      CommonResponseType & { data: Collection },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.collection}/${id}`,
        method: "GET",
      }),
    }),
    deleteCollectionById: builder.query<CommonResponseType, { id: string }>({
      query: ({ id }) => ({
        url: `${END_POINTS.collection}/${id}`,
        method: "DELETE",
      }),
    }),
    addCollection: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.collection,
        method: "POST",
        body,
      }),
    }),
    updateCollection: builder.mutation<
      CommonResponseType,
      { id: string; body: CommonBody }
    >({
      query: ({ id, body }) => ({
        url: `${END_POINTS.collection}/${id}/`,
        method: "PUT",
        body,
      }),
    }),
    updateCollectionStatus: builder.mutation<
      CommonResponseType & { data: Collection },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.collection}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    //nft
    addNft: builder.mutation<CommonResponseType, CommonBody>({
      query: (body) => ({
        url: END_POINTS.addNft,
        method: "POST",
        body,
      }),
    }),
    updatePlatformNftStatus: builder.mutation<
      CommonResponseType & { data: Nft },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.platformNft}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    updateAdminNftStatus: builder.mutation<
      CommonResponseType & { data: Nft },
      {
        id: string;
        body: CommonBody;
      }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.adminNft}/${id}`,
        method: "PUT",
        body,
      }),
    }),
    getPlatformPassiveNft: builder.query<
      CommonResponseType & { data: NftRes },
      GetParams & {
        filter?: number | undefined;
        sort?: number | undefined | string;
        categoryId?: string | undefined;
        sortType?: string | undefined;
      }
    >({
      query: ({ size, page, query, sort, sortType, categoryId, filter }) => ({
        url:
          END_POINTS.platformNft +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query +
          "&sort=" +
          sort +
          "&sortType=" +
          sortType +
          "&categoryId=" +
          categoryId +
          "&filter=" +
          filter,
        method: "GET",
      }),
    }),
    getPlatformNftById: builder.query<
      CommonResponseType & { data: Nft },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.platformNft}/${id}`,
        method: "GET",
      }),
    }),

    getAdminPassiveNft: builder.query<
      CommonResponseType & { data: NftRes },
      GetParams & {
        filter?: number | undefined;
        sort?: number | undefined | string;
        categoryId?: string | undefined;
        sortType?: string | undefined;
      }
    >({
      query: ({ size, page, query, sort, sortType, categoryId, filter }) => ({
        url:
          END_POINTS.adminNft +
          "?page=" +
          page +
          "&size=" +
          size +
          "&search=" +
          query +
          "&sort=" +
          sort +
          "&sortType=" +
          sortType +
          "&categoryId=" +
          categoryId +
          "&filter=" +
          filter,
        method: "GET",
      }),
    }),
    getAdminNftById: builder.query<
      CommonResponseType & { data: Nft },
      { id: string }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.adminNft}/${id}`,
        method: "GET",
      }),
    }),
    redeemCoupon: builder.mutation<
      CommonResponseType & { data: Category },
      {
        id: string;
      }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.redeemCoupon}/${id}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useLazyGetUsersQuery,
  useLazyDeleteUserByIdQuery,
  useLazyGetUserByIdQuery,
  useUpdateUserStatusMutation,
  useLazyGetUserClaimedCouponQuery,
  useLazyGetUserNftQuery,
  //category
  useAddCategoryMutation,
  useLazyDeleteCategoryByIdQuery,
  useLazyGetCategoryByIdQuery,
  useLazyGetCategoryQuery,
  useUpdateCategoryMutation,
  useUpdateCategoryStatusMutation,
  useLazyGetSubCategoryByIdQuery,
  //banner
  useAddBannerMutation,
  useLazyGetBannerQuery,
  useLazyGetBanneryByIdQuery,
  useUpdateBannerMutation,
  useLazyDeleteBannerByIdQuery,
  useUpdateBannerStatusMutation,
  //blog
  useAddBlogMutation,
  useLazyGetAllBlogQuery,
  useLazyGetBlogByIdQuery,
  useUpdateBlogMutation,
  useUpdateBlogStatusMutation,
  useLazyDeleteBlogByIdQuery,
  //coupon
  useAddCouponMutation,
  useLazyGetAllCouponQuery,
  useLazyGetCouponByIdQuery,
  useUpdateCouponMutation,
  useUpdateCouponStatusMutation,
  useLazyDeleteCouponByIdQuery,
  useLazyGetUserClaimedCouponByIdQuery,
  useLazyGetCouponNftQuery,
  //collection
  useAddCollectionMutation,
  useLazyGetAllCollectionQuery,
  useLazyGetCollectionByIdQuery,
  useUpdateCollectionMutation,
  useLazyDeleteCollectionByIdQuery,
  useUpdateCollectionStatusMutation,
  //nft
  useLazyGetPlatformPassiveNftQuery,
  useLazyGetPlatformNftByIdQuery,
  useLazyGetAdminPassiveNftQuery,
  useLazyGetAdminNftByIdQuery,
  useAddNftMutation,
  useUpdatePlatformNftStatusMutation,
  useUpdateAdminNftStatusMutation,
  useRedeemCouponMutation,
} = mainApi;
