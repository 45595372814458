import { useState } from "react";
import { Button, TextField, InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  useLazyGetUserQuery,
  usePostChangePasswordMutation,
} from "../../../services/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../../helpers";
import Loader from "../../../constants/Loader";
import { useNavigate } from "react-router-dom";
import { setCredentials } from "../../../reducers/authSlice";
import { useAppDispatch } from "../../../hooks/store";

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getUser] = useLazyGetUserQuery();
  const [changePassword, { isLoading }] = usePostChangePasswordMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword1 = () => {
    setShowPassword1(!showPassword1);
  };
  const handleMouseDownPassword1 = (event: any) => {
    event.preventDefault();
  };

  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const getUserDetails = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    try {
      const result = await getUser({}).unwrap();
      if (result?.statusCode === 200 && token) {
        dispatch(
          setCredentials({
            user: result?.data,
            token: token,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .label("Old Password")
        .required("Old password is required."),

      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as old password."
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
        passwordChanged: true,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await changePassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Password changed successfully" || "");
          navigate("/dashboard");
          getUserDetails();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
      formik.setSubmitting(false);
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />
      <div className="password_page">
        <form onSubmit={formik.handleSubmit}>
          <div className="form_group gap_m">
            <div className="form_control form_control_bg w_50">
              <label className="custom_label">Current Password</label>
              <div className="position_relative">
                <TextField
                  hiddenLabel
                  placeholder="Current Password"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  className="text_field"
                  name="oldPassword"
                  id="oldPassword"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.oldPassword}
                  helperText={
                    formik.touched.oldPassword && formik.errors.oldPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="form_control form_control_bg w_50">
              <label className="custom_label">New Password</label>
              <div className="position_relative">
                <TextField
                  hiddenLabel
                  placeholder="New Password"
                  fullWidth
                  type={showPassword1 ? "text" : "password"}
                  variant="outlined"
                  className="text_field"
                  name="newPassword"
                  id="newPassword"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.newPassword}
                  helperText={
                    formik.touched.newPassword && formik.errors.newPassword
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword1}
                          edge="end"
                        >
                          {showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className="form_control form_control_bg w_50">
              <label className="custom_label">Confirm Password</label>
              <div className="position_relative">
                <TextField
                  hiddenLabel
                  placeholder="Confirm Password"
                  fullWidth
                  type={showPassword2 ? "text" : "password"}
                  variant="outlined"
                  className="text_field"
                  name="passwordConfirmation"
                  id="passwordConfirmation"
                  onChange={(val) => {
                    if (val.target.value === " " || val.target.value === ".") {
                    } else {
                      formik.handleChange(val);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  value={formik.values.passwordConfirmation}
                  helperText={
                    formik.touched.passwordConfirmation &&
                    formik.errors.passwordConfirmation
                  }
                  InputProps={{
                    endAdornment: (
                      <InputAdornment className="eye_btn" position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword2}
                          onMouseDown={handleMouseDownPassword2}
                          edge="end"
                        >
                          {showPassword2 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
          <div className="form_btn">
            <Button size="large" type="submit" className="btn btn_primary">
              Save
            </Button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ChangePassword;
