import React, { useState, ChangeEvent, useEffect } from "react";
import {
  CardMedia,
  TextField,
  Input,
  Select,
  MenuItem,
  SelectChangeEvent,
  Switch,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import PhoneInput from "react-phone-input-2";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { AddressType, CommonBody } from "../../../types/General";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../../helpers";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useAddOrganisationMutation,
  useLazyDeleteOrganisationByIdQuery,
  useLazyGetOrganisationByIdQuery,
  useUpdateOrganisationMutation,
} from "../../../services/organisation";
import { isString } from "../../../utils/validation";
import { UploadMedia, Uploadpdf } from "../../../utils/mediaUpload";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../../constants/Loader";
import { User } from "../../../types/User";
import WarnModal from "../../../components/modals/WarnModal";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../hooks/store";
import { setCredentials } from "../../../reducers/authSlice";
import EditText from "../../../components/EditText";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function OrgDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useAuth();
  const dispatch = useAppDispatch();

  const [organisationById] = useLazyGetOrganisationByIdQuery();
  const [addOrganisation, { isLoading }] = useAddOrganisationMutation();
  const [updateOrganisation, updateOrganisationData] =
    useUpdateOrganisationMutation();
  const [deleteById, deleteByIdData] = useLazyDeleteOrganisationByIdQuery();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  console.log("error: ", error);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState<any>([]);
  console.log("items: ", items);
  const [phoneNo, setPhoneNo] = useState<string>("");
  const [countryCode, setCountryCode] = useState<string>("+971");
  const [addressField, setAddressField] = useState<string>("");
  console.log("addressField: ", addressField);
  const [organizationType, setorganizationType] = useState("1");
  const [organisation, setOrganisation] = useState<User | null>(null);

  const handleChangeSelect = (event: SelectChangeEvent) => {
    setorganizationType(event.target.value as string);
  };

  const [planType, setplanType] = useState("Monthly");
  const handleChangeSelect2 = (event: SelectChangeEvent) => {
    setplanType(event.target.value as string);
  };

  const handleAddAddress = () => {
    if (!addressField) {
      setError(true);
      return;
    }
    if (addressField.trim() !== "") {
      const newItem: AddressType = {
        address: addressField,
        phone: phoneNo,
        countryCode: countryCode,
      };
      console.log(newItem, "newItem12");

      setItems([newItem, ...items]);
      setAddressField("");
      setPhoneNo("");
      setCountryCode("us");
      setError(false);
    }
  };

  const handleDeleteClick = (index: any) => {
    const updatedItems = [...items];
    updatedItems.splice(index, 1);
    setItems(updatedItems);
  };

  //for image upload
  const handleImageUpload = async (event: any, key: string) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          if (key === "image") {
            formik.setFieldValue("image", res?.data);
          } else {
            formik.setFieldValue("bannerImage", res?.data);
          }
        } else {
          errorToast(res?.message);
        }
      } else {
        // Display an error message for non-image files
        errorToast("Please Try Again");
      }
    }
  };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const handleChangePhone2 = (phone: any, country: any) => {
    setPhoneNo(phone?.replace(country.dialCode, ""));
    setCountryCode(
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
  };

  //pdf
  const [selectedDocs, setSelectedDocs] = useState<any[]>([]);
  const [imageRes, setImageRes] = useState<string>();
  const [type, setType] = useState<string>("edit");

  const handlePdfUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const fileList = files?.files;

    if (fileList?.length) {
      setLoading(true);
      const pdfPromises = Array.from(fileList).map(async (file) => {
        const res = await Uploadpdf(file);
        if (res?.statusCode === 200) {
          return { url: res?.data };
        } else {
          errorToast(res?.message);
          return null;
        }
      });

      const uploadedPDFs = await Promise.all(pdfPromises);
      const validPDFs = uploadedPDFs.filter((pdf) => pdf !== null);
      if (validPDFs.length > 0) {
        setSelectedDocs((prevDocs) => [...prevDocs, ...validPDFs]);
        setImageRes(validPDFs[0]?.url);
      }
      setLoading(false);
    }
  };

  // const uploadPdf = async (file: File) => {
  //   // Your PDF upload logic here, return the result
  //   // For example purposes, I'm assuming you have an Uploadpdf function
  //   return Uploadpdf(file);
  // };

  const removePdf = (index: number) => {
    const updatedDocs = [...selectedDocs];
    updatedDocs.splice(index, 1);
    setSelectedDocs(updatedDocs);
    setImageRes(updatedDocs.length > 0 ? updatedDocs[0]?.url : undefined);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: organisation?.email || "",
      phone: organisation?.phone || "",
      countryCode: organisation?.countryCode || "+971",
      image: organisation?.image || "",
      bannerImage: organisation?.bannerImage || "",
      companyName: organisation?.companyName || "",
      companyDomain: organisation?.companyWebSite || "",
      companyAddress: organisation?.companyAddress || "",
      description: organisation?.description || "",
      offer: organisation?.offer || "",
      startData: organisation?.startDate
        ? moment(organisation?.startDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
      endDate: organisation?.endDate
        ? moment(organisation?.endDate).format("YYYY-MM-DD")
        : "",
    },
    validationSchema: Yup.object({
      image: Yup.string().required("Image is required"),
      bannerImage: Yup.string().required("Banner Image is required"),
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      phone: Yup.string()
        .required("Phone number is required")
        .min(8, "Phone number must be at least 8 digits")
        .max(16, "Phone number must be at least 16 digits"),
      companyName: Yup.string()
        .required("Company Name is required")
        .max(80, "Maximum 80 character are allowed")
        .min(2, "Minimum 2 characters are required"),
      description: Yup.string()
        .required("Description is required")
        .max(3000, "Maximum 3000 character are allowed")
        .min(2, "Minimum 2 characters are required")
        .test("not-empty-p-tags", "Invalid content", (value) => {
          return !/^\s*<p>\s*<\/p>\s*$/.test(value);
        }),

      offer: Yup.string()
        .required("Offer is required")
        .max(3000, "Maximum 3000 character are allowed")
        .min(2, "Minimum 2 characters are required")
        .test("not-empty-p-tags", "Invalid content", (value) => {
          return !/^\s*<p>\s*<\/p>\s*$/.test(value);
        }),

      companyDomain: Yup.string()
        .required("Company Domain is required")
        .matches(
          /^(https?:\/\/)?((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i,
          "Enter a valid URL format"
        ),
      startData: Yup.string().required("This field is required"),
      // endDate: Yup.string().required("This field is required"),
    }),
    onSubmit: async (values) => {
      console.log("hihittt");

      formik.setSubmitting(true);

      // if (formik.values.startData > formik.values.endDate) {
      //   errorToast("Please select valid dates");
      //   return;
      // }
      // Push address data into items array
      let arr = [...items];
      console.log(arr, "itemsitems");
      // if (addressField.trim() !== "") {
      //   const newItem: AddressType = {
      //     address: addressField,
      //     phone: phoneNo,
      //     countryCode: countryCode,
      //   };
      //   items.push(newItem);
      // }
      // setError(false);
      if (addressField) {
        const newItem: AddressType = {
          address: addressField,
          phone: phoneNo,
          countryCode: countryCode,
        };
        console.log("newItem: jj", addressField, newItem);
        arr.push(newItem);

        // setItems([newItem, ...items]);
      }
      // if (!items?.length || addressField) {
      //   errorToast("Please Add Address");
      //   return;
      // }

      console.log(arr, "arr");

      if (!arr?.length) {
        // errorToast("Add Address first");
        return;
      }
      let body = {
        email: formik.values.email,
        description: formik.values.description,
        offer: formik.values.offer,
        countryCode: formik.values.countryCode,
        phone: formik.values.phone,
        image: formik.values.image,
        bannerImage: formik.values.bannerImage,
        companyName: formik.values.companyName,
        companyWebSite: formik.values.companyDomain,
        address: arr,
        permission: [],
        b2bType: organizationType,
        planType: planType,
        startDate: formik.values.startData,
        ...(formik.values.endDate ? { endDate: formik.values.endDate } : {}),
        docs: selectedDocs,
      };
      console.log(body, "body>>");

      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateOrganisation({
            body: encryptedBody,
            id,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Organisation Updated Successfully");
            formik.resetForm();
            setAddressField("");
            setPhoneNo("");
            setCountryCode("+971");
            navigate(
              id ? `/manage-organization/view/${id}` : `/manage-organization`
            );
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await addOrganisation(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Organisation Added Successfully");
            formik.resetForm();
            setAddressField("");
            setPhoneNo("");
            setCountryCode("+971");
            navigate("/manage-organization");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });

  const getOrganisationById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const response = await organisationById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setOrganisation(response?.data);
        setorganizationType(String(response?.data?.b2bType));
        setplanType(response?.data?.planType);
        setItems(response?.data?.address || []);
        setSelectedDocs(response?.data?.docs);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const handleDelete = async () => {
    if (id) {
      try {
        const response = await deleteById({ id }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Deleted Successfully");
          navigate("/manage-organization");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getOrganisationById(id);
    } else {
      setType("add");
    }
  }, []);

  return (
    <>
      <Loader
        isLoad={isLoading || updateOrganisationData?.isLoading || loading}
      />

      <div className="dashboard sec_head fs_22 d_flx">
        <h2>{id ? "Edit" : "Add"} Organization</h2>
        <div className="bck_btn ">
          <button
            className="btn btn_sm btn_primary"
            onClick={() =>
              navigate(
                id ? `/manage-organization/view/${id}` : `/manage-organization`
              )
            }
          >
            Back
          </button>
        </div>
      </div>
      <div className="edit_info org_form form_group">
        <div className="form_title fs_16">
          <h2>General Info</h2>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form_group gap_m">
            <div className=" form_control form_control_bg prfl_pic w_50">
              <label>Upload Logo</label>
              {formik.values.image ? (
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <CardMedia
                        component="img"
                        image={formik.values.image}
                        alt="photo"
                      />
                    </figure>
                    <CloseIcon
                      onClick={() => {
                        formik.setFieldValue("image", "");
                      }}
                    />
                  </div>
                </div>
              ) : (
                <label
                  className="upload_image"
                  htmlFor="icon-button-file"
                  style={{ display: "block" }}
                >
                  <Input
                    sx={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    inputProps={{
                      accept: "image/png,image/jpeg",
                    }}
                    onChange={(e) => handleImageUpload(e, "image")}
                  />
                  <span className="upload_image_holder">
                    <figure>
                      <img src="/images/placeholder_user_icon.png" alt="" />
                    </figure>
                    <AddIcon />
                  </span>
                </label>
              )}
              {!formik.values.image && error ? (
                <h6 className="err_msg">This field is required</h6>
              ) : (
                ""
              )}
            </div>

            {/* banner image */}
            <div className=" form_control form_control_bg prfl_pic w_50">
              <label>Upload Banner Image (dimensions 498×540)</label>
              {formik.values.bannerImage ? (
                <div className="upload_image full_img">
                  <div className="upload_image_holder">
                    <figure>
                      <CardMedia
                        component="img"
                        image={formik.values.bannerImage}
                        alt="photo"
                      />
                    </figure>
                    <CloseIcon
                      onClick={() => {
                        formik.setFieldValue("bannerImage", "");
                      }}
                    />
                  </div>
                </div>
              ) : (
                <label
                  className="upload_image full_img"
                  htmlFor="icon-button-file-banner"
                  style={{ display: "block" }}
                >
                  <Input
                    sx={{ display: "none" }}
                    id="icon-button-file-banner"
                    type="file"
                    inputProps={{
                      accept: "image/png,image/jpeg",
                    }}
                    onChange={(e) => handleImageUpload(e, "bannerImage")}
                  />
                  <span className="upload_image_holder">
                    <figure>
                      <img src="/images/placeholder_gallery.jpeg" alt="" />
                    </figure>
                    <AddIcon />
                  </span>
                </label>
              )}
              {!formik.values.bannerImage && error ? (
                <h6 className="err_msg">This field is required</h6>
              ) : (
                ""
              )}
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Name of the company</label>

              <TextField
                hiddenLabel
                placeholder="Company Name"
                fullWidth
                name="companyName"
                type="text"
                variant="outlined"
                className="text_field"
                id="companyName"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
              />
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Organization Type</label>
              <Select
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="organization-type"
                value={organizationType}
                onChange={handleChangeSelect}
              >
                <MenuItem value={1}>Vendor</MenuItem>
                <MenuItem value={2}>Partner</MenuItem>
                <MenuItem value={3}>Community</MenuItem>
              </Select>
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Company website domain</label>
              <TextField
                hiddenLabel
                placeholder="example.com"
                fullWidth
                type="text"
                variant="outlined"
                className="text_field"
                name="companyDomain"
                id="companyDomain"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.companyDomain}
                helperText={
                  formik.touched.companyDomain && formik.errors.companyDomain
                }
              />
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Business Email address</label>
              <TextField
                disabled={id ? true : false}
                hiddenLabel
                placeholder="Email"
                fullWidth
                name="email"
                type="text"
                variant="outlined"
                id="email"
                className="text_field"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Mobile number</label>
              <PhoneInput
                value={formik.values.countryCode + formik.values.phone}
                country={"us"}
                placeholder="0 (000) 000-000"
                enableSearch={true}
                inputStyle={{ width: "100%" }}
                onChange={(phone, country) => handleChangePhone(phone, country)}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <h6 className="err_msg">
                  {formik.touched.phone && formik.errors.phone}
                </h6>
              ) : (
                ""
              )}
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Plan Type</label>
              <Select
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="plan-type"
                value={planType}
                onChange={handleChangeSelect2}
              >
                <MenuItem value="Monthly">Monthly</MenuItem>
                <MenuItem value="Halfly">Half Yearly</MenuItem>
                <MenuItem value="Yearly"> Yearly</MenuItem>
                <MenuItem value="Life Time">Life Time</MenuItem>
              </Select>
            </div>

            <div className="form_control">
              <div className="gap_m">
                <label> Address</label>
                {items?.length ? (
                  <div className="box">
                    <ul>
                      {items?.map((item: any, i: any) => (
                        <li key={i}>
                          <CloseIcon onClick={() => handleDeleteClick(i)} />
                          <h4 style={{ lineBreak: "anywhere" }}>
                            {item?.address || ""}
                          </h4>
                          {item?.phone ? (
                            <p>
                              {item?.countryCode} {item?.phone}
                            </p>
                          ) : null}
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : null}

                <div className="form_control form_control_bg w_50">
                  <label>Address</label>
                  <TextField
                    hiddenLabel
                    placeholder="Address"
                    fullWidth
                    name="address"
                    type="text"
                    variant="outlined"
                    id="address"
                    className="text_field"
                    value={addressField}
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        setAddressField(val.target.value);
                      }
                    }}
                  />
                  {error && !addressField && !items?.length ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form_control form_control_bg w_50">
                  <label>Contact number</label>
                  <PhoneInput
                    value={countryCode + phoneNo}
                    country={"us"}
                    placeholder="Contact Number"
                    enableSearch={true}
                    inputStyle={{ width: "100%" }}
                    onChange={(phone, country) =>
                      handleChangePhone2(phone, country)
                    }
                    onBlur={formik.handleBlur}
                  />

                  {error && phoneNo?.length && phoneNo?.length < 7 ? (
                    <span className="helper-text">
                      <h6 className="err_msg">
                        Phone number must have at least 7 digits.
                      </h6>
                    </span>
                  ) : undefined}
                </div>
              </div>
              <div className="text_end">
                <a
                  href="javascript:void(0)"
                  className="btn_link sm"
                  onClick={handleAddAddress}
                >
                  +ADD ADDRESS
                </a>
              </div>
            </div>

            <div className="form_control form_control_bg w_50">
              <label>Start Date</label>
              <TextField
                // disabled
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
                onKeyDown={(e) => e.preventDefault()}
                hiddenLabel
                placeholder="Start Date"
                fullWidth
                type="date"
                variant="outlined"
                className="text_field"
                name="startData"
                id="startData"
                value={formik.values.startData}
                onChange={formik.handleChange}
                helperText={formik.touched.startData && formik.errors.startData}
              />
            </div>
            <div className="form_control form_control_bg w_50">
              <label>End Date</label>
              <TextField
                hiddenLabel
                placeholder="End Date"
                fullWidth
                type="date"
                variant="outlined"
                className="text_field"
                name="endDate"
                id="endDate"
                inputProps={{
                  min:
                    formik.values.startData ||
                    new Date().toISOString().split("T")[0],
                  max: (() => {
                    const maxDate = new Date();
                    maxDate.setFullYear(maxDate.getFullYear() + 10); // Adjust this if you want a different maximum year
                    return maxDate.toISOString().split("T")[0];
                  })(),
                }}
                // inputProps={{
                //   min:
                //     formik.values.startData ||
                //     new Date().toISOString().split("T")[0],
                // }}
                value={formik.values.endDate}
                onChange={formik.handleChange}
                helperText={formik.touched.endDate && formik.errors.endDate}
              />
            </div>

            {/* <div className="form_control form_control_bg w_50" /> */}
            <div className="form_control form_control_bg w_100">
              <label>Description</label>

              {/* <TextField
                multiline
                maxRows={4}
                minRows={4}
                hiddenLabel
                placeholder="Description"
                fullWidth
                name="description"
                type="text"
                variant="outlined"
                className="text_field"
                id="description"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              /> */}
              {type === "edit" && formik.values.description ? (
                <EditText
                  content={formik.values.description}
                  setContent={(value) =>
                    formik.setFieldValue("description", value)
                  }
                />
              ) : type === "add" ? (
                <EditText
                  content={formik.values.description}
                  setContent={(value) =>
                    formik.setFieldValue("description", value)
                  }
                />
              ) : null}
              {(!formik.values.description ||
                /^\s*<p>\s*<\/p>\s*$/.test(formik.values.description)) &&
              error ? (
                <h6 className="err_msg">This field is required</h6>
              ) : null}
            </div>

            <div className="form_control form_control_bg w_100">
              <label>Offer</label>

              {/* <TextField
                multiline
                maxRows={4}
                minRows={4}
                hiddenLabel
                placeholder="Offer"
                fullWidth
                name="offer"
                type="text"
                variant="outlined"
                className="text_field"
                id="offer"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.offer}
                helperText={formik.touched.offer && formik.errors.offer}
              /> */}

              {type === "edit" && formik.values.offer ? (
                <EditText
                  content={formik.values.offer}
                  setContent={(value) => formik.setFieldValue("offer", value)}
                />
              ) : type === "add" ? (
                <EditText
                  content={formik.values.offer}
                  setContent={(value) => formik.setFieldValue("offer", value)}
                />
              ) : null}
              {(!formik.values.offer ||
                /^\s*<p>\s*<\/p>\s*$/.test(formik.values.offer)) &&
              error ? (
                <h6 className="err_msg">This field is required</h6>
              ) : null}
            </div>

            <div className="form_control form_control_bg w_100">
              <label>Upload Docs</label>
              <div className="gap_m pdf_divv_wrp">
                {selectedDocs?.length
                  ? selectedDocs?.map((pdf, index) => (
                      <div
                        key={index}
                        className=" pdf_divv"
                        style={{ position: "relative" }}
                      >
                        <div className="pdf_cont">
                          <CloseIcon onClick={() => removePdf(index)} />
                        </div>
                        <figure>
                          <a
                            href={pdf.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Document file={pdf}>
                              <Page pageNumber={1} />
                            </Document>
                          </a>
                        </figure>
                      </div>
                    ))
                  : null}
                <label
                  className="upload_image upload_docs w_50"
                  htmlFor="icon-button-file-pdf"
                >
                  <Input
                    sx={{ display: "none" }}
                    id="icon-button-file-pdf"
                    inputProps={{
                      accept: "application/pdf",
                      multiple: true,
                    }}
                    type="file"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handlePdfUpload(event)
                    }
                  />
                  <span className="upload_image_holder w_50">
                    <figure className="">
                      <img
                        src="/images/placeholder_gallery.jpeg"
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    </figure>
                    <AddIcon />
                  </span>
                </label>
              </div>
            </div>
          </div>
          <div className="btn_group">
            <button
              style={{ marginRight: 10 }}
              className="btn btn_sm btn_primary"
              onClick={() => setError(true)}
            >
              Save Detail
            </button>
            {id ? (
              <h3
                className="btn btn_sm  btn_danger"
                onClick={() => setOpen(true)}
              >
                Delete Profile
              </h3>
            ) : null}
          </div>
        </form>
      </div>

      <WarnModal
        setOpen={setOpen}
        open={open}
        name="Organisation"
        handleDelete={handleDelete}
      />
    </>
  );
}
