import React from "react";
import MainContainer from "../../layout/MainContainer";
import NftList from "./features/nftList";

const ManageNft = () => {
  return (
    <>
      <MainContainer name="Manage NFT">
        <NftList />
      </MainContainer>
    </>
  );
};

export default ManageNft;
