import ManageEmployee from "./manageEmployee";
import AddEmployee from "./manageEmployee/add";
import ManageCoupons from "./managecoupons";
import ManageRole from "./managerole/Index";
import CouponDetail from "./managecoupons/CouponDetail";
import AddAdminCoupon from "./admincoupon/add";
import Login from "./auth/login";
import ForgotPassword from "./auth/forgot-password";
import HelpSupport from "./helpsupport";
import ManageCms from "./cms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import Analytics from "./analytics/analytics";
import Blog from "./manageblog";
import AddBlog from "./manageblog/add";
import ManageNft from "./manageNft";
import ManageCategory from "./category";
import AddCategory from "./category/add";
import ManageSubcategory from "./category/subcat";
import AddSubCategory from "./category/addsubcate";
import ManageBanner from "./managebanner";
import AddBanner from "./managebanner/add";
import EditNft from "./manageNft/add";
import Dashboard from "./dashboard";
import ManageCollection from "./collection";
import AddCollection from "./collection/add";
import Profile from "./profile/profile";
import ChangePassword from "./profile/profile/changePassword";
import OrganizationList from "./organization";
import OraganizationDetail from "./organization/add";
import Otp from "./auth/otp";
import ResetPassword from "./auth/reset-password";
import Register from "./auth/register";
import ProfileSetup from "./auth/profile-setup";
import OrganizationView from "./organization/detail";
import EmployeeView from "./manageEmployee/detail";
import ManageUsers from "./manageUsers";
import ManageUsersView from "./manageUsers/view";
import ManageCollectionCenter from "./manageCollectionCenter";
import AddCollectionCenter from "./manageCollectionCenter/add";
import AddCoupon from "./managecoupons/add";
import EditPlatformNft from "./managePlatformNft/add";
import ManagePlatFormNft from "./managePlatformNft";
import AddNft from "./managePlatformNft/addNft";
import Viewquery from "./helpsupport/viewquery";
import ClaimedCoupon from "./manageUsers/features/claimedCoupon";
import CouponNft from "./managecoupons/feature/CouponNft";
import AddNews from "./manageNews/add";
import NewsPage from "./manageNews";
import AddTestimonials from "./manageTestimonials/add";
import TestimonialPage from "./manageTestimonials";
import ManageSuperAdmin from "./manageSuperAdmin";
import ManageNewsletter from "./manageNewsletter";
import { WebNotSupport } from "./web3NotSupport";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  WebNotSupport,
  Login,
  Register,
  ProfileSetup,
  ManageEmployee,
  AddEmployee,
  ManageRole,
  ManageCoupons,
  CouponDetail,
  AddAdminCoupon,
  ForgotPassword,
  HelpSupport,
  ManageCms,
  ManageNotifications,
  AddNotification,
  RecievedNotifications,
  Analytics,
  Blog,
  AddBlog,
  ManageNft,
  ManageCategory,
  AddCategory,
  ManageSubcategory,
  AddSubCategory,
  ManageBanner,
  AddBanner,
  EditNft,
  Dashboard,
  ManageCollection,
  AddCollection,
  Profile,
  ChangePassword,
  OrganizationList,
  OraganizationDetail,
  Otp,
  ResetPassword,
  OrganizationView,
  EmployeeView,
  ManageUsers,
  ManageUsersView,
  ManageCollectionCenter,
  AddCollectionCenter,
  AddCoupon,
  EditPlatformNft,
  ManagePlatFormNft,
  AddNft,
  Viewquery,
  ClaimedCoupon,
  CouponNft,
  AddNews,
  NewsPage,
  AddTestimonials,
  TestimonialPage,
  ManageSuperAdmin,
  ManageNewsletter,
};
