import MainContainer from "../../layout/MainContainer";
import UserListing from "./features/userListing";

const ManageUsers = () => {

    return (
        <MainContainer>
            <div className="main_loyout rpt_card">
                <UserListing />
            </div>
        </MainContainer>
    );
};

export default ManageUsers;
