import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Button, Card, Grid, TextField, Typography } from "@mui/material";
import EditText from "../../components/EditText";
import * as Yup from "yup";
import { useFormik } from "formik";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/bootstrap.css";
import Faq from "./feature/faq";
import PhoneInput from "react-phone-input-2";
import { useLazyGetCmsQuery, usePostCmsMutation } from "../../services/common";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";
import Loader from "../../constants/Loader";
import { isWeb3Enabled, setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageCms = () => {
  const user = useAuth();
  const dispatch = useAppDispatch();
  const [addCms, { isLoading }] = usePostCmsMutation();
  const [cmsDetails, cmsDetailsData] = useLazyGetCmsQuery();

  const [privacyPolicy, setPrivacyPolicy] = useState<string>("");
  const [disclaimer, setDisclaimer] = useState<string>("");
  const [terms, setTerms] = useState<string>("");

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // for country picker
  const handleChangePhone = (phone: any, country: any) => {
    formik.setFieldValue("phone", phone?.replace(country.dialCode, ""));
    formik.setFieldValue(
      "countryCode",
      country?.dialCode.includes("+") ? "" : "+" + country?.dialCode
    );
    formik.setFieldValue("countryName", country?.countryCode.toUpperCase());
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      phone: "",
      countryCode: "+971",
      address: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Email is required")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),

      phone: Yup.string()
        .required("Phone number is required")
        .min(8, "Phone number must be at least 8 digits")
        .max(16, "Phone number must be at least 16 digits"),
      address: Yup.string()
        // .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(250, "Maximum 256 character are allowed"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const body = {
        email: formik.values.email,
        phone: formik.values.phone,
        countryCode: formik.values.countryCode,
        privacyPolicy: privacyPolicy,
        disclaimer: disclaimer,
        address: formik.values.address,
        termsAndCondition: terms,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await addCms(encryptedBody).unwrap();

        if (response?.statusCode === 200) {
          successToast(response?.message || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });

  const getCmsDetail = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await cmsDetails({}).unwrap();
      if (res?.statusCode === 200 && res?.data?.email) {
        formik.setFieldValue("email", res?.data?.email);
        formik.setFieldValue("phone", res?.data?.phone);
        formik.setFieldValue("countryCode", res?.data?.countryCode);
        formik.setFieldValue("address", res?.data?.address);
        setPrivacyPolicy(res?.data?.privacyPolicy || " ");
        setDisclaimer(res?.data?.disclaimer || " ");
        setTerms(res?.data?.termsAndCondition || " ");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCmsDetail();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || cmsDetailsData?.isLoading} />
      <div className="main_loyout cms_pages">
        <div className="dashboard sec_head">
          <h1>Manage CMS</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs cms_pg_wrp ">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Contact Support" {...a11yProps(0)} />
              <Tab label="Terms and conditions" {...a11yProps(1)} />
              <Tab label="Privacy policy" {...a11yProps(2)} />
              <Tab label="Disclaimer" {...a11yProps(3)} />
              <Tab label="Faq" {...a11yProps(4)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography className="custom_label">Email</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"email"}
                      name="email"
                      variant="outlined"
                      fullWidth
                      placeholder="Email"
                      id="email"
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 256 }}
                      helperText={formik.touched.email && formik.errors.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="custom_label">
                      Phone Number
                    </Typography>
                    <PhoneInput
                      enableSearch={true}
                      value={formik.values.countryCode + formik.values.phone}
                      country={"us"}
                      inputClass="phoneInput"
                      inputStyle={{ width: "100%" }}
                      buttonClass="phoneBtn"
                      placeholder="Phone Number"
                      onChange={(phone, country) =>
                        handleChangePhone(phone, country)
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <h6 className="err_msg">
                        {formik.touched.phone && formik.errors.phone}
                      </h6>
                    ) : (
                      ""
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className="custom_label">Address</Typography>
                    <TextField
                      className="text_field"
                      hiddenLabel
                      type={"text"}
                      name="address"
                      variant="outlined"
                      fullWidth
                      placeholder="Address"
                      id="address"
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      onChange={formik.handleChange}
                      inputProps={{ maxLength: 40 }}
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid>
                </Grid>
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <form onSubmit={formik.handleSubmit}>
                <EditText content={terms} setContent={setTerms} />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <form onSubmit={formik.handleSubmit}>
                <EditText
                  content={privacyPolicy}
                  setContent={setPrivacyPolicy}
                />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <form onSubmit={formik.handleSubmit}>
                <EditText content={disclaimer} setContent={setDisclaimer} />
                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </form>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <Faq />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageCms;
