import MainContainer from "../../layout/MainContainer";
import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../components/Pagination";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loader from "../../constants/Loader";
import {
  useLazyDeleteBlogByIdQuery,
  useLazyGetAllBlogQuery,
  useUpdateBlogStatusMutation,
} from "../../services/main";
import { useEffect, useState } from "react";
import { Blog, CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { isValidInput } from "../../utils/validation";
import SearchBar from "../../components/SearchBar";
import WarnModal from "../../components/modals/WarnModal";
import moment from "moment";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";

export default function BlogPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const user = useAuth();

  const [getBlog, { isLoading }] = useLazyGetAllBlogQuery();
  const [deleteById] = useLazyDeleteBlogByIdQuery();
  const [updateStatusMutation] = useUpdateBlogStatusMutation();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState<Blog[]>([]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllBlogs = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await getBlog({
        size: 12,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setBlogs(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllBlogs();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer name="Contact & Support">
      <Loader isLoad={isLoading || loading} />
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <h2>Manage Blog</h2>
          <div className="btn_group1">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <div className="add_btn">
              <button
                className="btn btn_primary"
                onClick={() => navigate("/manage-blog/add")}
              >
                <AddIcon />
              </button>
            </div>
          </div>
        </div>
        <TableContainer className="table_container">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Blog Title</TableCell>
                {/* <TableCell>Blog Description</TableCell> */}
                <TableCell>Blog Author</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {blogs?.length
                ? blogs?.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row?.title || ""}</TableCell>
                      {/* <TableCell>
                        <p className="short_disc"> {row?.description || ""} </p>
                      </TableCell> */}
                      <TableCell>{row?.author || ""}</TableCell>

                      <TableCell>
                        {row?.createdAt
                          ? moment(row?.createdAt).format("LL")
                          : ""}
                      </TableCell>
                      <TableCell>
                        <Switch
                          {...label}
                          size="small"
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(
                              row?._id,
                              !row?.isBlocked,
                              updateStatusMutation,
                              setBlogs,
                              blogs
                            )
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <ul className="action_btn">
                          {/* <li onClick={() => navigate("/manage-blog/add")}>
                        <button type="button">
                          <VisibilityIcon />
                        </button>
                      </li> */}
                          <li
                            onClick={() =>
                              navigate(`/manage-blog/edit/${row?._id}`)
                            }
                          >
                            <button type="button">
                              <EditIcon />
                            </button>
                          </li>
                          <li>
                            <button
                              type="button"
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </li>
                        </ul>
                      </TableCell>
                    </TableRow>
                  ))
                : !loading &&
                  !blogs?.length && (
                    <TableRow>
                      <TableCell align="center" colSpan={7}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          setPage={setPage}
          module={blogs}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          totalCount={totalCount}
        />
      </div>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name="blog"
        handleDelete={() => handleDelete(deleteById, selectedId, getAllBlogs)}
      />
    </MainContainer>
  );
}
