import { useParams } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import EmployeeViewContent from "./features/employeeViewContent";

const EmployeeView = () => {
  const { id } = useParams();
  return (
    <MainContainer>
      <div className="main_loyout rpt_card">
        <EmployeeViewContent id={id} />
      </div>
    </MainContainer>
  );
};

export default EmployeeView;
