import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyGetSupportByIdQuery } from "../../services/common";
import { Support } from "../../types/General";
import { errorToast } from "../../helpers";

function Viewquery() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [query, setQuery] = useState<Support | null>(null);
  const [getQuertyByIdMutation] = useLazyGetSupportByIdQuery();

  const getQueryByById = async (id: string) => {
    try {
      const response = await getQuertyByIdMutation({ id, type: 1 }).unwrap();
      if (response?.statusCode === 200) {
        setQuery(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getQueryByById(id);
    }
  }, []);
  return (
    <MainContainer name="Contact & Support">
      <div className="rpt_card mng_role_wrp">
        <div className="sec_head">
          <h2>View Revert</h2>
          <div className="bck_btn">
            <button
              className="btn btn_primary"
              onClick={() => navigate(`/help-support`)}
            >
              Back
            </button>
          </div>
        </div>
        <div className="form_info">
          <ul className="gap_p">
            <li>
              <p>User Name</p>
              <h6>{query?.name || ""}</h6>
            </li>

            <li>
              <p>Mobile Number</p>
              <h6>
                {query?.countryCode
                  ? (query?.countryCode.includes("+") ? "" : "+") +
                    query?.countryCode
                  : null}{" "}
                {query?.phone || ""}
              </h6>
            </li>
            <li>
              <p>User Email</p>
              <h6> {query?.email || ""}</h6>
            </li>
            <li>
              <p>Company Name</p>
              <h6> {query?.companyName || ""}</h6>
            </li>
            <li>
              <p>User Query</p>
              <h6> {query?.query || ""}</h6>
            </li>
            <li />
            <li>
              <p>Revert On Query</p>
              <h6>{query?.reply || ""}</h6>
            </li>
          </ul>
        </div>
      </div>
    </MainContainer>
  );
}

export default Viewquery;
