import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLazyGetB2BEmployeeByIdQuery,
  useLazyGetCouponByB2bIdQuery,
  useLazyGetOrganisationByIdQuery,
} from "../../../services/organisation";
import { Typography, Select, MenuItem, SelectChangeEvent } from "@mui/material";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../../helpers";
import { Employee, User } from "../../../types/User";
import moment from "moment";
import { Document, Page, pdfjs } from "react-pdf";
import Loader from "../../../constants/Loader";
import { Coupon } from "../../../types/General";
import CouponCard from "../../managecoupons/feature/CouponCard";
import { setCredentials } from "../../../reducers/authSlice";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../hooks/store";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type props = {
  id: string | undefined;
};
const OrganizationViewContent = ({ id }: props) => {
  const location = useLocation();
  const { state } = location;
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [organisationById, { isLoading }] = useLazyGetOrganisationByIdQuery();
  const [b2bEmployeeById, b2bEmployeeByIdData] =
    useLazyGetB2BEmployeeByIdQuery();

  const [couponByB2bId, couponByB2bIdData] = useLazyGetCouponByB2bIdQuery();

  const [value, setValue] = useState(state?.key === "byCoupon" ? 2 : 0);
  const [organisation, setOrganisation] = useState<User | null>(null);
  const [b2bEmployee, setB2bEmployee] = useState<Employee[]>([]);
  const [coupons, setCoupons] = useState<Coupon[]>([]);

  const [items, setItems] = useState<any>([]);
  //pdf
  const [selectedDocs, setSelectedDocs] = useState<any[]>([]);
  const [imageRes, setImageRes] = useState<string>();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const getOrganisationById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const response = await organisationById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setOrganisation(response?.data);
        setItems(response?.data?.address || []);
        setSelectedDocs(response?.data?.docs || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getb2bEmployeeById = async (id: string) => {
    try {
      const response = await b2bEmployeeById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setB2bEmployee(response?.data?.data || []);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const getCouponByB2bId = async (id: string) => {
    try {
      const response = await couponByB2bId({
        id,
        filter: String(filterType),
      }).unwrap();
      if (response?.statusCode === 200) {
        setCoupons(response?.data?.data || []);
        // setTotalCount(res?.data?.count);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  const [filterType, setfilterType] = useState("");
  const handleChangeSelect2 = (event: SelectChangeEvent) => {
    setfilterType(event.target.value as string);
  };
  useEffect(() => {
    if (id) {
      if (id !== "add" && value === 0) {
        getOrganisationById(id);
      } else if (value === 1) {
        getb2bEmployeeById(id);
      } else {
        getCouponByB2bId(id);
      }
    }
  }, [value, filterType]);

  return (
    <>
      <Loader
        isLoad={
          isLoading ||
          b2bEmployeeByIdData?.isLoading ||
          couponByB2bIdData?.isLoading
        }
      />
      <div className="custom_tabs org_detail">
        <div className="bck_btn pos_back ">
          <button
            className="btn btn_sm btn_primary"
            onClick={() => navigate("/manage-organization")}
          >
            Back
          </button>
        </div>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
          className="custom_tabs_links"
        >
          <Tab label="Organization Detail" {...a11yProps(0)} />
          <Tab label="Employee Detail" {...a11yProps(1)} />
          <Tab label="Coupons" {...a11yProps(2)} />
        </Tabs>
        <h2 className="ctitle">
          Company: <span>{organisation?.companyName || ""}</span>
        </h2>
        <CustomTabPanel value={value} index={0}>
          <div className="form_title fs_16">
            <h2>
              General Info
              <figure
                className="pointer"
                onClick={() => navigate(`/manage-organization/edit/${id}`)}
              >
                <img src="/images/edit_icon.svg" alt="" />
              </figure>
            </h2>
          </div>
          <div className="form_info">
            <ul className="gap_p">
              <li className="w_100">
                <p>Logo </p>
                <figure>
                  <img
                    src={organisation?.image || "/images/userDummy.jpeg"}
                    alt="user"
                  />
                </figure>
              </li>

              <li>
                <p>Name of the company</p>
                <h6>{organisation?.companyName || ""}</h6>
              </li>
              <li>
                <p>Company website domain</p>
                <h6>{organisation?.companyWebSite || ""}</h6>
              </li>
              <li>
                <p>Business Email address</p>
                <h6>{organisation?.email || ""}</h6>
              </li>

              <li>
                <p>Phone number</p>

                <h6>
                  {organisation?.countryCode
                    ? (organisation?.countryCode.includes("+") ? "" : "+") +
                      organisation?.countryCode
                    : null}{" "}
                  {organisation?.phone || ""}
                </h6>
              </li>
              <li>
                <p>Organization Type</p>
                <h6>
                  {organisation?.b2bType === 1
                    ? "Vendor"
                    : organisation?.b2bType === 2
                    ? "Partner"
                    : "Community"}
                </h6>
              </li>
              <li>
                <p>Start Date</p>
                <h6>{moment(organisation?.startDate).format("LL")}</h6>
              </li>

              {organisation?.endDate ? (
                <li>
                  <p>End Date</p>
                  <h6>
                    {organisation?.endDate
                      ? moment(organisation?.endDate).format("LL")
                      : ""}
                  </h6>
                </li>
              ) : null}

              <li>
                <p>Plan Type</p>
                <h6>
                  {organisation?.planType === "Halfly"
                    ? "Half Yearly"
                    : organisation?.planType}
                </h6>
              </li>
              <li />
            </ul>

            <p className="lable mt_20"> Address</p>
            {items?.length ? (
              <div className="box">
                <ul>
                  {items?.map((item: any, i: any) => (
                    <li key={i}>
                      <h4 style={{ lineBreak: "anywhere" }}>
                        {item?.address || ""}
                      </h4>
                      {item?.phone ? (
                        <p>
                          {item?.countryCode} {item?.phone}
                        </p>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            <p className="lable mt_20"> Document</p>
            <div className="gap_m pdf_divv_wrp">
              {selectedDocs?.length
                ? selectedDocs?.map((pdf, index) => (
                    <div
                      key={index}
                      className="pdf_divv "
                      style={{ position: "relative" }}
                    >
                      <figure>
                        <a
                          href={pdf.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <Document file={pdf}>
                            <Page pageNumber={1} />
                          </Document>
                        </a>
                      </figure>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="form_title fs_16">
            <h2>Employees List</h2>
          </div>
          <ul className="emp_lst gap_m">
            {b2bEmployee?.length ? (
              b2bEmployee?.map((item) => (
                <li key={item?._id}>
                  <figure>
                    <img
                      src={item?.image || "/images/userDummy.jpeg"}
                      alt="user"
                    />
                  </figure>
                  <div className="usr_cnt">
                    <h3>{item?.firstName || "-"}</h3>
                    <p>{item?.email || "-"}</p>
                    <button
                      onClick={() =>
                        navigate(`/manage-employee/view/${item?._id}/0`, {
                          state: { id, tab: "b2b" },
                        })
                      }
                      type="button"
                      className="lst_btn"
                    >
                      View Details
                    </button>
                  </div>
                </li>
              ))
            ) : (
              <div className="no_date_sc hd_4">
                <figure>
                  <img src="/images/noData.png" alt="noData" />
                </figure>
                <h2>No Data Found</h2>
              </div>
            )}
          </ul>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <div className="form_title fs_16">
            <h2>Coupons</h2>
            <div className="btn_group">
              <div className="form_control form_control_bg d_flex">
                <label>Filter by:</label>
                <Select
                  fullWidth
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  label="plan-type"
                  value={filterType}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  onChange={handleChangeSelect2}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value={"1"}>Redeemed</MenuItem>
                  <MenuItem value={"2"}>Reserved</MenuItem>
                  <MenuItem value={"3"}>Available</MenuItem>
                </Select>
              </div>
            </div>
          </div>
          <div className="coupon_flx gap_m">
            {coupons?.length ? (
              coupons?.map((item) => (
                <CouponCard item={item} couponTab={3} tabKey={id} />
              ))
            ) : (
              <p className="text_center">No Data Found</p>
            )}
          </div>
        </CustomTabPanel>
      </div>
    </>
  );
};

export default OrganizationViewContent;
