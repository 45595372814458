
import MainContainer from "../../layout/MainContainer";
import OrgDetail from "./feature/orgdetail";

const OraganizationDetail = () => {
  return (
    <MainContainer>
      <div className="main_loyout rpt_card">
        <OrgDetail />
      </div>
    </MainContainer>
  );
};

export default OraganizationDetail;
