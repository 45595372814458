import React, { useEffect, useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useLazyGetEmployeeByIdQuery } from "../../../services/organisation";
import { Employee } from "../../../types/User";
import { errorToast } from "../../../helpers";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { Permissions } from "../../../types/General";
import useAuth from "../../../hooks/useAuth";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

type props = {
  id: string | undefined;
};

interface DataType {
  key?: string;
  label: string;
  isAdd: boolean;
  isView: boolean;
  isDelete?: boolean;
  disabled?: boolean;
}

const EmployeeViewContent = ({ id }: props) => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const { value } = useParams();
  const location = useLocation();
  const { state } = location;
  const user = useAuth();
  const navigate = useNavigate();
  const [employeeById] = useLazyGetEmployeeByIdQuery();

  const [employee, setEmployee] = useState<Employee | null>(null);
  const [permissions, setPermissions] = useState<DataType[]>([
    {
      key: "1",
      label: "Dashboard",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      key: "2",
      label: "Manage Employees",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },
    {
      key: "3",
      label: "Manage Coupon",
      isAdd: false,
      isView: false,
      isDelete: false,
      disabled: false,
    },

    // {
    //   key: "4",
    //   label: "Manage Notification",
    //   isAdd: false,
    //   isView: false,
    //   isDelete: false,
    //   disabled: false,
    // },
  ]);

  const getOrganisationById = async (id: string, value: number) => {
    try {
      const response = await employeeById({ id, value }).unwrap();
      if (response?.statusCode === 200) {
        setEmployee(response?.data);
        const modifiedArr2 = permissions.map((item) => {
          const foundItem = response?.data?.permission?.find(
            (i) => i?.label === item.label
          );
          if (foundItem) {
            return {
              ...item,
              isView: foundItem.isView,
              isAdd: foundItem.isAdd,
              isDelete: foundItem.isDelete,
            };
          }
          return item;
        });
        setPermissions(modifiedArr2);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add" && value) {
      getOrganisationById(id, Number(value));
    }
  }, []);

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const checkPermission = () => {
    const permission = user?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Employees"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (user && user?.permission?.length) {
      checkPermission();
    }
  }, [user]);

  return (
    <div className="custom_tabs org_detail">
      <div
        className="bck_btn "
        style={{ display: "flex", justifyContent: "flex-end" }}
      >
        <button
          className="btn btn_sm btn_primary"
          onClick={() =>
            navigate(
              state?.id
                ? `/manage-organization/view/${state?.id}`
                : `/manage-employee`
            )
          }
        >
          Back
        </button>
      </div>
      <div className="form_title fs_16">
        <h2>
          General Info
          {hidePermission?.isAdd || user?.role === 2 ? (
            <figure
              className="pointer"
              onClick={() => navigate(`/manage-employee/edit/${id}/${value}`)}
            >
              <img src="/images/edit_icon.svg" alt="" />
            </figure>
          ) : null}
        </h2>
      </div>
      <div className="form_info">
        <ul className="gap_p">
          <li className="w_100">
            <p>Profile Picture</p>
            <figure>
              <img
                src={employee?.image || "/images/userDummy.jpeg"}
                alt="user"
              />
            </figure>
          </li>
          <li>
            <p>First Name</p>
            <h6>{employee?.firstName || "-"}</h6>
          </li>
          <li>
            <p>Last Name</p>
            <h6>{employee?.lastName || "-"}</h6>
          </li>
          <li>
            <p>Email</p>
            <h6>{employee?.email || ""}</h6>
          </li>
        </ul>
      </div>
      <div className="form_title fs_16 mt_40">
        <h2>Roles & Permissions</h2>
      </div>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className="table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Module</TableCell>
              <TableCell>View</TableCell>
              <TableCell>Add/Edit</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {permissions?.length
              ? permissions?.map((item) => (
                  <TableRow key={item?.key}>
                    <TableCell>{item?.label}</TableCell>
                    <TableCell>
                      <Checkbox
                        {...label}
                        // onChange={() => onSelectChange(item, "isview")}
                        checked={item?.isView || false}
                      />
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        {...label}
                        // onChange={() => onSelectChange(item, "addEdit")}
                        checked={item?.isAdd || false}
                      />
                    </TableCell>

                    <TableCell>
                      <Checkbox
                        {...label}
                        // onChange={() => onSelectChange(item, "isdel")}
                        checked={item?.isDelete || false}
                      />
                    </TableCell>
                  </TableRow>
                ))
              : null}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
          className="custom_tabs_links"
        >
          <Tab label="Employee Detail" {...a11yProps(0)} />
          <Tab label="NFT's" {...a11yProps(1)} />
          <Tab label="Coupons" {...a11yProps(1)} />
        </Tabs>
        <CustomTabPanel value={value} index={0}>
        
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="form_title fs_16">
            <h2>NFT Details</h2>
          </div>
          <div className="nft_dtl mt_30 fs_16">
            <h3>Minted</h3>
          </div>
          <Swiper
            spaceBetween={20}
            slidesPerView={3.8}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]}
            loop={true}
            navigation={false}
            className="explore_swiper"
          >
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  E Bin <span>3 ETH $5,442.6</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft2.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Scrap Battery <span>3 ETH $5,44</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft3.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Scrap Battery <span>3 ETH $5,442.6</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft4.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Uni waste <span>3 ETH $5,44</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  E Bin <span>3 ETH $5,442.6</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft2.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Scrap Battery <span>3 ETH $5,44</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft3.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Scrap Battery <span>3 ETH $5,442.6</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft4.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Uni waste <span>3 ETH $5,44</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
          </Swiper>

          <div className="nft_dtl mt_30 fs_16">
            <h3>Passive</h3>
          </div>
          <Swiper
            spaceBetween={20}
            slidesPerView={3.8}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation]}
            loop={true}
            navigation={false}
            className="explore_swiper"
          >
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  E Bin <span>3 ETH $5,442.6</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft2.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Scrap Battery <span>3 ETH $5,44</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft3.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Scrap Battery <span>3 ETH $5,442.6</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft4.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Uni waste <span>3 ETH $5,44</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  E Bin <span>3 ETH $5,442.6</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft2.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Scrap Battery <span>3 ETH $5,44</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft3.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Scrap Battery <span>3 ETH $5,442.6</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="expore_box_single">
                <figure>
                  <img
                    src="/images/purchase_nft4.png"
                    alt="explore_attachment_1"
                  />
                </figure>
                <h4>
                  Uni waste <span>3 ETH $5,44</span>{" "}
                </h4>
                <p>By remnynt</p>
              </div>
            </SwiperSlide>
          </Swiper>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <div className="form_title fs_16">
            <h2>Coupons</h2>
          </div>
          <div className="coupon_flx gap_m">
            <div
              className="coupon_card"
              onClick={() => navigate("/manage-coupons/coupon-detail")}
            >
              <div className="coupon_card_dtl">
                <figure>
                  <img src="/images/bar_code.svg" alt="" />
                </figure>
                <div className="coupon_dtl fs_21 text_center">
                  <h2>
                    Special <span className="d_block"> Offer</span>
                  </h2>
                  <p>Valid upto 31st Dec 2023</p>
                </div>
                <div className="coupon_btn">
                  <button className="btn btn_primary">50%</button>
                </div>
              </div>
            </div>
            <div
              className="coupon_card"
              onClick={() => navigate("/manage-coupons/coupon-detail")}
            >
              <div className="coupon_card_dtl">
                <figure>
                  <img src="/images/bar_code.svg" alt="" />
                </figure>
                <div className="coupon_dtl fs_21 text_center">
                  <h2>
                    Special <span className="d_block"> Offer</span>
                  </h2>
                  <p>Valid upto 31st Dec 2023</p>
                </div>
                <div className="coupon_btn">
                  <button className="btn btn_primary">50%</button>
                </div>
              </div>
            </div>
            <div
              className="coupon_card"
              onClick={() => navigate("/manage-coupons/coupon-detail")}
            >
              <div className="coupon_card_dtl">
                <figure>
                  <img src="/images/bar_code.svg" alt="" />
                </figure>
                <div className="coupon_dtl fs_21 text_center">
                  <h2>
                    Special <span className="d_block"> Offer</span>
                  </h2>
                  <p>Valid upto 31st Dec 2023</p>
                </div>
                <div className="coupon_btn">
                  <button className="btn btn_primary">50%</button>
                </div>
              </div>
            </div>
            <div
              className="coupon_card"
              onClick={() => navigate("/manage-coupons/coupon-detail")}
            >
              <div className="coupon_card_dtl">
                <figure>
                  <img src="/images/bar_code.svg" alt="" />
                </figure>
                <div className="coupon_dtl fs_21 text_center">
                  <h2>
                    Special <span className="d_block"> Offer</span>
                  </h2>
                  <p>Valid upto 31st Dec 2023</p>
                </div>
                <div className="coupon_btn">
                  <button className="btn btn_primary">50%</button>
                </div>
              </div>
            </div>
            <div
              className="coupon_card"
              onClick={() => navigate("/manage-coupons/coupon-detail")}
            >
              <div className="coupon_card_dtl">
                <figure>
                  <img src="/images/bar_code.svg" alt="" />
                </figure>
                <div className="coupon_dtl fs_21 text_center">
                  <h2>
                    Special <span className="d_block"> Offer</span>
                  </h2>
                  <p>Valid upto 31st Dec 2023</p>
                </div>
                <div className="coupon_btn">
                  <button className="btn btn_primary">50%</button>
                </div>
              </div>
            </div>
          </div>
        </CustomTabPanel> */}
    </div>
  );
};

export default EmployeeViewContent;
