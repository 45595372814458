import { PropsWithoutRef, memo, useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItemText,
  ListItemButton,
  Box,
  ListItem,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { usePostLogoutMutation } from "../services/auth";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  removeFromStorage,
} from "../helpers";
import { metaMaskAddress, setCredentials } from "../reducers/authSlice";
import { useAppDispatch } from "../hooks/store";
import { LogoutModal } from "../components";
import Loader from "../constants/Loader";
import useAuth from "../hooks/useAuth";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

const Sidebar = ({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) => {
  const user = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [logoutMethod, { isLoading }] = usePostLogoutMutation();
  const user_role = getFromStorage(STORAGE_KEYS.USER_ROLE);

  const newss = getFromStorage(STORAGE_KEYS.credentials);

  const [openLogout, setOpenLogout] = useState<boolean>(false);

  const adminItems = [
    {
      label: "Dashboard",
      key: "1",
      selected: "/dashboard",
    },
    // {
    //   label: "Manage Users",
    //   key: "2",
    //   selected: "/manage-users",
    // },
    {
      label: "Manage Customers",
      key: "3",
      selected: "/manage-users",
    },
    {
      label: "Manage Organization",
      key: "15",
      selected: "/manage-organization",
    },
    // {
    //   label: "Manage Collection",
    //   key: "4",
    //   selected: "/manage-collection",
    // },
    {
      label: "Manage Coupon",
      key: "5",
      selected: "/manage-coupons",
    },
    {
      label: "Manage Category",
      key: "6",
      selected: "/manage-category",
    },
    {
      label: "Manage Admin Nft",
      key: "7",
      selected: "/manage-nft",
    },
    {
      label: "Manage Platform Nft",
      key: "20",
      selected: "/manage-platform-nft",
    },
    {
      label: "Manage Blog",
      key: "8",
      selected: "/manage-blog",
    },

    {
      label: "Manage News",
      key: "2",
      selected: "/manage-news",
    },
    {
      label: "Manage Testimonials",
      key: "4",
      selected: "/manage-testimonial",
    },
    {
      label: "Manage Banner",
      key: "9",
      selected: "/manage-banner",
    },
    {
      label: "Manage Collection Center",
      key: "16",
      selected: "/manage-collection-center",
    },
    {
      label: "Analytics",
      key: "10",
      selected: "/analytics",
    },

    // {
    //   label: "Manage Notification",
    //   key: "12",
    //   selected: "/manage-notification",
    // },
    {
      label: "Super Admin",
      key: "17",
      selected: "/superAdmin",
    },
    {
      label: "Newsletter",
      key: "18",
      selected: "/newsletter",
    },

    {
      label: "CMS",
      key: "13",
      selected: "/cms",
    },
    {
      label: "Help & Support",
      key: "14",
      selected: "/help-support",
    },
  ];

  const b2bItems = [
    {
      label: "Dashboard",
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Employees",
      key: "2",
      selected: "/manage-employee",
    },
    {
      label: "Manage Coupon",
      key: "3",
      selected: "/manage-coupons",
    },

    // {
    //   label: "Manage Notification",
    //   key: "4",
    //   selected: "/manage-notification",
    // },
  ];

  const [list, setList] = useState<any>(
    user?.role === 2 ? b2bItems : user?.permission
  );

  useEffect(() => {
    let arr = [];
    if (user?.role === 2) {
      setList(b2bItems);
    } else if (user?.permission?.length) {
      const permission = user?.permission;
      arr = b2bItems.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [user]);

  const handleLogout = async () => {
    try {
      const res = await logoutMethod({}).unwrap();
      navigate("/");
      removeFromStorage(STORAGE_KEYS.token);
      removeFromStorage(STORAGE_KEYS.tempToken);
      removeFromStorage(STORAGE_KEYS.USER_ROLE);
      removeFromStorage(STORAGE_KEYS.WalletAddress);
      dispatch(
        setCredentials({
          user: null,
          token: null,
        })
      );
      dispatch(
        metaMaskAddress({
          walletAddress: null,
        })
      );

      if (res?.statusCode === 200) {
        navigate("/");
        removeFromStorage(STORAGE_KEYS.token);
        removeFromStorage(STORAGE_KEYS.tempToken);
        removeFromStorage(STORAGE_KEYS.USER_ROLE);
        removeFromStorage(STORAGE_KEYS.WalletAddress);
        dispatch(
          setCredentials({
            user: null,
            token: null,
          })
        );
        dispatch(
          metaMaskAddress({
            walletAddress: null,
          })
        );
      }
    } catch (error: any) {
      // errorToast(error?.data?.message || "");
    }
  };

  const drawer = (
    <>
      <Loader isLoad={isLoading} />
      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItem className="main_prfl fs_16">
          <ListItemButton
            className="lst-itm"
            selected={location.pathname === "/view-profile"}
            onClick={() => navigate("/view-profile")}
          >
            <figure className="sideBarName">
              <img
                src={user?.image ? user?.image : "/images/userDummy.jpeg"}
                alt=""
              />
            </figure>
            <div className="usr_detl">
              <h2>
                {(user?.firstName || "User") + " " + (user?.lastName || "")}
              </h2>
              {/* {user?.role === 1 ? (
                <h2>
                  {(user?.firstName || "User") + " " + (user?.lastName || "")}
                </h2>
              ) : (
                <h2>{user?.companyName || ""}</h2>
              )} */}

              <p>{user?.email}</p>
            </div>
          </ListItemButton>
        </ListItem>
        {user_role === 1 && adminItems?.length
          ? adminItems?.map((item) => {
              const isSelected = location.pathname.includes(item?.selected);
              return (
                <ListItem key={item?.key}>
                  <ListItemButton
                    className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                    selected={location.pathname === item?.selected}
                    onClick={() => navigate(item?.selected)}
                  >
                    <ListItemText
                      className="lstitm-txt"
                      primary={item?.label}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })
          : user_role === 2 || (user_role === 3 && list?.length)
          ? list
              ?.filter((ele: any) => ele !== undefined)
              .map((item: any) => {
                const isSelected = location.pathname.includes(item?.selected);
                return (
                  <ListItem key={item?.key}>
                    <ListItemButton
                      className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                      selected={location.pathname === item?.selected}
                      onClick={() => {
                        if (!user?.isProfileComplete) {
                          errorToast(
                            "Please set up your profile to perform any action"
                          );
                        } else if (!user?.passwordChanged) {
                          errorToast("Change your password");
                        } else {
                          navigate(item?.selected);
                        }
                      }}
                    >
                      <ListItemText
                        className="lstitm-txt"
                        primary={item?.label}
                      />
                    </ListItemButton>
                  </ListItem>
                );
              })
          : null}
        <ListItem>
          <ListItemButton
            className="lst-itm"
            selected={location.pathname === "/login"}
            onClick={() => setOpenLogout(true)}
          >
            <ListItemText className="lstitm-txt" primary="Logout" />
          </ListItemButton>
        </ListItem>
      </List>
    </>
  );

  return (
    <Box component="nav" className="sidebar-main-div  open_sidebar">
      <LogoutModal
        openLogout={openLogout}
        setOpenLogout={setOpenLogout}
        handleLogout={handleLogout}
      />
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        // sx={{
        //   display: { xs: "block", lg: "none" },
        //   "& .MuiDrawer-paper": {
        //     boxSizing: "border-box",
        //     width: SIDEBAR_WIDTH,
        //   },
        // }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        // sx={{
        //   display: { xs: "none", lg: "block" },
        //   "& .MuiDrawer-paper": {
        //     width: SIDEBAR_WIDTH,
        //     boxSizing: "border-box",
        //   },
        // }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
};

export default memo(Sidebar);
