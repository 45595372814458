/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactNode, memo, useState } from "react";
import { Container, Box } from "@mui/material";
import Sidebar from "./SideBar";
import Topbar from "./TopBar";
import { SIDEBAR_WIDTH } from "../constants";
import "./Layout.scss";
import SearchBar from "../components/SearchBar";
import Footer from "./footer";

function MainContainer({
  children,
  hideHeader = false,
  name,
}: {
  children: ReactNode;
  hideHeader?: boolean;
  name?: string | undefined;
}) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleSidebarToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box>
      <Topbar handleSidebarToggle={handleSidebarToggle} />
      <div className="main_bx">
        <div className="conta_iner ps_rlt uht_spc">
          {/* <div className="srch_bar d_flx">
            {name ? <h1>{name}</h1> : <></>}
            <ul className="site_pagination ">
              <li>
                <a href="javascript:void(0)">Home</a>
              </li>
              <li>
                <a href="javascript:void(0)">Vendor</a>
              </li>
              <li>Vendor Detail</li>
            </ul>
          </div> */}

          <Box
            className="dashboard_main mn-lyout"
            component="main"
            sx={{
              width: { lg: `calc(100% - ${SIDEBAR_WIDTH}px)` },
            }}
          >
            <Container className="sub-layout">
              <Sidebar
                mobileOpen={mobileOpen}
                handleSidebarToggle={handleSidebarToggle}
              />
              {children}
            </Container>
          </Box>
        </div>
      </div>
      <Footer />
    </Box>
  );
}
export default memo(MainContainer);
