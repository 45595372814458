
import ForgotPasswordContent from "./features/forgotPassword";
import "../../auth/auth.scss";
import AuthLayout from "../../../layout/authLayout";

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <main>
        <ForgotPasswordContent />
      </main>
    </AuthLayout>
  );
};

export default ForgotPassword;
