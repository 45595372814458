import React from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import DeleteIcon from "@mui/icons-material/Delete";

const RecievedNotifications = () => {
  function createData(
    UserName: string,
    UserEmail: string,
    Title: string,
    Message: string,
    DateTime: string
  ) {
    return { UserName, UserEmail, Title, Message, DateTime };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Gurinder Singh",
      "gurinder@apptunix.com",
      "Title",
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit.",
      "04-10-2023 / 10:00AM"
    ),
  ];

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>Recieved Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">{/* <SearchBar /> */}</Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>User Name</TableCell>
                  <TableCell>User Email</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Date/Time</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>{row.UserName}</TableCell>
                    <TableCell>{row.UserEmail}</TableCell>
                    <TableCell>{row.Title}</TableCell>
                    <TableCell>{row.Message}</TableCell>
                    <TableCell>{row.DateTime}</TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default RecievedNotifications;
