import { useNavigate } from "react-router-dom";
import MainContainer from "../../layout/MainContainer";
import Pagination from "../../components/Pagination";
import {
  useLazyGetOrganisationCsvQuery,
  useLazyGetOrganisationQuery,
  useUpdateOrganisationStatusMutation,
} from "../../services/organisation";
import { useEffect, useState } from "react";
import Loader from "../../constants/Loader";
import { User } from "../../types/User";
import {
  API_URL,
  END_POINTS,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
} from "../../helpers";
import { isValidInput } from "../../utils/validation";
import SearchBar from "../../components/SearchBar";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";
import { MenuItem, Select, SelectChangeEvent, Switch } from "@mui/material";
import { updateStatus } from "../../utils/commonFunctions";

const OrganizationList = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getCsvMethod] = useLazyGetOrganisationCsvQuery();
  const [getOrganisation, { isLoading }] = useLazyGetOrganisationQuery();
  const [updateStatusMutation] = useUpdateOrganisationStatusMutation();

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filterType, setfilterType] = useState("");
  const [organisation, setOrganisation] = useState<User[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 12);

  const handleChangeSelect2 = (event: SelectChangeEvent) => {
    setfilterType(event.target.value as string);
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleExportCsv = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    let headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    };

    try {
      const res = await fetch(
        `${API_URL}${
          END_POINTS?.organisationCsv
        }?search=${debouncedSearchTerm.trim()}&filter=${String(filterType)}`,
        {
          method: "GET",
          headers,
        }
      );

      let response = await res.json();
      window.open(response?.data || "");
    } catch (error) {
      console.log(error, ">>>>>>>>>");
    }
  };

  const getAllOrganisation = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await getOrganisation({
        filter: String(filterType),
        size: 12,
        page: page,
        query: debouncedSearchTerm.trim(),
        pagination: true,
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setOrganisation(res?.data?.subAdmin || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllOrganisation();
  }, [debouncedSearchTerm, page, filterType]);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || loading} />
      <div className="main_loyout rpt_card">
        <div className="dashboard d_flx sec_head fs_22">
          <h2>Organization List</h2>

          <div className="btn_group">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <div className="form_control form_control_bg d_flex">
              <Select
                fullWidth
                labelId="demo-multiple-name-label"
                id="demo-multiple-name"
                label="plan-type"
                value={filterType}
                displayEmpty
                inputProps={{
                  "aria-label": "Without label",
                }}
                onChange={handleChangeSelect2}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value={1}>Vendor</MenuItem>
                <MenuItem value={2}>Partner</MenuItem>
                <MenuItem value={3}>Community</MenuItem>
              </Select>
            </div>
            <figure
              className="pls_icn"
              onClick={() => navigate("/manage-organization/add")}
            >
              <img src="/images/plus.svg" alt="" />
            </figure>
            <button
              className="btn btn_sm btn_primary"
              onClick={handleExportCsv}
            >
              Export CSV
            </button>
          </div>
        </div>
        <ul className="emp_lst userList  gap_m">
          {organisation?.length
            ? organisation?.map((item) => (
                <li className="active" key={item?._id}>
                  <figure>
                    <img
                      src={item?.image || "/images/userDummy.jpeg"}
                      alt="user"
                    />
                    <Switch
                      {...label}
                      size="small"
                      checked={!item?.isBlocked}
                      onChange={() =>
                        updateStatus(
                          item?._id,
                          !item?.isBlocked,
                          updateStatusMutation,
                          setOrganisation,
                          organisation
                        )
                      }
                    />
                  </figure>
                  <div className="usr_cnt">
                    <h3>{item?.companyName || ""}</h3>
                    <p>{item?.companyWebSite || ""}</p>
                    <div className="btn_group">
                      <button
                        onClick={() =>
                          navigate(`/manage-organization/view/${item?._id}`)
                        }
                        type="button"
                        className="lst_btn"
                      >
                        View Details
                      </button>
                    </div>
                  </div>
                </li>
              ))
            : !loading &&
              !organisation?.length && (
                <div className="no_date_sc hd_4">
                  <figure>
                    <img src="/images/noData.png" alt="noData" />
                  </figure>
                  <h2>No Data Found</h2>
                </div>
              )}
        </ul>
        <Pagination
          setPage={setPage}
          module={organisation}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          totalCount={totalCount}
        />
      </div>
    </MainContainer>
  );
};

export default OrganizationList;
