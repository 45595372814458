import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../hooks/store";
import { STORAGE_KEYS, errorToast, getFromStorage } from "../../../helpers";
import { setCredentials } from "../../../reducers/authSlice";
import { useLazyGetUserClaimedCouponByIdQuery } from "../../../services/main";
import { ClaimedResponse, Coupon } from "../../../types/General";
import MainContainer from "../../../layout/MainContainer";
import moment from "moment";
import { User } from "../../../types/User";

const ClaimedCoupon = () => {
  const { userId } = useParams();
  const { id } = useParams();
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [claimedCouponById] = useLazyGetUserClaimedCouponByIdQuery();

  const [open, setOpen] = useState(false);
  const [coupon, setCoupon] = useState<ClaimedResponse | null>(null);

  //   const handleDelete = async () => {
  //     if (id) {
  //       try {
  //         const response = await deleteById({ id }).unwrap();
  //         if (response?.statusCode === 200) {
  //           successToast("Deleted Successfully");
  //           navigate("/manage-coupons");
  //         }
  //       } catch (error: any) {
  //         errorToast(error?.data?.message || "");
  //       }
  //     }
  //   };

  const getCouponClaimedByById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    if (userId) {
      try {
        const response = await claimedCouponById({ userId, id }).unwrap();
        if (response?.statusCode === 200) {
          // console.log("response: cl", response);
          setCoupon(response?.data);
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getCouponClaimedByById(id);
    }
  }, []);

  return (
    <MainContainer name="Manage Employees">
      <div className="coupon coupon_dtl_page ">
        <div className="sec_head">
          <h2>Coupon Detail</h2>
          <div className="btn_group">
            <div
              className="btn btn_sm btn_primary"
              onClick={() =>
                navigate(`/manage-users/view/${userId}`, {
                  state: { key: "byClaimed" },
                })
              }
            >
              Back
            </div>
          </div>
        </div>
        {/* <div className="form_title fs_16">
          <h2>
            Coupon Info
            <figure
              className="pointer"
              onClick={() => navigate(`/manage-coupons/edit/${coupon?._id}`)}
            >
              <img src="/images/edit_icon.svg" alt="" />
            </figure>
            <IconButton className="pos_delete" onClick={() => setOpen(true)}>
              <DeleteIcon />
            </IconButton>
          </h2>
        </div> */}
        <div className="form_info">
          <ul className="gap_p">
            <li className="w_100">
              <p>Coupon Image</p>
              <div className="coupon_card">
                <div className="coupon_card_dtl">
                  <figure>
                    <span>Gift Voucher</span>
                    <img src="/images/bar_code.svg" alt="" />
                  </figure>
                  <div className="coupon_dtl fs_28 text_center">
                    <figure className="uploaded_coupon">
                      <img src={coupon?.couponId?.image || ""} alt="" />
                    </figure>
                  </div>
                  <div className="coupon_btn">
                    <button className="btn btn_primary">
                      {coupon?.couponId?.discountType === 1 ? "AED " : ""}
                      {coupon?.couponId?.effectiveValue || "0"}{" "}
                      {coupon?.couponId?.discountType === 2 ? "%" : ""}
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <p>Coupon Code</p>
              <h6>{coupon?.couponId?.code || ""}</h6>
            </li>
            <li>
              <p>Coupon Status</p>
              <h6>
                {coupon?.couponId?.status === 1
                  ? "Redeemed"
                  : coupon?.couponId?.status === 2
                  ? "Reserved"
                  : "Available"}
              </h6>
            </li>
            <li>
              <p>Value</p>
              <h6>
                {coupon?.couponId?.discountType === 1 ? "AED " : ""}
                {coupon?.couponId?.effectiveValue || "0"}{" "}
                {coupon?.couponId?.discountType === 2 ? "%" : ""}
              </h6>
            </li>
            <li>
              <p>Start Date</p>
              <h6>{moment(coupon?.couponId?.startDate).format("LL")}</h6>
            </li>
            <li>
              <p>End Date</p>
              <h6>
                {coupon?.couponId?.endDate
                  ? moment(coupon?.couponId?.endDate).format("LL")
                  : ""}
              </h6>
            </li>
            <li>
              <p>Coupon Type</p>
              <h6>
                {coupon?.couponId?.couponType === 1 ? "Direct" : "Indirect"}
              </h6>
            </li>
            {/* {user?.role === 1 ? (
              <li>
                <p>Assign To</p>
                {coupon?.couponId?.assignedTo?.length
                  ? coupon?.couponId?.assignedTo?.map((item: User, i) => (
                      <h6 key={i}>
                        {item?.companyName || ""}{" "}
                        <span>({item?.companyWebSite || ""})</span>
                      </h6>
                    ))
                  : null}
              </li>
            ) : null} */}

            <li className="w_100">
              <p>Short Note</p>
              <h6>{coupon?.couponId?.description || ""}</h6>
            </li>
          </ul>
        </div>
        {/* <div className="form_title fs_16 mt_40">
          <h2>NFT Detail</h2>
        </div>
        <div className="form_info">
          <ul className="gap_p">
            <li className="w_100">
              <p>NFT Image</p>
              <div className="prfl_pic w_30">
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <img src="/images/explore_attachment_3.jpg" alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <p>NFT Name</p>
              <h6>Nokia</h6>
            </li>
            <li>
              <p>Carban Credit</p>
              <h6>60</h6>
            </li>
            <li className="w_100">
              <p>Description</p>
              <h6>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum
                officia harum enim laborum consequuntur reprehenderit, quisquam
                excepturi cum veritatis reiciendis perspiciatis quasi veniam
                exercitationem? Voluptates soluta debitis sit quidem quod.
              </h6>
            </li>
            <li>
              <p>User Name</p>
              <h6>Kishore</h6>
            </li>
            <li>
              <p>User Contact Number</p>
              <h6>9877454492</h6>
            </li>
            <li>
              <p>User Email</p>
              <h6>kishore@apptunix.com</h6>
            </li>
          </ul>
        </div> */}
      </div>
      {/* <WarnModal
        setOpen={setOpen}
        open={open}
        name="coupon"
        handleDelete={handleDelete}
      /> */}
    </MainContainer>
  );
};

export default ClaimedCoupon;
