import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../../utils/ModalStyle";
import CloseIcon from "@mui/icons-material/Close";

type props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  handleDelete: () => void;
  name: string;
};

const WarnModal = ({ open, setOpen, handleDelete, name }: props) => {
  const style = generateResponsiveStyle();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div style={{ position: "relative" }}>
          {/* <div className="cross_icn_logout">
            <CloseIcon onClick={() => setOpen(false)} />
          </div> */}
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "19px" }}
          >
            Are you sure to delete this {name}?
          </Typography>
          <div className="flexDiv">
            <Button
              className="yesBtn"
              onClick={() => {
                setOpen(false);
                handleDelete();
              }}
            >
              Yes
            </Button>

            <Button
              sx={{
                color: "#56bb6d",
                border: "1px solid #56bb6d",
                textTransform: "capitalize",
              }}
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default WarnModal;
