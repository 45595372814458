import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../app/store";
import type { User } from "../types/User";
import { removeFromStorage, STORAGE_KEYS } from "../helpers";

export interface AuthState {
  user: User | null;
  token: any;
  tempToken: string | null;
  walletAddress: string | null;
  web3: boolean;
}

const initialState: AuthState = {
  user: null,
  token: null,
  tempToken: null,
  walletAddress: null,
  web3: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    resetAuth: (state) => {
      removeFromStorage(STORAGE_KEYS.token);
      state.user = null;
      state.token = null;
    },
    setCredentials: (
      state,
      action: PayloadAction<Pick<AuthState, "user" | "token">>
    ) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
    },
    temporaryToken: (
      state,
      action: PayloadAction<Pick<AuthState, "tempToken">>
    ) => {
      state.tempToken = action.payload.tempToken;
    },
    metaMaskAddress: (
      state,
      action: PayloadAction<Pick<AuthState, "walletAddress">>
    ) => {
      state.walletAddress = action.payload.walletAddress;
    },
    isWeb3Enabled: (state, action: PayloadAction<Pick<AuthState, "web3">>) => {
      state.web3 = action.payload.web3;
    },
  },
});

export const {
  resetAuth,
  setCredentials,
  temporaryToken,
  metaMaskAddress,
  isWeb3Enabled,
} = authSlice.actions;

export const getCurrentUser = (state: RootState) => state.auth.user;
export const getToken = (state: RootState) => state.auth.token;
export const getMetaMaskAmount = (state: RootState) => state.auth.walletAddress;
export const getWeb3Enabled = (state: RootState) => state.auth.web3;

export default authSlice.reducer;
