import { errorToast, successToast } from "../helpers";
import { CommonBody } from "../types/General";
import { generateEncryptedKeyBody } from "./crypto";

// export const handleImageUpload = async (
//   event: React.ChangeEvent<HTMLInputElement>
// ) => {
//   const files = event.target;
//   const file = files?.files?.length ? files?.files[0] : "";

//   // Check if a file is selected
//   if (file) {
//     // Check if the selected file is an image
//     if (file.type.startsWith("image/")) {
//       const res = await UploadMedia(file);
//       if (res?.statusCode === 200) {
//         formik.setFieldValue("image", res?.data);
//       } else {
//         errorToast(res?.message);
//       }
//     } else {
//       // Display an error message for non-image files
//       errorToast("Please Try Again");
//     }
//   }
// };

export const handleDelete = async (
  deleteById: any,
  selectedId: string,
  getAllData: () => void
) => {
  if (selectedId) {
    try {
      const response = await deleteById({ id: selectedId }).unwrap();
      if (response?.statusCode === 200) {
        successToast(`Deleted Successfully`);
        if (getAllData) {
          getAllData(); // Assuming getAllData is a function to fetch updated data
        }
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  }
};

export const updateStatus = async (
  id: string,
  status: boolean,
  updateStatusMutation: any,
  setData: any,
  data: any
) => {
  let body = {
    isBlocked: status,
  };

  let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
  const response = await updateStatusMutation({
    id,
    body: encryptedBody,
  }).unwrap();
  if (response?.statusCode === 200) {
    const updatedData = data.map((x: any) => {
      if (x._id === id) {
        return {
          ...x,
          isBlocked: status,
        };
      }
      return x;
    });
    setData(updatedData);
    successToast("Status updated successfully");
    // if (!status) {
    //   console.log("status: ", status);
    //   successToast("Enabled successfully");
    // } else {
    //   successToast("Disabled successfully");
    // }
  } else {
    errorToast("Please try again");
  }
};
