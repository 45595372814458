import MainContainer from "../../layout/MainContainer";
import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../components/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import AddIcon from "@mui/icons-material/Add";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useLazyDeleteCategoryByIdQuery,
  useLazyGetSubCategoryByIdQuery,
  useUpdateCategoryStatusMutation,
} from "../../services/main";
import { Category } from "../../types/General";
import { useEffect, useState } from "react";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import WarnModal from "../../components/modals/WarnModal";
import { updateStatus } from "../../utils/commonFunctions";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validation";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";

export default function ManageSubcategory() {
  const location = useLocation();
  const { state } = location;
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useAuth();
  const dispatch = useAppDispatch();

  const [getSubcategory] = useLazyGetSubCategoryByIdQuery();
  const [deleteById, deleteByIdData] = useLazyDeleteCategoryByIdQuery();
  const [updateStatusMutation] = useUpdateCategoryStatusMutation();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [categories, setCategories] = useState<Category[]>([]);

  let totalPages = Math.ceil(totalCount / 10);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleDelete = async () => {
    if (selectedId) {
      try {
        const response = await deleteById({ id: selectedId }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Deleted Successfully");
          if (id) {
            getAllSubCategory(id);
          }
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const getAllSubCategory = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );

    if (id) {
      try {
        const res = await getSubcategory({
          id,
          size: 10,
          page: page,
          query: debouncedSearchTerm.trim(),
          pagination: true,
          blockStatus: true,
        }).unwrap();
        if (res?.statusCode === 200) {
          setCategories(res?.data?.category || []);
          setTotalCount(res?.data?.count);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getAllSubCategory(id);
    }
  }, [debouncedSearchTerm, page]);
  return (
    <MainContainer name="Contact & Support">
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <div>
            <h2>Manage Sub-category</h2>
            <p>{state ? state : ""}</p>
          </div>
          <div className="btn_group1 back_btn">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <div className="add_btn">
              <button
                className="btn btn_primary"
                onClick={() =>
                  navigate(`/manage-category/add-subcategory/${id}`)
                }
              >
                <AddIcon />
              </button>
              <button
                className="btn btn_sm btn_primary "
                onClick={() => navigate(`/manage-category`)}
              >
                Back
              </button>
            </div>
          </div>
        </div>
        <TableContainer className="table_container">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Sub-category Name</TableCell>
                <TableCell>Sub-category Description</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories?.length ? (
                categories?.map((row, i) => (
                  <TableRow>
                    <TableCell align="center">
                      {(page - 1) * 10 + i + 1}
                    </TableCell>
                    <TableCell>{row?.name || ""}</TableCell>
                    <TableCell>
                      <p className="short_disc"> {row?.description || ""} </p>
                    </TableCell>
                    <TableCell>
                      <Switch
                        {...label}
                        size="small"
                        checked={!row?.isBlocked}
                        onChange={() =>
                          updateStatus(
                            row?._id,
                            !row?.isBlocked,
                            updateStatusMutation,
                            setCategories,
                            categories
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <ul className="action_btn">
                        {/* <li
                          onClick={() =>
                            navigate(
                              `/manage-category/edit-subcategory/${row?._id}`
                            )
                          }
                        >
                          <button type="button">
                            <VisibilityIcon />
                          </button>
                        </li> */}
                        <li
                          onClick={() =>
                            navigate(
                              `/manage-category/edit-subcategory/${id}/${row?._id}`
                            )
                          }
                        >
                          <button type="button">
                            <EditIcon />
                          </button>
                        </li>
                        {row?.nftCount ? null : (
                          <li>
                            <button
                              type="button"
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          </li>
                        )}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell align="center" colSpan={5}>
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          setPage={setPage}
          module={categories}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          totalCount={totalCount}
        />
      </div>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name="sub-category"
        handleDelete={handleDelete}
      />
    </MainContainer>
  );
}
