import React, { useEffect, useState } from "react";

import { STORAGE_KEYS, errorToast, getFromStorage } from "../../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { useAppDispatch } from "../../../hooks/store";
import { useLazyGetAdminNftByIdQuery } from "../../../services/main";
import { Nft } from "../../../types/General";
import { setCredentials } from "../../../reducers/authSlice";
import MainContainer from "../../../layout/MainContainer";
import Loader from "../../../constants/Loader";
import moment from "moment";

const CouponNft = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const user = useAuth();
  const dispatch = useAppDispatch();

  const [getNftByIdMutation, { isLoading }] = useLazyGetAdminNftByIdQuery();

  const [nftData, setNftData] = useState<Nft | null>(null);

  const getNftById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const response = await getNftByIdMutation({ id }).unwrap();
      if (response?.statusCode === 200) {
        setNftData(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getNftById(id);
    }
  }, []);
  return (
    <MainContainer name="Contact & Support">
      <Loader isLoad={isLoading} />
      <div className="manage_blog view_nft">
        <div className="sec_head fs_22">
          <h2>View NFT</h2>
          <div className="btn_group">
            <button
              className="btn btn_sm btn_primary"
              onClick={() => navigate(`/manage-coupons/coupon-detail/${id}`)}
            >
              Back
            </button>
          </div>
        </div>

        <div className="form_title fs_16">
          <h2>NFT Detail</h2>
        </div>
        <div className="form_info">
          <ul className="gap_p">
            <li className="w_100">
              <p>NFT Image</p>
              <div className="prfl_pic w_30">
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <img src={nftData?.image || ""} alt="" />
                    </figure>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <p>NFT Name</p>
              <h6>{nftData?.name || ""}</h6>
            </li>
            <li>
              <p>Carban Credit</p>
              <h6>{nftData?.carbonCredit || "0"}</h6>
            </li>
            <li>
              <p>Category Name</p>
              <h6>{nftData?.category?.name || ""}</h6>
            </li>
            <li>
              <p>I2E Point</p>
              <h6>{nftData?.basePrice || "0"}</h6>
            </li>
            <li>
              <p>Date</p>
              <h6> {moment(nftData?.createdAt).format("MMM Do YY")}</h6>
            </li>
            <li className="w_100">
              <p>Description</p>
              <h6>{nftData?.description || ""}</h6>
            </li>
          </ul>
        </div>

        {/* <div className="form_title fs_16 mt_40">
              <h2>User Detail</h2>
            </div>
            <div className="form_info">
              <ul className="gap_p">
                <li>
                  <p>Name</p>
                  <h6>Kishore</h6>
                </li>
                <li>
                  <p>Contact Number</p>
                  <h6>9877454492</h6>
                </li>
                <li>
                  <p>Email</p>
                  <h6>kishore@apptunix.com</h6>
                </li>
              </ul>
            </div> */}
      </div>
    </MainContainer>
  );
};

export default CouponNft;
