import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { EmployeeList } from "./features";
import { useLazyGetEmployeeQuery } from "../../services/organisation";
import Loader from "../../constants/Loader";
import { Employee } from "../../types/User";
import { useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const ManageEmployee = () => {
  return (
    <>
      <MainContainer name="Manage Employees">
        <EmployeeList />
      </MainContainer>
    </>
  );
};

export default ManageEmployee;
