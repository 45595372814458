import { useNavigate } from "react-router-dom";
import { IconButton, Switch } from "@mui/material";
import {
  useLazyDeleteUserByIdQuery,
  useLazyGetUsersQuery,
  useUpdateUserStatusMutation,
} from "../../../services/main";
import { useEffect, useState } from "react";
import { User } from "../../../types/User";
import {
  API_URL,
  END_POINTS,
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../../helpers";
import { CommonBody, WebUser } from "../../../types/General";
import SearchBar from "../../../components/SearchBar";
import { isValidInput } from "../../../utils/validation";
import Loader from "../../../constants/Loader";
import Pagination from "../../../components/Pagination";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import DeleteIcon from "@mui/icons-material/Delete";
import WarnModal from "../../../components/modals/WarnModal";
import { useAppDispatch } from "../../../hooks/store";
import { setCredentials } from "../../../reducers/authSlice";
import useAuth from "../../../hooks/useAuth";
import { updateStatus } from "../../../utils/commonFunctions";

const UserListing = () => {
  const user = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [getUser, { isLoading }] = useLazyGetUsersQuery();
  const [updateStatusMutation] = useUpdateUserStatusMutation();
  const [deleteById, deleteByIdData] = useLazyDeleteUserByIdQuery();

  const [page, setPage] = useState(1);
  const [selectedId, setSelectedId] = useState("");
  const [allUser, setAllUser] = useState<WebUser[]>([]);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const handleExportCsv = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    let headers = {
      Accept: "application/json",
      Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    };

    try {
      const res = await fetch(
        `${API_URL}${
          END_POINTS?.userExports
        }?search=${debouncedSearchTerm.trim()}`,
        {
          method: "GET",
          headers,
        }
      );

      let response = await res.json();
      window.open(response?.data || "");
    } catch (error) {
      console.log(error, ">>>>>>>>>");
    }
  };

  const handleDelete = async () => {
    if (selectedId) {
      try {
        const response = await deleteById({ id: selectedId }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Deleted Successfully");
          getAllUser();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const getAllUser = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);

    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );

    try {
      const res = await getUser({
        size: 12,
        page: page,
        query: debouncedSearchTerm.trim(),
        pagination: true,
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setAllUser(res?.data?.user || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllUser();
  }, [debouncedSearchTerm, page]);

  return (
    <>
      <Loader isLoad={loading || deleteByIdData?.isLoading} />
      <div className="dashboard d_flx sec_head fs_22">
        <h2>Manage Customers</h2>
        <div className="btn_group">
          <SearchBar
            searchTerm={searchTerm}
            setDebouncedSearchTerm={setDebouncedSearchTerm}
            value={searchTerm}
            onCross={() => setSearchTerm("")}
            onChange={(val: any) => {
              if (isValidInput(val.target.value)) {
                setSearchTerm(val.target.value);
              }
            }}
          />
          <button className="btn btn_sm btn_primary" onClick={handleExportCsv}>
            Export CSV
          </button>
        </div>
      </div>
      <ul className="emp_lst userList gap_m">
        {allUser?.length
          ? allUser?.map((item) => (
              <li className={item?.isOnline ? "active" : ""} key={item?._id}>
                <IconButton
                  onClick={() => {
                    setOpen(true);
                    setSelectedId(item?._id);
                  }}
                  className="pos_delete"
                  title="Delete"
                >
                  <DeleteIcon />
                </IconButton>
                <figure>
                  <img
                    src={item?.image || "/images/userDummy.jpeg"}
                    alt="user"
                  />
                  <div>
                    <Switch
                      {...label}
                      size="small"
                      checked={!item?.isBlocked}
                      onChange={() =>
                        updateStatus(
                          item?._id,
                          !item?.isBlocked,
                          updateStatusMutation,
                          setAllUser,
                          allUser
                        )
                      }
                    />
                  </div>
                </figure>
                <div className="usr_cnt">
                  <h3>{item?.name || ""}</h3>
                  <p>{item?.email || ""}</p>
                  <button
                    onClick={() => navigate(`view/${item?._id}`)}
                    type="button"
                    className="lst_btn"
                  >
                    View Details
                  </button>
                </div>
              </li>
            ))
          : !loading &&
            !allUser?.length && (
              <div className="no_date_sc hd_4">
                <figure>
                  <img src="/images/noData.png" alt="noData" />
                </figure>
                <h2>No Data Found</h2>
              </div>
            )}
      </ul>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name="user"
        handleDelete={handleDelete}
      />
      <Pagination
        setPage={setPage}
        module={allUser}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
        totalCount={totalCount}
      />
    </>
  );
};

export default UserListing;
