import React, { useEffect, useState } from "react";
import PeopleIcon from "@mui/icons-material/People";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ImageIcon from "@mui/icons-material/Image";
import useAuth from "../../../hooks/useAuth";
import DiscountIcon from "@mui/icons-material/Discount";
import PhotoIcon from "@mui/icons-material/Photo";
import {
  useAdminDashboardMutation,
  useB2bDashboardMutation,
} from "../../../services/organisation";
import { Dashboard } from "../../../types/General";
import { STORAGE_KEYS, getFromStorage } from "../../../helpers";
import { useAppDispatch } from "../../../hooks/store";
import { setCredentials } from "../../../reducers/authSlice";
import { useNavigate } from "react-router-dom";
export default function DashCard() {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [b2bDash] = useB2bDashboardMutation();
  const [adminDash] = useAdminDashboardMutation();

  const [dashboard, setDashboard] = useState<Dashboard | null>(null);

  const getDashboard = async () => {
    if (!user) {
      const token = getFromStorage(STORAGE_KEYS.token);
      dispatch(
        setCredentials({
          user: user,
          token: token,
        })
      );
    } else if (user?.role === 1) {
      try {
        const res = await adminDash({}).unwrap();
        if (res?.statusCode === 200) {
          setDashboard(res?.data);
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const res = await b2bDash({}).unwrap();
        if (res?.statusCode === 200) {
          setDashboard(res?.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDashboard();
  }, [user]);

  return (
    <>
      {user?.role === 2 || user?.role === 3 ? (
        <div className="dash_card" onClick={() => navigate("/manage-employee")}>
          <div className="dash_card_cnt fs_21">
            <p>Total Employees</p>
            <h2>{dashboard?.totalEmployees || 0}</h2>
          </div>
          <PeopleIcon />
        </div>
      ) : null}
      {user?.role === 2 || user?.role === 3 ? (
        <div className="dash_card" onClick={() => navigate("/manage-coupons")}>
          <div className="dash_card_cnt fs_21">
            <p>Total Coupons</p>
            <h2>{dashboard?.myCoupons || 0}</h2>
          </div>
          <DiscountIcon />
        </div>
      ) : null}
      {user?.role === 2 || user?.role === 3 ? (
        <div className="dash_card" onClick={() => navigate("/manage-coupons")}>
          <div className="dash_card_cnt fs_21">
            <p>Minted Coupons</p>
            <h2>{dashboard?.mintedCoupons || 0}</h2>
          </div>
          <PhotoIcon />
        </div>
      ) : null}

      {user?.role === 1 ? (
        <div className="dash_card" onClick={() => navigate("/manage-users")}>
          <div className="dash_card_cnt fs_21">
            <p>Total Customer</p>
            <h2>{dashboard?.totalUser || 0}</h2>
          </div>
          <PeopleIcon />
        </div>
      ) : null}
      {user?.role === 1 ? (
        <div className="dash_card">
          <div className="dash_card_cnt fs_21">
            <p>Active Customer</p>
            <h2>{dashboard?.activeUsers || 0}</h2>
          </div>
          <PeopleIcon />
        </div>
      ) : null}

      {user?.role === 1 ? (
        <div className="dash_card" onClick={() => navigate("/manage-nft")}>
          <div className="dash_card_cnt fs_21">
            <p>Total Minted</p>
            <h2>{dashboard?.totalMinted || 0}</h2>
          </div>
          <PhotoIcon />
        </div>
      ) : null}

      {user?.role === 1 ? (
        <div className="dash_card" onClick={() => navigate("/manage-coupons")}>
          <div className="dash_card_cnt fs_21">
            <p> Coupon Claimed</p>
            <h2>{dashboard?.couponClaimed || 0}</h2>
          </div>
          <DiscountIcon />
        </div>
      ) : null}
      {user?.role === 1 ? (
        <div
          className="dash_card"
          onClick={() => navigate("/manage-organization")}
        >
          <div className="dash_card_cnt fs_21">
            <p>Total Organisations</p>
            <h2>{dashboard?.newOrganisations || 0}</h2>
          </div>
          <AccountBalanceIcon />
        </div>
      ) : null}

      {user?.role === 1 ? (
        <div
          className="dash_card"
          onClick={() => navigate("/manage-platform-nft")}
        >
          <div className="dash_card_cnt fs_21">
            <p>Total Nft</p>
            <h2>{dashboard?.totalNft || 0}</h2>
          </div>
          <ImageIcon />
        </div>
      ) : null}
    </>
  );
}
