import React from "react";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
export default function AssignCoupon() {
  return (
    <>
      <div className="sec_head fs_21">
        <h2>Assigned To</h2>
        <button className="btn btn_primary">
          <AddIcon />
        </button>
      </div>

      <div className="gap_m assign_flx">
        <div className="assign_card active">
          <figure>
            <img src="/images/assign_usr.png" alt="" />
          </figure>
          <div className="usr_card">
            <h3>Abigail Smurt</h3>
            <p>Highspeed Inc.</p>
            <span className="tick">
              <CheckIcon />
            </span>
          </div>
        </div>
        <div className="assign_card active">
          <figure>
            <img src="/images/assign_usr.png" alt="" />
          </figure>
          <div className="usr_card">
            <h3>Abigail Smurt</h3>
            <p>Highspeed Inc.</p>
            <span className="tick">
              <CheckIcon />
            </span>
          </div>
        </div>
        <div className="assign_card active">
          <figure>
            <img src="/images/assign_usr.png" alt="" />
          </figure>
          <div className="usr_card">
            <h3>Abigail Smurt</h3>
            <p>Highspeed Inc.</p>
            <span className="tick">
              <CheckIcon />
            </span>
          </div>
        </div>
        <div className="assign_card active">
          <figure>
            <img src="/images/assign_usr.png" alt="" />
          </figure>
          <div className="usr_card">
            <h3>Abigail Smurt</h3>
            <p>Highspeed Inc.</p>
            <span className="tick">
              <CheckIcon />
            </span>
          </div>
        </div>
        <div className="assign_card active">
          <figure>
            <img src="/images/assign_usr.png" alt="" />
          </figure>
          <div className="usr_card">
            <h3>Abigail Smurt</h3>
            <p>Highspeed Inc.</p>
            <span className="tick">
              <CheckIcon />
            </span>
          </div>
        </div>
        <div className="assign_card active">
          <figure>
            <img src="/images/assign_usr.png" alt="" />
          </figure>
          <div className="usr_card">
            <h3>Abigail Smurt</h3>
            <p>Highspeed Inc.</p>
            <span className="tick">
              <CheckIcon />
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
