import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";

import QueryList from "./QueryList";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/store";
import { useLazyGetSupportQuery } from "../../services/common";
import useAuth from "../../hooks/useAuth";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { Support } from "../../types/General";
import Pagination from "../../components/Pagination";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function HelpSupport() {
  const [value, setValue] = useState(0);
  console.log("value: HelpSupport", value);

  const user = useAuth();
  const dispatch = useAppDispatch();
  const [getQuery, { isLoading }] = useLazyGetSupportQuery();

  const [page, setPage] = useState(1);
  const [queries, setQuaries] = useState<Support[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllQuery = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await getQuery({
        type: value,
      }).unwrap();
      if (res?.statusCode === 200) {
        setQuaries(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllQuery();
  }, [value]);

  const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const navigate = useNavigate();
  const toggleModal = (name: string) => () => {};
  return (
    <MainContainer name="Contact & Support">
      <>
        <div className="rpt_card mng_role_wrp">
          <div className="dashboard sec_head">
            <h1>Help & Support</h1>
          </div>
          <Box className="custom_tabs">
            <Tabs
              value={value}
              onChange={handleChange2}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Pending" {...a11yProps(0)} />
              <Tab label="Resolved" {...a11yProps(1)} />
            </Tabs>
            <CustomTabPanel value={value} index={0}>
              <QueryList
                value={value}
                queries={queries}
                page={page}
                getAllQuery={getAllQuery}
              />
              <Pagination
                setPage={setPage}
                module={queries}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                totalCount={totalCount}
              />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <QueryList
                value={value}
                queries={queries}
                page={page}
                getAllQuery={getAllQuery}
              />
              <Pagination
                setPage={setPage}
                module={queries}
                page={page}
                onPageChange={onPageChange}
                totalPages={totalPages}
                totalCount={totalCount}
              />
            </CustomTabPanel>
          </Box>
        </div>
      </>
    </MainContainer>
  );
}
