import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import AddIcon from "@mui/icons-material/Add";
import CouponCard from "./feature/CouponCard";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { Banner, Coupon, Permissions } from "../../types/General";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useLazyGetAllCouponQuery } from "../../services/main";
import Loader from "../../constants/Loader";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import Pagination from "../../components/Pagination";
import { isValidInput } from "../../utils/validation";
import SearchBar from "../../components/SearchBar";

const ManageCoupons = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [getCoupon, { isLoading }] = useLazyGetAllCouponQuery();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [coupons, setCoupons] = useState<Coupon[]>([]);
  const [loading, setLoading] = useState(false);

  const [filterType, setfilterType] = useState("");
  const handleChangeSelect2 = (event: SelectChangeEvent) => {
    setfilterType(event.target.value as string);
  };

  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const checkPermission = () => {
    const permission = user?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Coupon"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (user && user?.permission?.length) {
      checkPermission();
    }
  }, [user]);

  const getAllCoupon = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await getCoupon({
        filter: String(filterType),
        page: page,
        size: 12,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setCoupons(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCoupon();
  }, [filterType, page, debouncedSearchTerm]);

  return (
    <>
      <MainContainer name="Manage Employees">
        <Loader isLoad={isLoading || loading} />
        <div className="coupon">
          <div className="sec_head">
            <h2>Coupons</h2>
            <div className="btn_group">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <div className="form_control form_control_bg d_flex">
                <label>Filter by:</label>
                <Select
                  fullWidth
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  label="plan-type"
                  value={filterType}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  onChange={handleChangeSelect2}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value={"1"}>Redeemed</MenuItem>
                  <MenuItem value={"2"}>Reserved</MenuItem>
                  <MenuItem value={"3"}>Available</MenuItem>
                </Select>
              </div>

              {/* <div className="form_control form_control_bg d_flex">
                <label>Filter by:</label>
                <Select
                  fullWidth
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  label="plan-type"
                  value={filterType}
                  displayEmpty
                  inputProps={{
                    "aria-label": "Without label",
                  }}
                  onChange={handleChangeSelect2}
                >
                  <MenuItem value="">All</MenuItem>
                  <MenuItem value={"1"}>Redeemed</MenuItem>
                  <MenuItem value={"2"}>Reserved</MenuItem>
                  <MenuItem value={"3"}>Available</MenuItem>
                </Select>
              </div> */}

              {hidePermission?.isAdd || user?.role === 2 || user?.role === 1 ? (
                <button
                  className="btn btn_primary"
                  onClick={() => navigate("add")}
                >
                  <AddIcon />
                </button>
              ) : null}
            </div>
          </div>
          <div className="coupon_flx gap_m">
            {coupons?.length
              ? coupons?.map((item) => <CouponCard item={item} />)
              : !loading &&
                !coupons?.length && (
                  // <p className="text_center">No Data Found</p>
                  <div className="no_date_sc hd_4">
                    <figure>
                      <img src="/images/noData.png" alt="noData" />
                    </figure>
                    <h2>No Data Found</h2>
                  </div>
                )}
          </div>
          <Pagination
            setPage={setPage}
            module={coupons}
            page={page}
            onPageChange={onPageChange}
            totalPages={totalPages}
            totalCount={totalCount}
          />
        </div>
      </MainContainer>
    </>
  );
};

export default ManageCoupons;
