import { CardMedia, Input, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { Blog, CommonBody } from "../../types/General";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import {
  useAddBlogMutation,
  useLazyGetBlogByIdQuery,
  useUpdateBlogMutation,
} from "../../services/main";
import { UploadMedia } from "../../utils/mediaUpload";
import Loader from "../../constants/Loader";
import { isString } from "../../utils/validation";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import { useAppDispatch } from "../../hooks/store";
import {
  useAddNewsMutation,
  useLazyGetNewsByIdQuery,
  useUpdateNewsMutation,
} from "../../services/common";
import EditText from "../../components/EditText";

export default function AddNews() {
  const user = useAuth();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [blog, setBlog] = useState<Blog | null>(null);
  const [error, setError] = useState<boolean>(false);

  const [type, setType] = useState<string>("edit");
  const [descrip, setDescrip] = useState<string>("");

  const [addBlog, { isLoading }] = useAddNewsMutation();
  const [updateBlog, updateBlogData] = useUpdateNewsMutation();
  const [blogById] = useLazyGetNewsByIdQuery();

  //for image upload
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("blogImage", res?.data);
        } else {
          errorToast(res?.message);
        }
      } else {
        // Display an error message for non-image files
        errorToast("Please Try Again");
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: blog?.title || "",
      description: blog?.description || "",
      author: blog?.author || "",
      blogImage: blog?.image || "",
    },
    validationSchema: Yup.object({
      blogImage: Yup.string().required("Image name is required"),
      title: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
      description: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .test("not-empty-p-tags", "Invalid content", (value) => {
          // Check if the value contains only <p></p>
          return !/^\s*<p>\s*<\/p>\s*$/.test(value);
        }),

      author: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        title: formik.values.title,
        author: formik.values.author,
        description: formik.values.description,
        image: formik.values.blogImage,
      };

      console.log(body, "body");

      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateBlog({
            body: encryptedBody,
            id,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("News Updated Successfully");
            formik.resetForm();
            navigate("/manage-news");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await addBlog(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("News Added Successfully");
            formik.resetForm();
            navigate("/manage-news");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });
  console.log("error: ", error, formik.values.description);
  const getNewsByById = async (id: string) => {
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const response = await blogById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setBlog(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getNewsByById(id);
    } else {
      setType("add");
    }
  }, []);

  return (
    <MainContainer name="Contact & Support">
      <Loader isLoad={updateBlogData?.isLoading || isLoading} />
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <h2>{id ? "Edit" : "Add"} News</h2>
          <div className="btn_group back_btn">
            <button
              className="btn btn_sm btn_primary "
              onClick={() => navigate("/manage-news")}
            >
              Back
            </button>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="form_group gap_m">
            <div className="prfl_pic form_control form_control_bg  w_100 ">
              <label>Image (dimensions 498×540)</label>
              {formik.values.blogImage ? (
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <CardMedia
                        component="img"
                        image={formik.values.blogImage}
                        alt="photo"
                      />
                    </figure>
                    <CloseIcon
                      onClick={() => {
                        formik.setFieldValue("blogImage", "");
                      }}
                    />
                  </div>
                </div>
              ) : (
                <label className="upload_image" htmlFor="icon-button-file">
                  <Input
                    sx={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    inputProps={{
                      accept: "image/png,image/jpeg",
                    }}
                    onChange={handleImageUpload}
                  />
                  <span className="upload_image_holder">
                    <figure>
                      <img src="/images/placeholder_gallery.jpeg" alt="" />
                    </figure>
                    <AddIcon />
                  </span>
                  {!formik.values.blogImage && error ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </label>
              )}
            </div>

            <div className="form_control form_control_bg  w_50">
              <label> Title </label>
              <TextField
                hiddenLabel
                placeholder="Title"
                fullWidth
                type="text"
                variant="outlined"
                className="text_field"
                name="title"
                id="title"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                helperText={formik.touched.title && formik.errors.title}
              />
            </div>

            <div className="form_control form_control_bg w_50">
              <label> Author</label>
              <TextField
                hiddenLabel
                placeholder="Author"
                fullWidth
                type="text"
                variant="outlined"
                className="text_field"
                name="author"
                id="author"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.author}
                helperText={formik.touched.author && formik.errors.author}
              />
            </div>
            <div className="form_control form_control_bg">
              <label> Description</label>

              {/* <TextField
                multiline
                maxRows={4}
                minRows={4}
                hiddenLabel
                placeholder="Description goes here"
                fullWidth
                type="text"
                variant="outlined"
                className="text_field"
                name="description"
                id="description"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                helperText={
                  formik.touched.description && formik.errors.description
                }
              /> */}

              {type === "edit" && formik.values.description ? (
                <EditText
                  content={formik.values.description}
                  setContent={(value) =>
                    formik.setFieldValue("description", value)
                  }
                />
              ) : type === "add" ? (
                <EditText
                  content={formik.values.description}
                  setContent={(value) =>
                    formik.setFieldValue("description", value)
                  }
                />
              ) : null}
              {(!formik.values.description ||
                /^\s*<p>\s*<\/p>\s*$/.test(formik.values.description)) &&
              error ? (
                <h6 className="err_msg">This field is required</h6>
              ) : null}
            </div>
          </div>
          <div className="submit_btn">
            <button className="btn btn_primary" onClick={() => setError(true)}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </MainContainer>
  );
}
