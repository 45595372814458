import { CardMedia, Input, TextField } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { errorToast, successToast } from "../../helpers";
import { Banner, CommonBody } from "../../types/General";
import {
  useLazyGetBanneryByIdQuery,
  useUpdateBannerMutation,
} from "../../services/main";
import { useAddBannerMutation } from "../../services/main";
import { isString } from "../../utils/validation";
import { UploadMedia } from "../../utils/mediaUpload";
import Loader from "../../constants/Loader";

export default function AddBanner() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [bannerById] = useLazyGetBanneryByIdQuery();
  const [updateBanner, { isLoading }] = useUpdateBannerMutation();
  const [addBanner, addBannerData] = useAddBannerMutation();

  const [error, setError] = useState<boolean>(false);
  const [banner, setBanner] = useState<Banner | null>(null);

  //for image upload
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";

    // Check if a file is selected
    if (file) {
      // Check if the selected file is an image
      if (file.type.startsWith("image/")) {
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
          formik.setFieldValue("bannerImage", res?.data);
        } else {
          errorToast(res?.message);
        }
      } else {
        // Display an error message for non-image files
        errorToast("Please Try Again");
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: banner?.title || "",
      button1: banner?.button1 || "",
      button2: banner?.button2 || "",
      bannerImage: banner?.bannerImage || "",
    },
    validationSchema: Yup.object({
      bannerImage: Yup.string().required("Image name is required"),
      title: Yup.string()
        .required("Title is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
      button1: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(15, "Maximum 15 character are allowed"),
      button2: Yup.string()
        .required("This field is required")
        .min(2, "Minimum 2 characters are required")
        .max(15, "Maximum 15 character are allowed"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        title: formik.values.title,
        button1: formik.values.button1,
        button2: formik.values.button2,
        bannerImage: formik.values.bannerImage,
      };

      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateBanner({
            body: encryptedBody,
            id,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Banner Updated Successfully");
            formik.resetForm();
            navigate("/manage-banner");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await addBanner(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Banner Added Successfully");
            formik.resetForm();
            navigate("/manage-banner");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });

  const getBannerByById = async (id: string) => {
    try {
      const response = await bannerById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setBanner(response?.data);
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  useEffect(() => {
    if (id && id !== "add") {
      getBannerByById(id);
    }
  }, []);

  return (
    <MainContainer name="Manage Banner">
      <Loader isLoad={addBannerData?.isLoading || isLoading} />
      <div className="manage_blog manage_banner">
        <div className="sec_head fs_22">
          <h2>{id ? "Edit Banner" : "Add Banner"}</h2>
          <div className="btn_group">
            <button
              className="btn btn_sm btn_primary "
              onClick={() => navigate("/manage-banner")}
            >
              Back
            </button>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="gap_m">
            <div className="form_control form_control_bg w_100">
              <label>Banner Title</label>
              <TextField
                hiddenLabel
                placeholder="Enter banner title"
                fullWidth
                type="text"
                variant="outlined"
                className="text_field"
                name="title"
                id="title"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.title}
                helperText={formik.touched.title && formik.errors.title}
              />
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Button 1 Text</label>
              <TextField
                hiddenLabel
                placeholder="Enter button Text"
                fullWidth
                type="text"
                variant="outlined"
                className="text_field"
                name="button1"
                id="button1"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.button1}
                helperText={formik.touched.button1 && formik.errors.button1}
              />
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Button 2 Text</label>
              <TextField
                hiddenLabel
                placeholder="Enter button Text"
                fullWidth
                type="text"
                variant="outlined"
                className="text_field"
                name="button2"
                id="button2"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.button2}
                helperText={formik.touched.button2 && formik.errors.button2}
              />
            </div>
            <div className="prfl_pic form_control form_control_bg w_50">
              <label>Banner Image (dimensions 498×540)</label>
              {formik.values.bannerImage ? (
                <div className="upload_image full_img">
                  <div className="upload_image_holder">
                    <figure>
                      <CardMedia
                        component="img"
                        image={formik.values.bannerImage}
                        alt="photo"
                        style={{ objectFit: "cover" }}
                      />
                    </figure>
                    <CloseIcon
                      onClick={() => {
                        formik.setFieldValue("bannerImage", "");
                      }}
                    />
                  </div>
                </div>
              ) : (
                <label
                  className="upload_image full_img"
                  htmlFor="icon-button-file"
                >
                  <Input
                    sx={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    inputProps={{
                      accept: "image/png,image/jpeg",
                    }}
                    onChange={handleImageUpload}
                  />
                  <span className="upload_image_holder">
                    <figure>
                      <img
                        src="/images/placeholder_gallery.jpeg"
                        alt=""
                        style={{ objectFit: "cover" }}
                      />
                    </figure>
                    <AddIcon />
                  </span>
                  {!formik.values.bannerImage && error ? (
                    <h6 className="err_msg">This field is required</h6>
                  ) : (
                    ""
                  )}
                </label>
              )}
            </div>
          </div>
          <div className="submit_btn">
            <button className="btn btn_primary" onClick={() => setError(true)}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </MainContainer>
  );
}
