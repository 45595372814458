import { ReactNode, useState } from "react";
import { Box } from "@mui/material";
import Topbar from "./TopBar";
import "./Layout.scss";
import Footer from "./footer";

function AuthLayout({
  children,
}: {
  children: ReactNode;
  hideHeader?: boolean;
  name?: string | undefined;
}) {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleSidebarToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Box>
      <Topbar handleSidebarToggle={handleSidebarToggle} />
      {children}
      <Footer />
    </Box>
  );
}
export default AuthLayout;
