import Compressor from "compressorjs";
import { API_URL, END_POINTS, STORAGE_KEYS, getFromStorage } from "../helpers";

const media_url = `${API_URL}`;

interface UploadResponse {
  statusCode: number;
  data: string;
  message: string;
}

export const UploadMedia = (imageObject: any): Promise<UploadResponse> => {
  return new Promise(async (resolve, reject) => {
    new Compressor(imageObject, {
      quality: 0.6,
      success: async (compressedResult) => {
        const formData = new FormData();
        formData.append("image", compressedResult as any);
        // const encryptedFormData = generateEncryptedKeyBody(formData);
        let headers = {
          Accept: "application/json",
        };

        try {
          const res = await fetch(media_url + END_POINTS?.mediaUpload, {
            method: "POST",
            headers,
            body: formData,
          });
          let response = await res.json();
          resolve(response); // Resolve the Promise with the response
        } catch (error) {
          console.log(error, ">>>>>>>>>");
          reject(error); // Reject the Promise with the error
        }
      },
    });
  });
};

export const Uploadpdf = async (imageObject: any) => {
  console.log("imageObject, ", imageObject);

  const formData = new FormData();

  formData.append("image", imageObject as any);

  const getToken = await getFromStorage(STORAGE_KEYS.token);
  // const token = getToken ? JSON.parse(`${getToken}`) : null;
  let headers = {
    // "Content-Type": "multipart/form-data",
    Accept: "application/json",
    Authorization: "Bearer " + getToken,
  };
  try {
    const res = await fetch(media_url + END_POINTS.mediaUpload, {
      method: "POST",
      headers,
      body: formData,
    });
    let response = await res.json();
    console.log(response, "response");
    return response;
  } catch (error) {
    console.log(error, ">>>>>>>>>");
    return error;
  }
};

// export const UploadCsv = async (csvObject: any) => {
//   const formData = new FormData();

//   formData.append("file", csvObject as any);

//   const getToken = await getFromStorage(STORAGE_KEYS.token);
//   const token = getToken ? JSON.parse(`${getToken}`) : null;
//   let headers = {
//     // "Content-Type": "multipart/form-data",
//     Accept: "application/json",
//     Authorization: "Bearer " + token,
//   };
//   try {
//     const res = await fetch(API_URL + END_POINTS.bulkUploadProducts, {
//       method: "POST",
//       headers,
//       body: formData,
//     });
//     let response = await res.json();
//     return response;
//   } catch (error) {
//     console.log(error, ">>>>>>>>>");
//     return error;
//   }
// };
