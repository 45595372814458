import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CollectionCenterForm from "./features/collectionCenterForm";

export default function AddCollectionCenter() {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <MainContainer name="Contact & Support">
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <h2>{id ? "Edit" : "Add"} Collection Center</h2>
          <div className="add_btn">
            <button
              className="btn btn_sm btn_primary"
              onClick={() => navigate("/manage-collection-center")}
            >
              Back
            </button>
          </div>
        </div>
        <CollectionCenterForm />
      </div>
    </MainContainer>
  );
}
