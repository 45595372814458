
import MainContainer from "../../layout/MainContainer";
import { EmployeeLeft } from "./features";
import EmployeeDetail from "./features/EmployeeDetail";
import NftDetail from "./features/NftDetail";

const AddEmployee = () => {
  return (
    <MainContainer>
      <div className="main_loyout rpt_card">
        <EmployeeDetail />
      </div>
    </MainContainer>
  );
};

export default AddEmployee;
