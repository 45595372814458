import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="site_footer">
      <div className="conta_iner">
        <div className="copywrite_footer">
          <p>Copyright by BANTgo impact2earn. All Rights Reserved.</p>
          <p>impact2earn simplifies: recycle, earn, and enjoy</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
