import MainContainer from "../../layout/MainContainer";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../components/Pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import Loader from "../../constants/Loader";
import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";
import { useEffect, useState } from "react";
import { Collection, CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { setCredentials } from "../../reducers/authSlice";
import {
  useLazyDeleteCollectionByIdQuery,
  useLazyGetAllCollectionQuery,
  useUpdateCollectionStatusMutation,
} from "../../services/main";
import WarnModal from "../../components/modals/WarnModal";
import { handleDelete } from "../../utils/commonFunctions";

export default function ManageCollection() {
  const user = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [getCollectionMutation, { isLoading }] = useLazyGetAllCollectionQuery();
  const [deleteById] = useLazyDeleteCollectionByIdQuery();
  const [updateStatusMutation] = useUpdateCollectionStatusMutation();

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [collections, setCollections] = useState<Collection[]>([]);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const updateStatus = async (id: string, status: boolean) => {
    let body = {
      isBlocked: status,
    };
    console.log(body, "body???");

    let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
    const response = await updateStatusMutation({
      id,
      body: encryptedBody,
    }).unwrap();
    if (response?.statusCode === 200) {
      const updatedData = collections.map((x) => {
        if (x._id === id) {
          return {
            ...x,
            isBlocked: status,
          };
        }
        return x;
      });
      setCollections(updatedData);
      successToast("Status updated successfully");
    } else {
      errorToast("Please try again");
    }
  };

  const getCollections = async () => {
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );

    try {
      const res = await getCollectionMutation({
        size: 12,
        page: page,
        query: debouncedSearchTerm.trim(),
      }).unwrap();
      if (res?.statusCode === 200) {
        setCollections(res?.data?.data || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCollections();
    console.log("getCollections: ");
  }, [debouncedSearchTerm, page]);

  function createData(
    image: string,
    name: string,
    description: string,

    action: string
  ) {
    return { image, name, description, action };
  }
  const rows = [
    createData(
      "",
      "E Waste",
      "By remnynt",

      ""
    ),
    createData("", "E Waste", "By remnynt", ""),
    createData("", "E Waste", "By remnynt", ""),
    createData("", "E Waste", "By remnynt", ""),
  ];
  return (
    <MainContainer name="Contact & Support">
      <Loader isLoad={isLoading} />
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <h2>Manage Collection</h2>
          <div className="add_btn">
            <button
              className="btn btn_primary"
              onClick={() => navigate("/manage-collection/add")}
            >
              <AddIcon />
            </button>
          </div>
        </div>
        <TableContainer className="table_container">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>

                <TableCell>Collection Name</TableCell>
                <TableCell>Collection Description</TableCell>

                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    {" "}
                    <p className="short_disc"> {row.description} </p>
                  </TableCell>

                  <TableCell>
                    <ul className="action_btn">
                      <li onClick={() => navigate("/manage-collection/add")}>
                        <button type="button">
                          <VisibilityIcon />
                        </button>
                      </li>
                      <li onClick={() => navigate("/manage-collection/add")}>
                        <button type="button">
                          <EditIcon />
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          onClick={() => {
                            setOpen(true);
                            // setSelectedId(row?._id);
                          }}
                        >
                          <DeleteIcon />
                        </button>
                      </li>
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          setPage={setPage}
          module={collections}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          totalCount={totalCount}
        />
      </div>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name="collection"
        handleDelete={() =>
          handleDelete(deleteById, selectedId, getCollections)
        }
      />
    </MainContainer>
  );
}
