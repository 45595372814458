import {
  Box,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";
import Pagination from "../../../components/Pagination";

export default function RoleList() {
  function createData(
    EmployeeID: string,
    Name: string,
    Role: string,
    Permission: string
  ) {
    return { EmployeeID, Name, Role, Permission };
  }
  const rows = [
    createData("254672", "Abdul Kean", "gurinder@apptunix.com", "Meat"),
    createData("254672", "Abdul Kean", "gurinder@apptunix.com", "Meat"),
    createData("254672", "Abdul Kean", "gurinder@apptunix.com", "Meat"),
    createData("254672", "Abdul Kean", "gurinder@apptunix.com", "Meat"),
  ];
  const [socialAccount, setSocialAccount] = React.useState("default");
  const handleChange = (event: SelectChangeEvent) => {
    setSocialAccount(event.target.value as string);
  };

  const [socialAccount1, setSocialAccount1] = React.useState("default");
  const handleChange1 = (event: SelectChangeEvent) => {
    setSocialAccount1(event.target.value as string);
  };
  return (
    <>
      <div className="rpt_card mng_role_wrp p_0">
        <TableContainer className="table_container">
          <Box className="heading"></Box>
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                {/* <TableCell>Employee ID</TableCell> */}
                <TableCell>Name</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Permission</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, i) => (
                <TableRow>
                  <TableCell align="center">{i + 1}</TableCell>
                  <TableCell>{row.EmployeeID}</TableCell>
                  <TableCell>{row.Name}</TableCell>

                  <TableCell>
                    <div className="form_control form_control_bg">
                      <Select
                        fullWidth
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        label="social-account"
                        value={socialAccount}
                        onChange={handleChange}
                      >
                        <MenuItem disabled value="default">
                          Select
                        </MenuItem>
                        <MenuItem value={i + 1}>Marketing Manager</MenuItem>
                        <MenuItem value={i + 2}>Designer</MenuItem>
                        <MenuItem value={i + 3}>Sales Manager</MenuItem>
                      </Select>
                    </div>
                  </TableCell>

                  <TableCell>
                    <div className="form_control form_control_bg">
                      <Select
                        fullWidth
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        label="social-account"
                        value={socialAccount1}
                        onChange={handleChange1}
                      >
                        <MenuItem disabled value="default">
                          Select
                        </MenuItem>
                        <MenuItem value={10}>View </MenuItem>
                        <MenuItem value={20}>Edit </MenuItem>
                        <MenuItem value={30}>Delete</MenuItem>
                      </Select>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Pagination /> */}
      </div>
    </>
  );
}
