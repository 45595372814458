import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../../helpers";
import { CommonBody, FaqBody } from "../../../types/General";
import {
  useAddFaqMutation,
  useLazyDeleteFaqQuery,
  useLazyGetFaqQuery,
} from "../../../services/common";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { handleDelete } from "../../../utils/commonFunctions";

const Faq = () => {
  const [getFaqData, { isLoading }] = useLazyGetFaqQuery();
  const [postFaq, postFaqData] = useAddFaqMutation();
  const [deleteFaq] = useLazyDeleteFaqQuery();

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqs, setFaqs] = useState<FaqBody[]>([]);

  const handleAddFaq = async () => {
    if (!question || !answer) {
      return;
    }
    const body = {
      question: question,
      answer: answer,
    };

    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const res = await postFaq(encryptedBody).unwrap();
      if (res?.statusCode === 200) {
        getAllFaqsHandler();
        successToast("FAQ added successfully");
        setQuestion("");
        setAnswer("");
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const getAllFaqsHandler = async () => {
    try {
      const res = await getFaqData({}).unwrap();
      if (res?.statusCode === 200) {
        setFaqs(res?.data?.data || []);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllFaqsHandler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid container spacing={2}>
      {faqs?.map((item, index) => {
        return (
          <Grid key={index} item xs={12}>
            <Box className="faq_box">
              <FormControl
                sx={{ mb: 2, width: "100%" }}
                className="form_control"
              >
                <Typography className="custom_label">Question</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  value={item?.question}
                  placeholder="Question"
                ></TextField>
              </FormControl>
              <FormControl sx={{ width: "100%" }} className="form_control">
                <Typography className="custom_label">Answer</Typography>
                <TextField
                  hiddenLabel
                  type={"text"}
                  name="name"
                  variant="outlined"
                  value={item?.answer}
                  placeholder="Answer"
                ></TextField>
              </FormControl>
              <Button
                variant="contained"
                className="btn btn_danger sm"
                onClick={() =>
                  handleDelete(deleteFaq, item?._id, getAllFaqsHandler)
                }
              >
                Remove
              </Button>
            </Box>
          </Grid>
        );
      })}

      <Grid item xs={12}>
        <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: "100%" }} className="form_control">
            <Typography className="custom_label">Question</Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={question}
              onChange={(val) => setQuestion(val.target.value)}
              placeholder="Question"
            ></TextField>
          </FormControl>
          <FormControl sx={{ width: "100%" }} className="form_control">
            <Typography className="custom_label">Answer</Typography>
            <TextField
              hiddenLabel
              type={"text"}
              name="name"
              variant="outlined"
              value={answer}
              onChange={(val) => setAnswer(val.target.value)}
              placeholder="Answer"
            ></TextField>
          </FormControl>
          <Button
            variant="contained"
            className="btn btn_primary sm"
            onClick={handleAddFaq}
            disabled={!question || !answer}
          >
            Add
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Faq;
