import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { Button, IconButton, InputAdornment, TextField } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AuthCommon from "../../../../components/auth/AuthCommon";
import { useFormik } from "formik";
import * as Yup from "yup";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import { CommonBody } from "../../../../types/General";
import { useResetPasswordMutation } from "../../../../services/auth";
import Loader from "../../../../constants/Loader";
import { errorToast, successToast } from "../../../../helpers";

const ResetPasswordContent = () => {
  const navigate = useNavigate();
  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const [showPassword2, setShowPassword2] = useState(false);
  const handleClickShowPassword2 = () => {
    setShowPassword2(!showPassword2);
  };
  const handleMouseDownPassword2 = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as current password."
        ),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);

      let body = {
        password: formik.values.newPassword,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await resetPassword(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          successToast("Password reset successfully" || "");
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
      formik.setSubmitting(false);
      navigate("/");
    },
  });

  return (
    <>
      <Loader isLoad={isLoading} />
      <section className="auth_section">
        <div className="conta_iner">
          <div className="gap_p">
            <AuthCommon />
            <div className="right_side">
              <form onSubmit={formik.handleSubmit} className="form hd_3">
                <h2>Reset Password</h2>
                <div className="form_control">
                  <label>Password *</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Password"
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    id="newPassword"
                    name="newPassword"
                    className="text_field"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.newPassword}
                    helperText={
                      formik.touched.newPassword && formik.errors.newPassword
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form_control">
                  <label>Confirm Password *</label>
                  <TextField
                    hiddenLabel
                    placeholder="Enter Confirm Password"
                    fullWidth
                    type={showPassword2 ? "text" : "password"}
                    id="passwordConfirmation"
                    name="passwordConfirmation"
                    className="text_field"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.passwordConfirmation}
                    helperText={
                      formik.touched.passwordConfirmation &&
                      formik.errors.passwordConfirmation
                    }
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword2}
                            onMouseDown={handleMouseDownPassword2}
                            edge="end"
                          >
                            {showPassword2 ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form_btn">
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Submit
                  </Button>
                </div>
                <div className="form_bottom">
                  <p>
                    Back to{" "}
                    <a
                      href="javacript:void(0)"
                      onClick={() => navigate("/login")}
                    >
                     Sign In
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ResetPasswordContent;
