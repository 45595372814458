import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import Pagination from "../../components/Pagination";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import ReplyIcon from "@mui/icons-material/Reply";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import { useAppDispatch } from "../../hooks/store";
import { useLazyGetSupportQuery } from "../../services/common";
import { setCredentials } from "../../reducers/authSlice";
import useAuth from "../../hooks/useAuth";
import { CommonBody, Support } from "../../types/General";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useRevertQuertyMutation } from "../../services/common";
import Loader from "../../constants/Loader";

type props = {
  value: number;
  queries: Support[];
  page: number;
  getAllQuery: () => void;
};
export default function QueryList({
  value,
  queries,
  page,
  getAllQuery,
}: props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [revertText, setRevertText] = useState<string>("");
  const [error, setError] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const toggleModal = () => {
    setOpen(!open);
  };

  const [addRevert, { isLoading }] = useRevertQuertyMutation();

  const handleRevert = async (id: string) => {
    setError(true);
    if (!revertText) {
      return;
    }
    const body = {
      reply: revertText,
    };
    console.log(body, "body");

    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await addRevert({
        body: encryptedBody,
        id,
      }).unwrap();
      if (response?.statusCode === 200) {
        successToast("Revert Sent Successfully");
        toggleModal();
        getAllQuery();
      } else {
        errorToast(response?.message || "");
      }
    } catch (error: any) {
      errorToast(error?.data?.message || "");
    }
  };

  return (
    <>
      <Loader isLoad={isLoading} />
      <TableContainer className="table_container">
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          className="table"
        >
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell>User Name</TableCell>
              <TableCell>Mobile Number</TableCell>
              <TableCell>User Email</TableCell>
              <TableCell>Company</TableCell>
              <TableCell>User Query</TableCell>
              <TableCell>Date/Time</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {queries?.length ? (
              queries?.map((item, i) => (
                <TableRow key={item?._id}>
                  <TableCell align="center">
                    {(page - 1) * 10 + i + 1}
                  </TableCell>
                  <TableCell>{item?.name || ""}</TableCell>
                  <TableCell>
                    {item?.countryCode
                      ? (item?.countryCode.includes("+") ? "" : "+") +
                        item?.countryCode
                      : null}{" "}
                    {item?.phone || ""}
                  </TableCell>
                  <TableCell>{item?.email || ""}</TableCell>
                  <TableCell>{item?.companyName || ""}</TableCell>
                  <TableCell>
                    <Tooltip title={item?.query || "-"}>
                      <p className="short_disc"> {item?.query || "-"}</p>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {moment(item?.createdAt).format("MMM Do YY")}
                  </TableCell>
                  {value === 0 ? (
                    <TableCell>
                      <button
                        className="btn btn_primary reply_btn"
                        onClick={() => {
                          setSelectedId(item?._id);
                          toggleModal();
                        }}
                      >
                        <ReplyIcon />
                      </button>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <button
                        className="btn btn_primary reply_btn"
                        onClick={() =>
                          navigate(`/help-support/view/${item?._id}`)
                        }
                      >
                        <RemoveRedEyeIcon />
                      </button>
                    </TableCell>
                  )}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={7}>
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        open={open}
        keepMounted
        onClose={toggleModal}
        aria-describedby="alert-dialog-slide-description"
      >
        <div className="single fs_21 dialog revert_query">
          <h2>Revert Query</h2>
          <div className="form_control form_control_bg">
            <TextField
              className="text_field-text"
              style={{ padding: 0 }}
              hiddenLabel
              multiline
              minRows={4}
              maxRows={4}
              placeholder="Enter revert here"
              fullWidth
              type="text"
              variant="outlined"
              name="revertText"
              id="revertText"
              onChange={(val) => {
                if (val.target.value === " " || val.target.value === ".") {
                } else {
                  setRevertText(val.target.value);
                }
              }}
              value={revertText}
            />
            {!revertText && error ? (
              <h6 className="err_msg">This field is required</h6>
            ) : (
              ""
            )}
          </div>

          <div className="form_btn">
            <Button
              className="btn btn_primary"
              color="primary"
              size="large"
              type="submit"
              variant="contained"
              onClick={() => handleRevert(selectedId)}
            >
              Submit
            </Button>
          </div>
        </div>

        {/* <Button onClick={handleClose}>Disagree</Button> */}
      </Dialog>
    </>
  );
}
