import React, { ChangeEvent, useState } from "react";
import { CardMedia, Input, TextField } from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Collection, CommonBody } from "../../types/General";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import {
  useAddCollectionMutation,
  useUpdateCollectionMutation,
} from "../../services/main";
import { errorToast, successToast } from "../../helpers";

export default function AddCollection() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateCollection, { isLoading }] = useUpdateCollectionMutation();
  const [addCollection, addCollectionData] = useAddCollectionMutation();

  const [alertMessage, setAlertMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState(0);
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [collection, setCollection] = useState<Collection | null>(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      address: "",
      longitute: "",
      latitute: "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
      address: Yup.string()
        .required("Address is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 character are allowed"),
    }),
    onSubmit: async () => {
      formik.setSubmitting(true);
      let body = {
        name: formik.values.name,
        address: formik.values.address,
        longitute: formik.values.longitute,
        latitute: formik.values.latitute,
      };

      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateCollection({
            body: encryptedBody,
            id,
          }).unwrap();
          if (response?.statusCode === 200) {
            successToast("Collection Updated Successfully");
            formik.resetForm();
            navigate("/manage-collection-center");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;

          const response = await addCollection(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            successToast("Collection Added Successfully");
            formik.resetForm();
            navigate("/manage-collection-center");
          } else {
            errorToast(response?.message || "");
          }
        } catch (error: any) {
          errorToast(error?.data?.message || "");
        }
      }
    },
  });

  return (
    <MainContainer name="Contact & Support">
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <h2>Add Collection</h2>
          <div className="btn_group back_btn">
            <button
              className="btn btn_sm btn_primary "
              onClick={() => navigate("/manage-collection")}
            >
              Back
            </button>
          </div>
        </div>
        <form>
          <div className="form_group gap_m">
            <div className="prfl_pic form_control form_control_bg  w_100 ">
              <label>Collection Image</label>
              {image ? (
                <div className="upload_image">
                  <div className="upload_image_holder">
                    <figure>
                      <CardMedia component="img" image={image} alt="photo" />
                    </figure>
                    <CloseIcon
                      onClick={() => {
                        setImage("");
                        setFileName("");
                      }}
                    />
                  </div>
                </div>
              ) : (
                <label className="upload_image" htmlFor="icon-button-file">
                  <Input
                    sx={{ display: "none" }}
                    id="icon-button-file"
                    type="file"
                    inputProps={{
                      accept: "image/png,image/jpeg",
                    }}
                    onChange={(event: ChangeEvent<HTMLInputElement>) => {
                      const files = (event.target as HTMLInputElement).files;
                      if (files && files[0].type.includes("image")) {
                        setFileName(files[0].name);
                        setImage(URL.createObjectURL(files[0]));
                      } else {
                        setAlertType(0);
                        setShowAlert(true);
                        setAlertMessage("This field only accepts images.");
                      }
                    }}
                  />
                  <span className="upload_image_holder">
                    <figure>
                      <img src="/images/prfl_placeholder.png" alt="" />
                    </figure>
                    <AddIcon />
                  </span>
                </label>
              )}
            </div>

            <div className="form_control form_control_bg  w_50">
              <label> Collection Name </label>
              <TextField
                hiddenLabel
                placeholder="Collection Name"
                fullWidth
                name="email"
                type="text"
                variant="outlined"
              />
            </div>
            <div className="form_control form_control_bg w_50">
              <label>Collection Description</label>

              <TextField
                hiddenLabel
                placeholder="Description goes here"
                fullWidth
                name="telegram"
                type="text"
                variant="outlined"
              />
            </div>

            <div className="submit_btn">
              <button className="btn btn_primary">Save </button>
            </div>
          </div>
        </form>
      </div>
    </MainContainer>
  );
}
