import React from "react";
import MainContainer from "../../layout/MainContainer";

import AdminCouponForm from "./feature/AdminCoupon";
import AssignCoupon from "../managecoupons/feature/assignCoupon";

const AddAdminCoupon = () => {
  return (
    <>
      <MainContainer name="Manage Employees">
        <div className="coupon coupon_dtl_page admin_cooupn ">
          <div className="sec_head">
            <h2>Coupons</h2>
          </div>

          <div className="coupon_flx gap_m">
            <div className="coupon_card">
              <div className="coupon_card_dtl">
                <figure>
                  <img src="/images/bar_code.svg" alt="" />
                </figure>
                <div className="coupon_dtl fs_28 text_center">
                  <h2>
                    Special <span className="d_block"> Offer</span>
                  </h2>
                  <p className="code">CODE : 26473</p>
                  <p>Valid upto 31st Dec 2023</p>
                </div>
                <div className="coupon_btn">
                  <button className="btn btn_primary">50%</button>
                </div>
              </div>
              <div className="coupon_avl">
                <p>Available</p>
                <button className="link">View Details</button>
              </div>
            </div>
            <div className="coupon_status sec_head  ">
              <AdminCouponForm />
            </div>
          </div>
          <div className="assign_copn_wrp">
            <AssignCoupon />
          </div>
        </div>
      </MainContainer>
    </>
  );
};
export default AddAdminCoupon;

