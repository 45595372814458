import AuthCommon from "../../../../components/auth/AuthCommon";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { generateEncryptedKeyBody } from "../../../../utils/crypto";
import { CommonBody } from "../../../../types/General";
import { usePostSignUpMutation } from "../../../../services/auth";
import { errorToast } from "../../../../helpers";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const RegisterContent = () => {
  const navigate = useNavigate();
  const [signUpMutation, { isLoading }] = usePostSignUpMutation();

  const [showPassword, setShowPassword] = useState(false);
  const [ConfirmshowPassword, setConfirmShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickConfirmShowPassword = () => {
    setConfirmShowPassword(!ConfirmshowPassword);
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      newsLetter: false,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      newsLetter: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address!"
        ),
      password: Yup.string()
        .required("Password is required!")
        .min(6, "Minimum 6 characters are required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match.")
        .required("Confirm password is required."),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        email: formik.values.email,
        password: values?.password,
        passwordChanged: true,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
        const response = await signUpMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          if (formik.values.newsLetter) {
            // setToStorage(STORAGE_KEYS.credentials, JSON.stringify(body));
          } else {
            // removeFromStorage(STORAGE_KEYS.credentials);
          }
          // navigate("/profile-setup");
          navigate("/otp", { state: body });
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    },
  });
  return (
    <>
      <section className="auth_section">
        <div className="conta_iner">
          <div className="gap_p">
            <AuthCommon />
            <div className="right_side">
              <form
                action=""
                className="form hd_3"
                onSubmit={formik.handleSubmit}
              >
                <h2>Register</h2>
                <div className="form_control">
                  <label>Email address *</label>
                  <TextField
                    placeholder="Enter Email"
                    className="text_field"
                    hiddenLabel
                    fullWidth
                    type="email"
                    variant="outlined"
                    name="email"
                    id="email"
                    onChange={(val) => {
                      if (
                        val.target.value === " " ||
                        val.target.value === "."
                      ) {
                      } else {
                        formik.handleChange(val);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="form_control">
                  <label>Password *</label>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    placeholder="Enter Password"
                    fullWidth
                    name="password"
                    id="password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    type={showPassword ? "text" : "password"}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <div className="form_control">
                  <label>Confirm Password *</label>
                  <TextField
                    className="text_field"
                    hiddenLabel
                    placeholder="Confirm Password"
                    fullWidth
                    name="confirmPassword"
                    id="confirmPassword"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    type={ConfirmshowPassword ? "text" : "password"}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment className="eye_btn" position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {ConfirmshowPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>

                {/* <div className="form_control">
                  <div className="remember_box">
                    <FormControlLabel
                      control={<Checkbox />}
                      checked={formik.values.newsLetter}
                      name="newsLetter"
                      onChange={formik.handleChange}
                      label="Sign me up for the newsletter!"
                    />
                  </div>
                </div> */}
                <div className="form_btn">
                  <Button
                    className="btn btn_primary"
                    color="primary"
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Register
                  </Button>
                </div>
                <div className="form_bottom">
                  <p>
                    Already have an account?{" "}
                    <a
                      href="javacript:void(0)"
                      onClick={() => navigate("/login")}
                    >
                      Sign In
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RegisterContent;
