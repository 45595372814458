import MainContainer from "../../layout/MainContainer";
import UserDetail from "./features/userDetail";

const ManageUsersView = () => {

    return (
        <MainContainer>
            <div className="main_loyout rpt_card">
                <UserDetail />
            </div>
        </MainContainer>
    );
};

export default ManageUsersView;
