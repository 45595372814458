import { END_POINTS } from "../helpers";
import { CommonBody } from "../types/General";
import emptySplitApi from "../utils/rtk";

type CommonResponseType = {
  statusCode: number;
  message: string;
};

export const nftApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    setNftOnBid: builder.mutation<
      CommonResponseType,
      { body: CommonBody; nftId: string }
    >({
      query: ({ body, nftId }) => ({
        url: `${END_POINTS.setOnBid}/${nftId}`,
        method: "PUT",
        body,
      }),
    }),
    giftNft: builder.mutation<
      CommonResponseType,
      { body: CommonBody; nftId: string }
    >({
      query: ({ body, nftId }) => ({
        url: `${END_POINTS.giftNft}/${nftId}`,
        method: "POST",
        body,
      }),
    }),

    handleBidAction: builder.mutation<
      CommonResponseType,
      { body: CommonBody; nftId: string }
    >({
      query: ({ body, nftId }) => ({
        url: `${END_POINTS.acceptBidWinner}/${nftId}`,
        method: "POST",
        body,
      }),
    }),
    mintNft: builder.mutation<CommonResponseType, { nftId: string }>({
      query: ({ nftId }) => ({
        url: `${END_POINTS.mintNft}/${nftId}`,
        method: "PUT",
      }),
    }),
  }),
});

export const {
  useSetNftOnBidMutation,
  useGiftNftMutation,
  useHandleBidActionMutation,
  useMintNftMutation,
} = nftApi;
