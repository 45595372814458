import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { Notification, Permissions } from "../../types/General";
import useAuth from "../../hooks/useAuth";
import {
  useLazyDeleteNotificationByIdQuery,
  useLazyGetNotificationQuery,
} from "../../services/common";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import {
  STORAGE_KEYS,
  errorToast,
  getFromStorage,
  successToast,
} from "../../helpers";
import Loader from "../../constants/Loader";
import Pagination from "../../components/Pagination";
import WarnModal from "../../components/modals/WarnModal";

const ManageNotifications = () => {
  const user = useAuth();
  const navigate = useNavigate();
  const [deleteById] = useLazyDeleteNotificationByIdQuery();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const dispatch = useAppDispatch();
  const [getNotification, { isLoading }] = useLazyGetNotificationQuery();

  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(false);

  let totalPages = Math.ceil(totalCount / 12);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllNotification = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await getNotification({}).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setNotifications(res?.data?.notification || []);
        setTotalCount(res?.data?.notificationCount);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleDelete = async () => {
    if (selectedId) {
      try {
        const response = await deleteById({ id: selectedId }).unwrap();
        if (response?.statusCode === 200) {
          successToast("Deleted Successfully");
          getAllNotification();
        }
      } catch (error: any) {
        errorToast(error?.data?.message || "");
      }
    }
  };

  const checkPermission = () => {
    const permission = user?.permission;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Notification"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (user && user?.permission?.length) {
      checkPermission();
    }
  }, [user]);

  useEffect(() => {
    getAllNotification();
  }, []);

  return (
    <MainContainer>
      <Loader isLoad={isLoading || loading} />
      <div className="main_loyout cms_pages">
        <div className="dashboard sec_head">
          <h1>Manage Notifications</h1>
          <Box className="cards_header_right bck_btn">
            {hidePermission?.isAdd ||
            user?.role === 2 ||
            user?.role === 3 ||
            user?.role === 1 ? (
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-notifications/add")}
              >
                Add Notification
              </Button>
            ) : null}
          </Box>
        </div>
        <Card className="cards">
          <TableContainer className="table_container">
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table"
              className="table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Title</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Platform</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {notifications?.length
                  ? notifications?.map((item, i) => (
                      <TableRow key={i}>
                        <TableCell align="center">
                          {" "}
                          {(page - 1) * 10 + i + 1}
                        </TableCell>
                        <TableCell>{item?.title || ""}</TableCell>
                        <TableCell>
                          <p className="short_disc">{item?.message || ""}</p>
                        </TableCell>
                        <TableCell align="center">
                          {item?.type === 1
                            ? "All"
                            : item?.type === 2
                            ? "Organisations"
                            : "Customers"}
                        </TableCell>
                        <TableCell>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                setOpen(true);
                                setSelectedId(item?._id);
                              }}
                            >
                              <DeleteIcon style={{ color: "#56bb6d" }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))
                  : !loading &&
                    !notifications?.length && (
                      <TableRow>
                        <TableCell align="center" colSpan={5}>
                          No Data Found
                        </TableCell>
                      </TableRow>
                    )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Pagination
          setPage={setPage}
          module={notifications}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          totalCount={totalCount}
        />
      </div>
      <WarnModal
        setOpen={setOpen}
        open={open}
        name="notification"
        handleDelete={handleDelete}
      />
    </MainContainer>
  );
};

export default ManageNotifications;
