
import AuthLayout from "../../../layout/authLayout";
import "../../auth/auth.scss";
import OtpContent from "./features/otp";

const Otp = () => {
  return (
    <AuthLayout>
      <main>
        <OtpContent />
      </main>
    </AuthLayout>
  );
};

export default Otp;
