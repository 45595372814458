import {
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Pagination from "../../components/Pagination";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  useLazyDeleteCategoryByIdQuery,
  useLazyGetCategoryQuery,
  useUpdateCategoryStatusMutation,
} from "../../services/main";
import { Category } from "../../types/General";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validation";
import Loader from "../../constants/Loader";
import { STORAGE_KEYS, getFromStorage } from "../../helpers";
import WarnModal from "../../components/modals/WarnModal";
import { useAppDispatch } from "../../hooks/store";
import useAuth from "../../hooks/useAuth";
import { setCredentials } from "../../reducers/authSlice";
import { handleDelete, updateStatus } from "../../utils/commonFunctions";
import DeleteIcon from "@mui/icons-material/Delete";

export default function ManageCategory() {
  const user = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState<Category[]>([]);
  const [getCategory, { isLoading }] = useLazyGetCategoryQuery();
  const [deleteById, deleteByIdData] = useLazyDeleteCategoryByIdQuery();
  const [updateStatusMutation] = useUpdateCategoryStatusMutation();

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getAllCategory = async () => {
    setLoading(true);
    const token = getFromStorage(STORAGE_KEYS.token);
    dispatch(
      setCredentials({
        user: user,
        token: token,
      })
    );
    try {
      const res = await getCategory({
        size: 10,
        page: page,
        query: debouncedSearchTerm.trim(),
        pagination: true,
      }).unwrap();
      if (res?.statusCode === 200) {
        setLoading(false);
        setCategories(res?.data?.category || []);
        setTotalCount(res?.data?.count);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllCategory();
  }, [debouncedSearchTerm, page]);

  return (
    <MainContainer name="Contact & Support">
      <Loader isLoad={isLoading || loading || deleteByIdData?.isLoading} />
      <div className="manage_blog">
        <div className="sec_head fs_22">
          <h2>Manage Category</h2>
          <div className="btn_group1">
            {/* <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            /> */}
            {categories?.length ? null : (
              <div className="add_btn">
                <button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-category/add")}
                >
                  <AddIcon />
                </button>
              </div>
            )}
          </div>
        </div>
        <TableContainer className="table_container">
          <Table
            sx={{ minWidth: 650 }}
            aria-label="simple table"
            className="table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">S.No</TableCell>
                <TableCell>Category Name</TableCell>
                <TableCell>Category Description</TableCell>
                {/* <TableCell>Sub-category </TableCell> */}
                {/* <TableCell>Status</TableCell> */}
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {categories?.length
                ? categories?.slice(0, 1).map((row, i) => (
                    <TableRow>
                      <TableCell align="center">
                        {(page - 1) * 10 + i + 1}
                      </TableCell>
                      <TableCell>{row?.name || ""}</TableCell>
                      <TableCell>
                        <p className="short_disc"> {row?.description || ""} </p>
                      </TableCell>
                      {/* <TableCell>
                      <button
                        className="btn btn_primary"
                        onClick={() => navigate("/manage-category/subcategory")}
                      >
                        View
                      </button>
                    </TableCell> */}
                      {/* <TableCell>
                        <Switch
                          {...label}
                          size="small"
                          checked={!row?.isBlocked}
                          onChange={() =>
                            updateStatus(
                              row?._id,
                              !row?.isBlocked,
                              updateStatusMutation,
                              setCategories,
                              categories
                            )
                          }
                        />
                      </TableCell> */}
                      <TableCell>
                        <ul className="action_btn">
                          <li
                            onClick={() =>
                              navigate(
                                `/manage-category/subcategory/${row?._id}`,
                                { state: row?.name }
                              )
                            }
                          >
                            <button type="button">
                              <VisibilityIcon />
                            </button>
                          </li>
                          <li
                            onClick={() =>
                              navigate(`/manage-category/edit/${row?._id}`)
                            }
                          >
                            <button type="button">
                              <EditIcon />
                            </button>
                          </li>
                          {row?.subCategoryCount ? null : (
                            <li>
                              <button
                                type="button"
                                onClick={() => {
                                  setOpen(true);
                                  setSelectedId(row?._id);
                                }}
                              >
                                <DeleteIcon />
                              </button>
                            </li>
                          )}
                        </ul>
                      </TableCell>
                    </TableRow>
                  ))
                : !loading &&
                  !categories?.length && (
                    <TableRow>
                      <TableCell align="center" colSpan={5}>
                        No Data Found
                      </TableCell>
                    </TableRow>
                  )}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Pagination
          setPage={setPage}
          module={categories}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
          totalCount={totalCount}
        /> */}
        <WarnModal
          setOpen={setOpen}
          open={open}
          name="category"
          handleDelete={() =>
            handleDelete(deleteById, selectedId, getAllCategory)
          }
        />
      </div>
    </MainContainer>
  );
}
