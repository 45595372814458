import { Switch, TextField } from "@mui/material";

const label = { inputProps: { "aria-label": "Switch demo" } };
export default function AdminCouponForm() {
  return (
    <form>
      <div className="form_group gap_m">
        <div className="form_control form_control_bg ">
          <label>
            {" "}
            Coupon Code <Switch {...label} defaultChecked />
          </label>
          <TextField
            hiddenLabel
            placeholder="245466"
            fullWidth
            name="email"
            type="text"
            variant="outlined"
          />
        </div>
        <div className="form_control form_control_bg ">
          <label>Fixed</label>

          <TextField
            hiddenLabel
            placeholder="Fixed"
            fullWidth
            name="telegram"
            type="text"
            variant="outlined"
          />
        </div>
      </div>
    </form>
  );
}
